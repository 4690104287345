export const TIMER_LIMITS = [
  4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
];

export const TAGS = [
  'Events',
  'General',
  'Geography',
  'History',
  'Literature',
  'Movies',
  'Music',
  'Science',
  'Sports',
  'Theme',
  'Wordplay',
];
