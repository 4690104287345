import React, { useEffect, useState } from 'react';
import {
  Dialog,
  IconButton,
  DialogContent,
  useTheme,
  useMediaQuery,
  Slide,
  AlertColor,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import Results from './Results';

import { CustomSetLeaderboard } from './types';
import { Player, Question } from '../../types';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type PropTypes = {
  close: () => void;
  customSetLeaderboard: CustomSetLeaderboard;
  customSetLeaderboardIsPending: boolean;
  isGuest: boolean;
  open: boolean;
  players: Record<string, Player>;
  profileId?: string;
  questions: Question[];
  title: string;
};

const ResultsDialog = ({
  close,
  customSetLeaderboard,
  customSetLeaderboardIsPending,
  isGuest,
  open,
  players,
  profileId,
  questions,
  title,
}: PropTypes) => {
  const theme = useTheme();
  const xsBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      fullScreen={xsBreakpoint}
      fullWidth={!xsBreakpoint}
      TransitionComponent={Transition}
    >
      <DialogContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
        <IconButton
          onClick={close}
          sx={{
            position: 'absolute',
            right: 5,
            top: 5,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Results
          customSetLeaderboard={customSetLeaderboard}
          customSetLeaderboardIsPending={customSetLeaderboardIsPending}
          isGuest={isGuest}
          players={players}
          profileId={profileId}
          questions={questions}
          title={title}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ResultsDialog;
