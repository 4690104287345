import React, { useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { DisplayFuzzy } from '../fuzzy/DisplayFuzzy';
import { Player } from '../types';
import { games } from '../../../game-engine/constants';

type PropTypes = {
  gameId: string;
  player: Player;
  questionIndex: number;
  type: 'buzzer' | 'multiple-choice';
};

export const AnswerFeedback = ({
  gameId,
  player,
  questionIndex,
  type,
}: PropTypes) => {
  const getPreviousAnswerAttempt = () => {
    const answerAttempts = player?.roundScores[questionIndex]?.answerAttempts;
    if (!answerAttempts?.length) return '';
    return answerAttempts[answerAttempts.length - 1].input;
  };

  const previousAnswerAttempt = useMemo(
    () => getPreviousAnswerAttempt(),
    [player, questionIndex]
  );

  const standardMessage = player?.correctAnswer
    ? `"${previousAnswerAttempt}"\nYou got a ${
        player.currentScore > 0 ? '+' : ''
      }${player.currentScore}`
    : player?.timerLimitReached && games[gameId].buzzerAttemptsRemaining === 0
    ? `"${previousAnswerAttempt}" is incorrect\nYou are out of buzzes`
    : player?.timerLimitReached && games[gameId].buzzerAttemptsRemaining > 0
    ? 'Time is up'
    : player?.incorrectAnswer
    ? `"${previousAnswerAttempt}" is incorrect`
    : `Attempts remaining: ${games[gameId].buzzerAttemptsRemaining}`;

  const multipleChoiceMessage =
    player?.incorrectAnswer && previousAnswerAttempt
      ? `"${previousAnswerAttempt}" is incorrect`
      : player?.timerLimitReached && !previousAnswerAttempt
      ? 'Time is up'
      : player?.correctAnswer
      ? `Correct! You got a ${player.currentScore > 0 ? '+' : ''}${
          player.currentScore
        }`
      : '';

  return (
    <Box
      height={type === 'buzzer' ? 40 : 20}
      width={'100%'}
      display={'flex'}
      alignItems={'center'}
      position={'relative'}
    >
      <Stack direction={'row'} width={'100%'} justifyContent={'center'}>
        {type === 'buzzer' && player.correctAnswer ? (
          <Box
            sx={{
              rotate: '-11deg',
              position: 'absolute',
              top: 10,
              left: 10,
            }}
          >
            <DisplayFuzzy
              correctAnswer={true}
              customFontSize={10}
              fuzzyPenalty={null}
              fuzzyScore={player?.fuzzyScore}
            />
          </Box>
        ) : null}
        <Typography
          textAlign={'center'}
          sx={{
            whiteSpace: 'pre-wrap',
            color: player?.correctAnswer
              ? 'success.main'
              : player?.incorrectAnswer || player?.timerLimitReached
              ? 'error.main'
              : '#ffba18',
          }}
        >
          {type === 'buzzer' ? standardMessage : multipleChoiceMessage}
        </Typography>
      </Stack>
    </Box>
  );
};
