import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Box, Typography } from '@mui/material';
import { generateQuestionData } from './utils';

export const vibrantColors = [
  'rgb(0, 255, 0, 0.5)',
  'rgb(26, 255, 0, 0.5)',
  'rgb(51, 255, 0, 0.5)',
  'rgb(77, 255, 0, 0.5)',
  'rgb(102, 255, 0, 0.5)',
  'rgb(128, 255, 0, 0.5)',
  'rgb(153, 255, 0, 0.5)',
  'rgb(179, 255, 0, 0.5)',
  'rgb(204, 255, 0, 0.5)',
  'rgb(230, 255, 0, 0.5)',
  'rgb(255, 255, 0, 0.5)',
  'rgb(255, 230, 0, 0.5)',
  'rgb(255, 204, 0, 0.5)',
  'rgb(255, 179, 0, 0.5)',
  'rgb(255, 153, 0, 0.5)',
  'rgb(255, 128, 0, 0.5)',
  'rgb(255, 102, 0, 0.5)',
  'rgb(255, 77, 0, 0.5)',
  'rgb(255, 51, 0, 0.5)',
  'rgb(255, 26, 0, 0.5)',
  'rgb(255, 0, 0, 0.5)',
];

type Props = {
  par: number;
  questionScores: number[];
  timerLimit: number;
};

const QuestionBarChart = ({ par, questionScores, timerLimit }: Props) => {
  const questionData = generateQuestionData(questionScores, par, timerLimit);

  const data = {
    labels: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ],
    datasets: [
      {
        data: questionData,
        backgroundColor: vibrantColors,
        borderColor: vibrantColors.map((color) => color.replace('0.5)', '1)')),
        borderWidth: 1,
      },
    ],
  };
  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Answers at Second',
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
      },
      y: {
        display: true,
        ticks: {
          beginAtZero: true,
          stepSize: 1,
        },
      },
    },
  };

  return <Bar data={data} options={options} height={'140px'} />;
};

export default QuestionBarChart;
