import {
  Typography,
  keyframes,
  styled,
  typographyClasses,
} from '@mui/material';
import { Ave } from './types';

const shine = keyframes`
  0% {
    background-position: -400px;
  }
  100% {
    background-position: 400px;
  }
`;

export const ShinyTypography = styled(Typography)(() => ({
  [`&.${typographyClasses.root}`]: {
    letterSpacing: 2,
    animation: `${shine} 3s infinite`,
    backgroundClip: 'text',
    backgroundImage:
      'linear-gradient(-40deg, transparent 0%, transparent 40%, #fff 50%, transparent 60%, transparent 100%)',
    backgroundRepeat: 'no-repeat',
  },
}));

export const AVES: Ave[] = [
  {
    name: 'bogey',
    score: 1,
    color: { dark: '', light: '' },
    shineColor: { dark: '', light: '' },
  },
  {
    name: 'par',
    score: 0,
    color: { dark: '#AC724C', light: '#cda68c' },
    shineColor: { dark: '#AC724CB3', light: '#cda68cb3' }, //'rgba(205, 166, 140, 0.7)',
  },
  {
    name: 'birdie',
    score: -1,
    color: { dark: '#00B600', light: '#00FF00' },
    shineColor: { dark: '#00B600B3', light: '#00FF00B3' }, //'rgba(0, 255, 0, 0.7)',
  },
  {
    name: 'eagle',
    score: -2,
    color: { dark: '#43B5C4', light: '#B0E0E6' },
    shineColor: { dark: '#43B5C4B3', light: '#B0E0E6B3' }, //'rgba(176, 224, 230, 0.7)',
  },
  {
    name: 'albatross',
    score: -3,
    color: { dark: '#D80073', light: '#FF1493' },
    shineColor: { dark: '#D80073B3', light: '#FF1493B3' }, //'rgba(255, 20, 147, 0.7)',
  },
  {
    name: 'condor',
    score: -4,
    color: { dark: '#C86E00', light: '#ff8c00' },
    shineColor: { dark: '#C86E00B3', light: '#ff8c00B3' }, //'rgba(255, 140, 0, 0.7)',
  },
  {
    name: 'ostrich',
    score: -5,
    color: { dark: '#E43CE4', light: '#EE82EE' },
    shineColor: { dark: '#E43CE4B3', light: '#EE82EEB3' }, //'rgba(238, 130, 238, 0.7)',
  },
  {
    name: 'pterodactyl',
    score: -6,
    color: { dark: '#AFA429', light: '#E6DF93' },
    shineColor: { dark: '#AFA429B3', light: '#E6DF93B3' }, // 'rgba(230, 223, 147, 0.7)',
  },
];

export const getAveShineColor = (name: string, mode: string) =>
  AVES.find((a) => a.name === name)?.shineColor[
    mode === 'dark' ? 'light' : 'dark'
  ] || '';

export const getAveName = (score: number) => {
  const found = AVES.find((a) => a.score === score)?.name;
  if (found) return found;
  if (score < -6) return 'pterodactyl'; // scores shouldn't be less than -6, but if achieved, still ptero
  return '';
};
