import { useMutation } from '@tanstack/react-query';
import { postCreateCustomSet } from '../../../../api/apiServices';

const useCreateCustomSet = (handleOnSuccess: (response: any) => void) => {
  const { mutate, isPending } = useMutation({
    mutationFn: postCreateCustomSet,
    onSuccess: handleOnSuccess,
  });

  return {
    mutate,
    isPending,
  };
};

export default useCreateCustomSet;
