import { EXCLUDED_ARTICLES } from '../constants';

export const createAlternateAnswersIfPrompted = (
  promptAnswers: string[],
  answer: string,
  alternateAnswers: string[]
) => {
  const getTrimmedAnswer = (prompt: string, answer: string) => {
    let trimmedAnswer = answer;
    for (const promptWord of prompt.split(' ')) {
      trimmedAnswer = trimmedAnswer
        .replace(/[\W_]+/g, '')
        .toUpperCase()
        .replace(promptWord.replace(/[\W_]+/g, '').toUpperCase(), '');
    }
    return trimmedAnswer.replace(/\s+/g, ' ').trim(); // remove double spaces
  };

  const alternateAnswersIfPrompted: Record<string, string[]> = {};
  for (const prompt of promptAnswers) {
    alternateAnswersIfPrompted[prompt] = [];
    const trimmedAnswer = getTrimmedAnswer(prompt, answer);
    if (!alternateAnswersIfPrompted[prompt].includes(trimmedAnswer)) {
      alternateAnswersIfPrompted[prompt].push(trimmedAnswer);
    }
    for (const altAnswer of alternateAnswers) {
      if (!altAnswer) continue;
      const trimmedAltAnswer = getTrimmedAnswer(prompt, altAnswer);
      if (!alternateAnswersIfPrompted[prompt].includes(trimmedAltAnswer)) {
        alternateAnswersIfPrompted[prompt].push(trimmedAltAnswer);
      }
    }
  }
  return alternateAnswersIfPrompted;
};

export const create2dAnswerArray = (answerArray) => {
  const revealedLetters = [];
  let word = [];
  answerArray.forEach((letter) => {
    // if non-alphanumeric
    if (
      /[\W_]/.test(letter) &&
      Array.from(letter.normalize('NFD')).length === 1
    ) {
      if (letter === ' ') {
        revealedLetters.push(word);
        word = [];
      } else {
        word.push(letter);
      }
    } else {
      word.push(letter);
    }
  });
  revealedLetters.push(word);
  return revealedLetters;
};

export const create2dRevealedLettersArray = (answerArray: string[]) => {
  const revealedLetters = [];
  let word = [];
  answerArray.forEach((letter) => {
    // if non-alphanumeric
    if (
      /[\W_]/.test(letter) &&
      Array.from(letter.normalize('NFD')).length === 1
    ) {
      if (letter === ' ') {
        revealedLetters.push(word);
        word = [];
      } else {
        word.push(letter);
      }
    } else {
      word.push('');
    }
  });
  revealedLetters.push(word);
  return revealedLetters;
};

export const getRandomIndexInArray = (array, properNameIndex = undefined) => {
  // leave 1 letter unrevealed
  // if all letters have been revealed except 1, return [-1, -1]
  if (array.flat().filter((e) => e === '').length === 1) return [-1, -1];
  while (true) {
    const randomWordIndex = Math.floor(Math.random() * array.length);
    const randomLetterIndex = Math.floor(
      Math.random() * array[randomWordIndex].length
    );
    if (
      properNameIndex &&
      randomWordIndex === properNameIndex[0] &&
      randomLetterIndex === properNameIndex[1]
    )
      continue;
    if (array[randomWordIndex][randomLetterIndex] === '')
      return [randomWordIndex, randomLetterIndex];
  }
};

export const collapseWords = (words) => {
  const regex = /[\W_]+/g;
  const collapsed = words
    .trimEnd()
    .toUpperCase()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '') // removes accents
    .replace(/ & /g, 'AND')
    .replace(/&/g, 'AND')
    .replace(regex, '');
  const firstWord = collapsed.replace(/ .*/, '');
  if (firstWord === 'THE' || firstWord === 'AN' || firstWord === 'A') {
    return collapsed.substr(collapsed.indexOf(' ') + 1);
  }
  return collapsed;
};

export const removeFirstWordArticles = (words) => {
  const firstWord = words.substring(0, words.indexOf(' '));
  if (EXCLUDED_ARTICLES.includes(firstWord.toUpperCase())) {
    const withoutFirstWord = words.substring(words.indexOf(' ') + 1);
    return withoutFirstWord;
  }
  return words;
};

export const roundToOneDecimal = (value) => Math.round(value * 10) / 10;
export const roundToTwoDecimals = (value) => Math.round(value * 100) / 100;

export const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const findIndexOf2dArray = (arr, k) => {
  for (let i = 0; i < arr.length; i++) {
    const index = arr[i].indexOf(k);
    if (index > -1) {
      return [i, index];
    }
  }
};

export const utcToLocalDate = (ms: number) => {
  const date = new Date(ms);
  return date.toLocaleDateString();
};

export const utcToLocalDateLong = (ms: number) => {
  const options: any = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  const date = new Date(ms);
  return date.toLocaleDateString('en-us', options);
};
