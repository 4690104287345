export const API_ROUTES = {
  postLogout: '/authenticate/logout',
  postLogin: '/authenticate/login',
  postGoogleLogin: '/authenticate/google-sso',
  getAuthVerify: '/authenticate/verify',
  postAuthSignup: '/authenticate/register',
  postAuthResetPassword: '/authenticate/reset-password',
  postAuthForgotPassword: '/authenticate/forgot-password',
  postAuthResendEmailVerification: '/authenticate/resend-email-verification',
  getProfile: '/profile',
  getProfileById: '/profile/id',
  postUpdateProfile: '/profile/update',
  fetchPlacesPredictions: '/locations/places/predictions',
  fetchPlacesGeocode: '/locations/places/geocode',
  getCustomGame: '/custom-games/game',
  postCustomGameStarted: '/custom-games/game-started',
  postCustomGame: '/custom-games/game',
  getCustomSets: '/custom-sets/sets',
  getCustomSetsDrafts: '/custom-sets/drafts',
  getCustomSetsPublished: '/custom-sets/published',
  getCustomSetQuestions: '/custom-sets/questions',
  getCustomSetsCreated: '/custom-sets/created',
  getCustomSetsPlayed: '/custom-sets/played',
  getCustomSet: '/custom-sets/set',
  getCustomSetLeaderboard: '/custom-sets/leaderboard',
  postCustomSetRating: '/custom-sets/rating',
  postCreateCustomSet: '/custom-sets/create',
  deleteCustomSet: '/custom-sets/delete',
  postPublishCustomSet: '/custom-sets/publish',
  postArchiveCustomSet: '/custom-sets/archive',
  postCreateCustomSetQuestion: '/custom-sets/question/create',
  postUpdateQuestionsSortOrder: '/custom-sets/question/sort',
  deleteCustomSetQuestion: '/custom-sets/question/delete',
  getUserNotifications: '/user-notifications',
  getUnseenUserNotifications: '/user-notifications/unseen',
  postUserNotificationsSeen: '/user-notifications/seen',
};
