import { games } from '../constants';
import { updateGame } from './game-loop';

export const updateGames = (seconds: number) => {
  const gameIds = Object.keys(games);
  for (const gameId of gameIds) {
    const isGameOver = games[gameId].gameOver;
    const isGameStarted = games[gameId].gameStarted;
    if (isGameOver || !isGameStarted) continue;
    updateGame(seconds, games[gameId]);
  }
};
