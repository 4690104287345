import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CustomSet } from '../../../types/custom-sets';
import QuestionCard from './QuestionCard';
import EditQuestionDialog from './dialogs/EditQuestionDialog/EditQuestionDialog';
import useGetCustomSetQuestions from './hooks/useGetCustomSetQuestions';
import SettingsIcon from '@mui/icons-material/Settings';
import { Question } from '../../game/types';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { games } from '../../../game-engine/constants';
import Previewer from '../Previewer';
import { Profile } from '../../../types/types';
import DeleteIcon from '@mui/icons-material/Delete';
import SortIcon from '@mui/icons-material/Sort';
import DraggableSortDialog from './dialogs/DraggableSortDialog/DraggableSortDialog';
import ArchiveConfirmDialog from './dialogs/ArchiveConfirmDialog';
import useUpdateArchiveSet from '../../game/hooks/useUpdateArchiveSet';

type Props = {
  customSet: CustomSet;
  handleBackClick: () => void;
  handleGameConfigsClick: () => void;
  handleOnSetArchiveSuccess: () => void;
  handleOnQuestionCreateSuccess: () => void;
  handleOnQuestionDeleteSuccess: () => void;
  onPlayTestEnd: () => void;
  onPlayTestStart: () => void;
  profile: Profile;
};

const EditQuestions = ({
  customSet,
  handleBackClick,
  handleGameConfigsClick,
  handleOnSetArchiveSuccess,
  handleOnQuestionCreateSuccess,
  handleOnQuestionDeleteSuccess,
  onPlayTestEnd,
  onPlayTestStart,
  profile,
}: Props) => {
  const [startPlayTest, setStartPlayTest] = useState<'all' | Question | null>(
    null
  );
  const [selectedQuestion, setSelectedQuestion] = useState<Question>();
  const [openEditQuestionDialog, setOpenEditQuestionDialog] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [openArchiveConfirmDialog, setOpenArchiveConfirmDialog] =
    useState(false);
  const [openSortDialog, setOpenSortDialog] = useState(false);

  const {
    data: customSetQuestions,
    isPending,
    refetch,
  } = useGetCustomSetQuestions(customSet?.customSetId);

  const handleQuestionCardClick = (question: Question) => {
    setSelectedQuestion(question);
    setOpenEditQuestionDialog(true);
  };

  const handleQuestionCreateSuccess = () => {
    setOpenEditQuestionDialog(false);
    refetch();
    handleOnQuestionCreateSuccess();
  };

  const handlePlayTestClick = () => {
    setStartPlayTest('all');
    onPlayTestStart();
  };

  const handlePlayQuestionClick = (e: any, question: Question) => {
    e.stopPropagation();
    setStartPlayTest(question);
    onPlayTestStart();
  };

  const handleGameOver = () => {
    setStartPlayTest(null);
    onPlayTestEnd();
    delete games['test-game-id'];
  };

  const handleSetArchiveSuccess = () => {
    setOpenArchiveConfirmDialog(false);
    handleOnSetArchiveSuccess();
  };

  const handleQuestionDeleteSuccess = () => {
    refetch();
    setOpenEditQuestionDialog(false);
    handleOnQuestionDeleteSuccess();
  };

  const { mutate: archiveCustomSet } = useUpdateArchiveSet(
    handleSetArchiveSuccess
  );

  const handleArchiveSetClick = () => {
    if (!customSet?.customSetId) return;
    setOpenArchiveConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    if (!customSet?.customSetId) return;
    archiveCustomSet({ customSetId: customSet.customSetId });
  };

  const handleUpdateSortSuccess = () => {
    refetch();
    setOpenSortDialog(false);
  };

  return (
    <>
      {startPlayTest ? (
        <Previewer
          avatarPublicId={profile?.avatarPublicId}
          avatarUrl={profile?.avatarUrl}
          customSet={customSet}
          gameId="test-game-id"
          handleGameOver={handleGameOver}
          profileId={profile?._id}
          questions={
            startPlayTest === 'all' ? customSetQuestions : [startPlayTest]
          }
          skipCountdown
          username={profile?.username}
        />
      ) : (
        <Stack>
          <Stack
            spacing={2}
            height={'calc(100dvh - 162px)'}
            overflow={'auto'}
            pb={4}
          >
            <Stack>
              <Stack direction={'row'} justifyContent={'left'}>
                <Button
                  startIcon={<ArrowBackIcon />}
                  onClick={handleBackClick}
                  size="small"
                >
                  Lists
                </Button>
              </Stack>

              <Typography textAlign={'center'}>"{customSet?.title}"</Typography>
            </Stack>

            {customSet?.archived ? (
              <Button
                variant="outlined"
                startIcon={<DeleteIcon />}
                onClick={handleArchiveSetClick}
                color="error"
                fullWidth
                size="small"
                disabled
              >
                This set is archived
              </Button>
            ) : (
              <Stack
                direction={'row'}
                spacing={2}
                justifyContent={'space-between'}
              >
                <Button
                  variant="outlined"
                  startIcon={<SettingsIcon />}
                  onClick={handleGameConfigsClick}
                  fullWidth
                  size="small"
                >
                  Edit Configs
                </Button>

                <Button
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  onClick={handleArchiveSetClick}
                  color="error"
                  fullWidth
                  size="small"
                >
                  Archive Set
                </Button>
              </Stack>
            )}

            <Stack direction={'row'} justifyContent={'space-between'}>
              <Button
                onClick={handlePlayTestClick}
                startIcon={<PlayCircleOutlineIcon />}
                color="secondary"
                size="small"
                disabled={!customSetQuestions?.length}
              >
                Play Test
              </Button>
              {/* 
              <Button
                onClick={() => setOpenSortDialog(true)}
                startIcon={<SortIcon />}
                color="creamOrange"
                size="small"
                disabled={!customSetQuestions?.length}
              >
                Sort
              </Button> */}

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showDetails}
                      onChange={(event) => setShowDetails(event.target.checked)}
                      disabled={!customSetQuestions?.length}
                      size="small"
                    />
                  }
                  label="Details"
                />
              </FormGroup>
            </Stack>

            <Stack>
              {customSetQuestions?.length ? (
                customSetQuestions
                  .sort((a, b) => a.sortOrder - b.sortOrder)
                  .map((question, i) => (
                    <QuestionCard
                      key={i}
                      handleCardClick={() => handleQuestionCardClick(question)}
                      handlePlayQuestionClick={(e, question) =>
                        handlePlayQuestionClick(e, question)
                      }
                      question={question}
                      showDetails={showDetails}
                    />
                  ))
              ) : (
                <Typography textAlign={'center'} color="lightgray">
                  No questions yet
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}

      <EditQuestionDialog
        customSetId={customSet?.customSetId}
        existingQuestion={selectedQuestion}
        handleOnCreateSuccess={handleQuestionCreateSuccess}
        handleOnDeleteSuccess={handleQuestionDeleteSuccess}
        limitEditing
        open={openEditQuestionDialog}
        onClose={() => setOpenEditQuestionDialog(false)}
        profileId={profile._id}
        type={customSet?.configs.type}
      />

      <ArchiveConfirmDialog
        confirmAction={handleConfirmDelete}
        onClose={() => setOpenArchiveConfirmDialog(false)}
        open={openArchiveConfirmDialog}
      />

      {customSetQuestions && (
        <DraggableSortDialog
          handleUpdateSortSuccess={handleUpdateSortSuccess}
          open={openSortDialog}
          onClose={() => setOpenSortDialog(false)}
          customSetId={customSet?.customSetId}
          customSetQuestions={customSetQuestions}
        />
      )}
    </>
  );
};

export default EditQuestions;
