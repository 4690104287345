import React, { useState, MouseEvent } from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  Stack,
  Typography,
  Divider,
  AccordionDetails,
  IconButton,
  useTheme,
  Badge,
  Avatar,
  Popover,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { formattedScore } from './ScorecardTable';
import { DisplayFuzzy } from './DisplayFuzzy';
import { Player, Question } from '../../../types';

type PropTypes = {
  players?: Record<string, Player>;
  profileId: string;
  questions: Question[];
};

export const ScorecardRows = ({ players, profileId, questions }: PropTypes) => {
  const theme = useTheme();

  const underParScoreBallStyle = {
    bgcolor: `#0db58640`,
    color: 'text.primary',
    textAlign: 'center',
    boxShadow: `0 0 5px 1px #0db586, 0 0 2px 3px #0db586 inset`,
    height: 30,
    width: 30,
  };

  const timedoutScoreBallStyle = {
    bgcolor: `${theme.palette.error.main}40`,
    color: 'error.contrastText',
    display: 'inline-flex',
    boxShadow: `0 0 5px 1px ${theme.palette.error.main}, 0 0 2px 3px ${theme.palette.error.main} inset`,
    height: 30,
    width: 30,
  };

  const playersCount = players ? Object.keys(players).length : 0;
  const [runningTotals, setRunningTotals] = useState(false);
  const [expandDetails, setExpandDetails] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
  });
  const [anchorAnswerAttemptsEl, setAnchorAnswerAttemptsEl] =
    useState<HTMLButtonElement | null>(null);
  const [
    clickedAnswerAttemptsQuestionIndex,
    setClickedAnswerAttemptsQuestionIndex,
  ] = useState<number>();
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [opponentProfileId, setOpponentProfileId] = useState<string>();

  const openAnswerAttempts = Boolean(anchorAnswerAttemptsEl);

  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpandDetails((prev) => ({
      ...prev,
      [panel]: newExpanded,
    }));
  };

  const handleAnswerAttemptsClick = (
    event: MouseEvent<HTMLButtonElement>,
    questionIndex: number
  ) => {
    setAnchorAnswerAttemptsEl(event.currentTarget);
    setClickedAnswerAttemptsQuestionIndex(questionIndex);
  };

  const handleAnswerAttemptsClose = () => {
    setAnchorAnswerAttemptsEl(null);
  };

  const isTimedOut = (par: number, score: number) => 20 - par === score;

  const getRunningTotalScore = (player: Player, index: number) => {
    let total = 0;
    for (let i = 0; i <= index; i++) {
      total =
        total +
        player.roundScores[i].score +
        player.roundScores[i].penalty +
        (player.roundScores[i].fuzzyPenalty || 0);
    }
    return total;
  };

  const formatPlayerScore = (
    index: number,
    par: number,
    precisionIconLocation: 'corner' | 'bottom',
    player?: Player
  ) => {
    if (!player || !player.roundScores[index]) return 'N/A';
    const score = runningTotals
      ? getRunningTotalScore(player, index)
      : player.roundScores[index].score;

    let scoreStyle: any = {
      bgcolor: theme.palette.mode === 'dark' ? '#434343' : 'undefined',
      color: 'error.contrastText',
      height: 30,
      width: 30,
    };
    let avatarShape: any = 'rounded';
    if (score < 0 && !runningTotals) {
      scoreStyle = underParScoreBallStyle;
      avatarShape = 'round';
    }
    if (isTimedOut(par, score) && !runningTotals) {
      scoreStyle = timedoutScoreBallStyle;
      avatarShape = 'square';
    }
    if (runningTotals) {
      scoreStyle = {
        bgcolor: theme.palette.mode === 'dark' ? 'transparent' : 'undefined',
        color: theme.palette.info.main,
        display: 'inline-flex',
        height: 30,
        width: 30,
      };
    }
    return (
      <Stack
        spacing={precisionIconLocation === 'bottom' ? 2 : 0}
        sx={{
          position: precisionIconLocation === 'bottom' ? 'relative' : 'none',
          alignItems: 'center',
        }}
      >
        <Badge badgeContent={player.roundScores[index].penalty} color="warning">
          <Box position={'absolute'} top={-8} left={-16} zIndex={1}>
            {player.roundScores[index].fuzzyPenalty !== null ? (
              <DisplayFuzzy
                correctAnswer={true}
                customText="P"
                customFontSize={8}
                fuzzyPenalty={player.roundScores[index].fuzzyPenalty}
                fuzzyScore={null}
              />
            ) : null}
          </Box>
          <Avatar sx={scoreStyle} variant={avatarShape}>
            <Typography variant="subtitle2">{formattedScore(score)}</Typography>
          </Avatar>
        </Badge>
      </Stack>
    );
  };

  const answerAttemptTooltip = (questionIndex: number) => {
    if (questionIndex === undefined) return;
    return (
      <Stack spacing={2} sx={{ padding: 1 }}>
        <Typography textAlign="center">Your Answer Attempts</Typography>
        {!players[profileId] ? (
          <Typography variant="body1">None</Typography>
        ) : (
          players[profileId]?.roundScores[questionIndex].answerAttempts.map(
            (answer) => (
              <Stack direction="row" spacing={3} minWidth={'250px'}>
                <Typography
                  variant="body1"
                  textAlign={'right'}
                  sx={{
                    width: '40%',
                    color:
                      theme.palette.mode === 'light'
                        ? theme.palette.primary.main
                        : theme.palette.text.primary,
                    textShadow:
                      theme.palette.mode === 'dark'
                        ? `0 0 1px ${theme.palette.primary.main}, 10px 0px 20px ${theme.palette.primary.main}, 0px 0px 16px ${theme.palette.primary.main}`
                        : 'none',
                  }}
                >
                  {answer.gameClock.toFixed(2)} sec
                </Typography>
                <Typography
                  variant="body1"
                  textAlign={'left'}
                  sx={{ width: '60%' }}
                >
                  "{answer.input}"
                </Typography>
              </Stack>
            )
          )
        )}
      </Stack>
    );
  };

  return (
    <>
      <Popover
        open={openAnswerAttempts}
        anchorEl={anchorAnswerAttemptsEl}
        onClose={handleAnswerAttemptsClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {answerAttemptTooltip(clickedAnswerAttemptsQuestionIndex)}
      </Popover>
      <Box>
        {questions.map((q, index) => (
          <Accordion
            expanded={expandDetails[index]}
            onChange={handleAccordionChange(index)}
            key={index}
            sx={{
              '&.MuiPaper-root': { backgroundImage: 'none' },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                pl: playersCount >= 2 ? 1 : 0,
                pr: playersCount >= 2 ? 0 : null,
                '.MuiAccordionSummary-content': { my: 0 },
                '.MuiAccordionSummary-expandIconWrapper': {
                  order: -1,
                },
              }}
            >
              <Stack
                direction={'row'}
                width={'100%'}
                display={'flex'}
                alignItems={'center'}
                pl={1}
              >
                <Box width={'5%'}>
                  <Typography variant="subtitle2">{index + 1}.</Typography>
                </Box>
                <Box width={'80%'}>
                  <Typography
                    variant="subtitle2"
                    paddingX={1}
                    textAlign={'center'}
                    sx={{ color: theme.palette.dailies.main }}
                  >
                    {q.answer}
                  </Typography>
                </Box>
                <Box width={'15%'}>
                  <Typography
                    variant="subtitle2"
                    paddingX={1}
                    textAlign={'center'}
                    sx={{ color: theme.palette.primary.main }}
                  >
                    {q.par}
                  </Typography>
                </Box>
                <Divider orientation="vertical" />
                <Box
                  width={playersCount >= 2 ? '80px' : '50px'}
                  display={'flex'}
                  justifyContent={'right'}
                >
                  {formatPlayerScore(
                    index,
                    q.par,
                    'bottom',
                    players[profileId]
                  )}
                </Box>
                {opponentProfileId ? (
                  <>
                    <Divider orientation="vertical" />
                    <Box
                      width={'80px'}
                      display={'flex'}
                      justifyContent={'center'}
                    >
                      {formatPlayerScore(
                        index,
                        q.par,
                        'bottom',
                        players[opponentProfileId]
                      )}
                    </Box>
                  </>
                ) : null}
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={1}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Box>
                    {/* <Typography
                    variant="subtitle2"
                    width={'fit-content'}
                    sx={{
                      color: theme.palette.info.main,
                      // borderBottom: '1px solid',
                      // borderColor: theme.palette.info.main,
                      paddingY: 0.2,
                      // paddingX: 1,
                    }}
                  >
                    {q.category}
                  </Typography> */}
                    <Typography variant={'caption'}>{q.question}</Typography>
                  </Box>
                  <IconButton
                    color="primary"
                    onClick={(e) => handleAnswerAttemptsClick(e, index)}
                    sx={{ padding: 0 }}
                  >
                    <ListAltIcon />
                  </IconButton>
                </Stack>
                {/* <Typography variant={'caption'}>
              {q.question}
            </Typography> */}
              </Stack>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      <Box>
        <AccordionSummary
          sx={{
            bgcolor: '#242b28',
            borderTop: '1px solid',
            borderColor: theme.palette.primary.dark,
            pl: playersCount >= 2 ? 0.5 : null,
            pr: playersCount >= 2 ? 0 : null,
            '&.MuiButtonBase-root': { minHeight: 35 },
            '.MuiAccordionSummary-content': { margin: 0 },
          }}
        >
          <Stack
            direction={'row'}
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
          >
            <Box width={'100%'}>
              <Typography
                variant="subtitle2"
                paddingX={1}
                textAlign={'right'}
                letterSpacing={5}
                fontWeight={600}
              >
                TOTAL
              </Typography>
            </Box>
            <Divider orientation="vertical" />
            <Box
              width={playersCount >= 2 ? '80px' : '50px'}
              display={'flex'}
              pr={1}
              justifyContent={'right'}
              fontWeight={600}
            >
              {formattedScore(players[profileId]?.parScore)}
            </Box>
            {opponentProfileId ? (
              <>
                <Divider orientation="vertical" />
                <Box
                  width={'80px'}
                  display={'flex'}
                  justifyContent={'right'}
                  fontWeight={600}
                >
                  {formattedScore(players[opponentProfileId]?.parScore)}
                </Box>
              </>
            ) : null}
          </Stack>
        </AccordionSummary>
      </Box>
    </>
  );
};
