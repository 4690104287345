import React from 'react';
import {
  Pristine,
  POISED_FUZZY_SCORE,
  Poised,
  PASSABLE_FUZZY_SCORE,
  Passable,
  Panicky,
  Putts,
} from './constants';

type Props = {
  correctAnswer: boolean;
  customFontSize?: number;
  customText?: string;
  fuzzyPenalty: number | null;
  fuzzyScore: number;
};

export const DisplayFuzzy = ({
  correctAnswer,
  customText,
  customFontSize,
  fuzzyPenalty,
  fuzzyScore,
}: Props) => {
  return fuzzyScore || fuzzyPenalty || fuzzyPenalty === 0 ? (
    fuzzyScore === 1 || fuzzyPenalty === -1 ? (
      <Pristine
        text={customText ?? 'Pristine'}
        fontSize={customFontSize ?? null}
      />
    ) : (fuzzyScore >= POISED_FUZZY_SCORE && fuzzyScore < 1) ||
      fuzzyPenalty === 0 ? (
      <Poised text={customText ?? 'Poised'} fontSize={customFontSize ?? null} />
    ) : (fuzzyScore >= PASSABLE_FUZZY_SCORE &&
        fuzzyScore < POISED_FUZZY_SCORE) ||
      fuzzyPenalty === 1 ? (
      <Passable
        text={customText ?? 'Passable'}
        fontSize={customFontSize ?? null}
      />
    ) : fuzzyScore < PASSABLE_FUZZY_SCORE || fuzzyPenalty === 2 ? (
      <Panicky
        text={customText ?? 'Panicky'}
        fontSize={customFontSize ?? null}
      />
    ) : null
  ) : correctAnswer ? (
    <Putts
      text={customText ?? 'Nice Putt!'}
      fontSize={customFontSize ?? null}
    />
  ) : null;
};
