import { useQuery } from '@tanstack/react-query';
import { getCustomSetsCreated } from '../../../api/apiServices';

const useGetCustomSetsCreated = (profileId: string) => {
  const { data, refetch } = useQuery({
    queryKey: ['getCustomSetsCreated', { profileId }],
    queryFn: getCustomSetsCreated,
    enabled: !!profileId,
  });

  return {
    refetch,
    data,
  };
};

export default useGetCustomSetsCreated;
