import { isEqual } from 'lodash';
import levenshtein from 'js-levenshtein';
import jaro from 'jaro-winkler';
const metaphone = require('metaphone');

const FUZZY_LENIENT_WORDS = [
  'A',
  'AM',
  'AN',
  'ARE',
  'AS',
  'AT',
  'BE',
  'BUT',
  'BY',
  'FOR',
  'IN',
  'IS',
  'OF',
  'OFF',
  'ON',
  'OUT',
  'PER',
  'THE',
  'TO',
];

const EXCLUDED_ARTICLES = ['THE', 'AN', 'A'];

const THRESHOLD_PER_WORD = 0.5;
const THRESHOLD_FINAL = 0.76; // same as panicky threshold
// this is needed to overtake a current match.
// higher the number the more the preference goes to smaller word,
// which is good since in general, we want to not match more letters than needed
const OVERTAKE_THRESHOLD = 0.05;
const PHONETIC_TEST_MIN_WORD_LENGTH = 2;
const MIN_EXACT_MATCH_LENGTH = 2;
const MAX_WORD_TO_ANSWER_RATIO = 0.33;

const THRESHOLD_BY_LENGTH = {
  2: 0.5,
  3: 0.6,
  4: 0.7,
  5: 0.74,
  6: 0.77,
  7: 0.8,
};

const logDetails = false;

function segmentWordAndCalculateFuzzyScoresV2(inputString, wordList) {
  const matches = [];
  const fuzzyScores = [];
  let extraWordsCount = 0;
  let i = 0;

  let WINDOW_BUFFER_START = -1;
  let WINDOW_BUFFER_END = 1;

  while (i < inputString.length) {
    // Find the longest matching word in the list starting from the current index
    let maxLength = MIN_EXACT_MATCH_LENGTH;
    let match = null;
    let similarity;
    for (const word of wordList) {
      // most of the time, we can find the matching first letter
      // but in case we don't set i to one space behind in case
      // the fuzzy included the matching first letter in the previous word
      if (inputString.slice(i).charAt(0) !== word.charAt(0)) {
        // But only shift left if it's equal to the first char
        // otherwise just leave it alone
        if (inputString.slice(i - 1).charAt(0) === word.charAt(0)) {
          i = i - 1;
        }
      }
      let fuzzyScore = 0;
      if (matches.length === wordList.length && inputString.slice(i) > 1) {
        extraWordsCount++;
      }
      for (
        let buffer = WINDOW_BUFFER_START;
        buffer <= WINDOW_BUFFER_END;
        buffer++
      ) {
        const slice = inputString.slice(i, i + word.length + buffer);
        if (!slice) continue;

        if (logDetails) console.log('Compare', word, slice);

        if (slice.includes(word)) {
          similarity = 1;
        } else {
          const maxWordLength = Math.max(slice.length, word.length);
          const lev =
            (maxWordLength - levenshtein(slice, word)) / maxWordLength;

          // Sorting helps determining if the user typed all/mostly the right letters
          const sortedSlice = slice.split('').sort().join('');
          const sortedWord = word.split('').sort().join('');

          let sortedDist;
          sortedDist =
            (maxWordLength - levenshtein(sortedSlice, sortedWord)) /
            maxWordLength;

          // If sorted contains all the same, assume swap typo
          if (sortedDist === 1) {
            similarity = (lev + sortedDist * 3) / 4;
          } else {
            // similarity = lev;
            similarity = calcSimpleFuzzyScore(slice, word);
          }

          if (similarity > 0 && slice.length >= 0 && logDetails)
            console.log(
              ' -- SCORING D1 =',
              +lev.toFixed(2),
              'D2 =',
              sortedDist,
              'Sim =',
              +similarity.toFixed(2)
            );
        }

        // Conditions theory explained:
        // Small words 2 or less need very high silmiarity
        // Bigger words need to meet the min threshold
        // Since this loop checks 3 windows, each iteration checks if match is better than previous iteration
        //  BUT next iteration must be better by an OVERTAKE threshold OR last char matches OR matches favor exact length matches
        if (
          isOverThreshold(similarity, word.length) &&
          (similarity > fuzzyScore + OVERTAKE_THRESHOLD ||
            (similarity > fuzzyScore &&
              (word[word.length - 1] === slice[slice.length - 1] ||
                (similarity > fuzzyScore && word.length === slice.length))))
        ) {
          if (logDetails) {
            console.log('       ');
            console.log('         ~~~ ADDDDEDDD ~~~~ ', word, slice);
            console.log('       ');
          }
          maxLength = slice.length;
          match = word;
          fuzzyScore = similarity;

          if (similarity === 1) {
            WINDOW_BUFFER_START = 0;
            WINDOW_BUFFER_END = 2;
            break;
          }
        }
      }
      if (match) {
        matches.push(match);
        fuzzyScores.push(fuzzyScore);
        match = null;
        i += maxLength;
        maxLength = MIN_EXACT_MATCH_LENGTH;
        if (similarity < 1) {
          WINDOW_BUFFER_START = -1;
          WINDOW_BUFFER_END = 1;
        }
      } else {
        // articles/preps move the window too far,
        // so reset the window by 1 seems like a sweet spot
        if (word.length <= 3) {
          i += word.length - 1;
        } else {
          i += word.length;
        }
      }
    }
  }

  return [matches, fuzzyScores, extraWordsCount];
}

const isOverThreshold = (score, wordLength) => {
  if (
    THRESHOLD_BY_LENGTH[wordLength] &&
    score >= THRESHOLD_BY_LENGTH[wordLength]
  ) {
    return true;
  }
  if (score >= THRESHOLD_FINAL) {
    return true;
  }
  return false;
};

const getMissingWordIfLenient = (sortedMatches, sortedWordList) => {
  let missingWord;
  let s = 0;
  for (let i = 0; i < sortedWordList.length; i++) {
    if (sortedMatches[i] !== sortedWordList[s]) {
      // If a missing word is a legit word, return immediately
      if (!FUZZY_LENIENT_WORDS.includes(sortedWordList[s])) {
        return null;
      }
      missingWord = sortedWordList[s];
      if (sortedMatches.length - i === sortedWordList.length - i - 1) {
        return missingWord;
      }
    }
    s++;
  }
};

const allScoresAboveThreshold = (fuzzyScores) =>
  fuzzyScores.every((score) => score >= THRESHOLD_FINAL);

const shouldRecalculateFuzzyScore = (matches, fuzzyScores, collapsedAnswer) => {
  // This function goes through a checking sequence of:
  // - Is it a word in the dictionary? If not, assume typo
  // - Is the word less than 25% of the overall answer length
  // - Is the average of the "other" words above the acceptable threshold
  for (let i = 0; i < fuzzyScores.length; i++) {
    if (fuzzyScores[i] < THRESHOLD_FINAL) {
      // NOT SURE IF WE WANT TO RELY ON THE DICTIONARY CHECK!
      // const isWord = dictionary.check(--- pull in the user match, not from wordlist ---);
      // if (isWord) return false; // assumes not typo
      if (matches[i].length / collapsedAnswer.length > MAX_WORD_TO_ANSWER_RATIO)
        return false;
      const avg = calcAverageWithSkippingElement(fuzzyScores, i);
      if (avg < THRESHOLD_FINAL) return false;
      return true;
    }
  }
};

// No credit will be given for typing a "lenient word" correctly
const removeLenientScores = (matches, fuzzyScores) => {
  const trueFuzzyScores = [];
  for (let i = 0; i < matches.length; i++) {
    if (!FUZZY_LENIENT_WORDS.includes(matches[i])) {
      trueFuzzyScores.push(fuzzyScores[i]);
    }
  }
  return trueFuzzyScores;
};

const average = (array) => array.reduce((a, b) => a + b) / array.length;

const calcAverageWithSkippingElement = (array, skipIndex) => {
  const SKIP_ONE = 1;
  let sum = 0;
  for (let i = 0; i < array.length; i++) {
    if (i !== skipIndex) {
      sum = sum + array[i];
    }
  }
  return sum / (array.length - SKIP_ONE);
};

const calcSimpleFuzzyScore = (text1, text2) => {
  const maxWordLength = Math.max(text1.length, text2.length);
  const lev = (maxWordLength - levenshtein(text1, text2)) / maxWordLength;
  const meta1 = metaphone(text1);
  const meta2 = metaphone(text2);
  const maxWordLengthMeta = Math.max(meta1.length, meta2.length);
  const meta =
    (maxWordLengthMeta - levenshtein(meta1, meta2)) / maxWordLengthMeta;
  let weightedScore;
  if (meta < 0.8) {
    // only use the metaphone algo when the phonetics of the words are undeniably close
    weightedScore = lev;
  } else if (maxWordLengthMeta <= 3) {
    // meta algo is terrible with levenshtein but short words
    // but amazing with long words
    // so we will use a different weight for shorter words
    const defaultLevWeight = 4;
    const denom = 5;
    weightedScore = (lev * defaultLevWeight + meta) / denom;
  } else {
    const defaultLevWeight = 2;
    const denom = 3;
    weightedScore = (lev * defaultLevWeight + meta) / denom;
  }
  // console.log(
  //   'calcSimpleFuzzyScore',
  //   lev,
  //   meta1,
  //   meta2,
  //   meta,
  //   weightedScore.toFixed(2)
  // );
  return weightedScore;
};

export const calcFuzzyScore = (inputString, wordList, collapsedAnswer) => {
  // most answers can be accepted as a poised if it passes a very high jaro score
  // saves time on fuzzy calculations
  const maxWordLength = Math.max(inputString.length, collapsedAnswer.length);
  const lev =
    (maxWordLength - levenshtein(inputString, collapsedAnswer)) / maxWordLength;
  const jaroRes = jaro(inputString, collapsedAnswer);
  if (jaroRes > 0.96 || lev > 0.96) {
    return 0.99;
  }

  const [matches, fuzzyScores, extraWordsCount] =
    segmentWordAndCalculateFuzzyScoresV2(inputString, wordList);

  // This usually happens when the alternate answer is similar to the main
  // We dont want to accept "Starry Night Over The Rhone" for "Starry Night"
  if (extraWordsCount > 0) {
    return 0;
  }

  const sortedMatches = [...matches].sort();
  const sortedWordList = [...wordList].sort();

  if (!isEqual(sortedMatches, sortedWordList)) {
    // If more than 1 word is missing, return 0, leniency does not go beyond that
    if (Math.abs(sortedMatches.length - sortedWordList.length) > 1) {
      return 0;
    }

    // If missing word is on the leniency list, continue the sequence
    // otherwise this answer is missing a "main" word and does not qualify for fuzzy
    const missingWord = getMissingWordIfLenient(sortedMatches, sortedWordList);
    if (!missingWord) {
      return 0;
    }
  }

  if (!allScoresAboveThreshold(fuzzyScores)) {
    // Check words that got a fuzzy score under the acceptable threshold
    if (shouldRecalculateFuzzyScore(matches, fuzzyScores, collapsedAnswer)) {
      const simpleFuzzyScore = calcSimpleFuzzyScore(
        inputString,
        collapsedAnswer
      );
      return simpleFuzzyScore;
    }
    return 0;
  }

  // Special edge case where all words matched perfectly (score of 1) in the algo but entire word is not an exact match
  //  e.g. Answer is "starry night over the rhone" and player inputs "starry night over the rhonee"
  //  e.g. Answer is "interference" and player inputs "constructive interference"
  // If the difference is less than 10%, manually assign a poised, otherwise fail it
  const fuzzyValues = fuzzyScores.filter((n) => n < 1);
  if (!fuzzyValues.length) {
    const collapsedPlayerInput = collapseWords(
      removeFirstWordArticles(inputString)
    );
    const MAX_ACCEPTABLE_LENGTH_DIFFERENCE = 0.2;
    if (
      collapsedPlayerInput.length / collapsedAnswer.length - 1 >
      MAX_ACCEPTABLE_LENGTH_DIFFERENCE
    ) {
      return 0;
    }
    return 0.99;
  }

  // const trueFuzzyScores = removeLenientScores(matches, fuzzyScores);
  const averageScore = average(fuzzyScores);
  const trueAverageScore =
    wordList.length > 1
      ? getMultipleWordAverageScore(averageScore, inputString, collapsedAnswer)
      : averageScore;
  return trueAverageScore;
};

// if an answer is multiple words and all individual word scores are above the threshold,
// do a jaro compare on the entire collapsed input vs answer and average it with the current score
// this method might provide some leniency for those times where a user is being punished too harshly for a small typo
// using jaro here since it is more lenient with smaller typos
const getMultipleWordAverageScore = (
  averageScore,
  inputString,
  collapsedAnswer
) => {
  const jaroScore = jaro(inputString, collapsedAnswer);
  return (averageScore + jaroScore) / 2;
};

const collapseWords = (words) => {
  const collapsed = words
    .trimEnd()
    .toUpperCase()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '') // removes accents
    .replace(/ & /g, 'AND')
    .replace(/[\W_]+/g, '');
  const firstWord = collapsed.replace(/ .*/, '');
  if (firstWord === 'THE' || firstWord === 'AN' || firstWord === 'A') {
    return collapsed.substr(collapsed.indexOf(' ') + 1);
  }
  return collapsed;
};

const removeFirstWordArticles = (words) => {
  const firstWord = words.substring(0, words.indexOf(' '));
  if (EXCLUDED_ARTICLES.includes(firstWord.toUpperCase())) {
    const withoutFirstWord = words.substring(words.indexOf(' ') + 1);
    return withoutFirstWord;
  }
  return words;
};
