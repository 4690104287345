import { PaletteOptions } from '@mui/material';

export const leaguePalette: PaletteOptions = {
  background: {
    // default: '#212a31',
    paper: '#28282efa',
    // paper: '#212835',
  },
  // primary: {
  //   main: '#06e7e6',
  //   light: '#d7fcfb',
  //   dark: '#00c3d0',
  // },
  secondary: {
    main: '#F8EE95',
    light: '#fdfbe5',
    dark: '#f0de26',
  },
  // Leagues
  pro: {
    main: '#00ffb3',
  },
  diamond: {
    main: '#06e7e6',
  },
  gold: {
    main: '#e7b606',
  },
  'film-tv': {
    main: '#af89de',
  },
  sports: {
    main: '#e55c5c',
  },
  logoPink: {
    main: '#de89a1',
    dark: '#aa4586',
  },
  creamOrange: {
    main: '#dea489',
  },
  warning: {
    main: '#FF7300',
  },
  answers: {
    main: '#ffba18',
    light: '#e7ba54',
  },
  teamA: {
    main: '#ec9bff',
  },
  teamB: {
    main: '#00e0ff',
  },
  notification: {
    main: '#cd0d01',
  },
  dailies: {
    main: '#ffba18',
    light: '#e7ba54',
  },
  ranked: {
    dark: '#004961',
    light: '#48d2ff',
    main: '#00bfff',
  },
  handicap: {
    main: '#05ff07',
  },
  survival: {
    main: '#ccf49f',
  },
  demon: {
    main: '#cd0d01',
    light: '#ff372a',
    contrastText: '#ffe500',
  },
  gray: {
    main: '#7a7a7a',
  },
};

export const leaguePaletteLight: PaletteOptions = {};

export const palette = {
  background: {
    default: '#2d363f',
    // paper: '#1A2027',
  },
  primary: {
    main: '#cda68c',
    dark: '#8f7462',
  },
  secondary: {
    main: '#AA4586',
  },
  tertiary: {
    main: '#73937E',
  },
  error: {
    main: '#ED0003',
  },
  success: {
    main: '#64FF00',
    dark: '#338100',
  },
  warning: {
    main: '#FF7300',
  },
  ranked: {
    dark: '#004961',
    light: '#48d2ff',
    main: '#00bfff',
  },
  info: {
    main: '#F48FB1',
  },
  overPar: {
    main: '#518492',
    contrastText: '#fff',
  },
  underline: {
    main: '#E7FF00',
    light: '#EBFF33',
    dark: '#A1B200',
  },
  underPar: {
    main: '#0db586',
    dark: '#06563f',
    contrastText: '#fff',
  },
  dailies: {
    main: '#ffba18',
    light: '#e7ba54',
  },
  fuzzy: {
    main: '#746ba6',
  },
  tourney: {
    main: '#2de29e',
  },
  notifications: {
    main: '#DD2121',
  },
  handicap: {
    main: '#05ff07',
  },
  survival: {
    main: '#ccf49f',
  },
  demon: {
    main: '#cd0d01',
    light: '#ff372a',
    contrastText: '#ffe500',
  },
  assist: {
    main: '#458daa',
  },
  turquoise: {
    main: '#2699a0',
    light: '#41f3ff',
  },
  gray: {
    main: '#7a7a7a',
  },
};

export const paletteLight = {
  background: {
    default: '#fff',
    paper: '#EEE1D8',
  },
  primary: {
    main: '#AC724C',
    dark: '#784F35',
  },
  secondary: {
    main: '#AA4586',
  },
  tertiary: {
    main: '#597362',
    light: '#7A8F81',
    dark: '#3E5044',
  },
  error: {
    main: '#ED0003',
  },
  success: {
    main: '#50C800',
  },
  warning: {
    main: '#FF7300',
  },
  ranked: {
    dark: '#004961',
    light: '#48d2ff',
    main: '#00bfff',
  },
  info: {
    main: '#ED447C',
  },
  overPar: {
    main: '#8EB4CD',
    contrastText: '#fff',
  },
  underline: {
    main: '#839200',
    light: '#9BA733',
    dark: '#5B6600',
  },
  underPar: {
    main: '#0db586',
    dark: '#06563f',
    contrastText: '#fff',
  },
  dailies: {
    main: '#ffba18',
    light: '#e7ba54',
  },
  fuzzy: {
    main: '#746ba6',
  },
  tourney: {
    main: '#2de29e',
  },
  notifications: {
    main: '#DD2121',
  },
  handicap: {
    main: '#05ff07',
  },
  survival: {
    main: '#ccf49f',
  },
  demon: {
    main: '#cd0d01',
    light: '#ff372a',
    contrastText: '#ffe500',
  },
  assist: {
    main: '#458daa',
  },
  turquoise: {
    main: '#2699a0',
    light: '#41f3ff',
  },
};
