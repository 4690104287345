import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { TAGS, TIMER_LIMITS } from './constants';
import useCreateCustomSet from './hooks/useCreateCustomSet';
import { CustomSet } from '../../../types/custom-sets';

type Props = {
  customSet: CustomSet;
  handleBackClick: () => void;
  handleCreateSuccess: (response: any) => void;
  profileId: string;
};

const EditConfigs = ({
  customSet,
  handleBackClick,
  handleCreateSuccess,
  profileId,
}: Props) => {
  const limitEditing = customSet?.published;

  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [title, setTitle] = useState(customSet?.title || '');
  const [description, setDescription] = useState(customSet?.description || '');
  const [type, setType] = useState<'buzzer' | 'multiple-choice'>(
    customSet?.configs?.type || 'buzzer'
  );
  const [timerLimit, setTimerLimit] = useState(
    customSet?.configs?.timerLimit?.toString() || '20'
  );
  const [tags, setTags] = useState(customSet?.tags[0] || '');
  const [isPrivate, setIsPrivate] = useState(customSet?.isPrivate);

  const handleTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    type: 'buzzer' | 'multiple-choice'
  ) => {
    setType(type);
  };

  const handleTimerLimitChange = (event: SelectChangeEvent) => {
    setTimerLimit(event.target.value);
  };

  const { mutate: createCustomSet, isPending } =
    useCreateCustomSet(handleCreateSuccess);

  const handleNextClick = () => {
    if (!title) {
      setAttemptedSubmit(true);
      return;
    }

    const bodyObj = {
      customSetId: customSet?.customSetId,
      profileId,
      title,
      description,
      type,
      timerLimit,
      isPrivate,
      tags: [tags],
    };
    createCustomSet(bodyObj);
  };

  return (
    <Stack spacing={3}>
      <Typography variant="h6" textAlign={'center'}>
        Game Configs
      </Typography>

      <TextField
        label="Title"
        disabled={limitEditing}
        error={attemptedSubmit && !title}
        helperText={
          attemptedSubmit && !title
            ? 'Title is required'
            : limitEditing
            ? 'Title cannot be edited'
            : ''
        }
        variant="outlined"
        value={title}
        onChange={(event) => setTitle(event.target.value)}
      />

      <TextField
        label="Description"
        helperText={'Optional'}
        variant="outlined"
        multiline
        value={description}
        onChange={(event) => setDescription(event.target.value)}
      />

      <ToggleButtonGroup
        color="primary"
        value={type}
        exclusive
        onChange={handleTypeChange}
        fullWidth
        size="small"
        disabled={limitEditing}
      >
        <ToggleButton value="buzzer">Buzzer</ToggleButton>
        <ToggleButton value="multiple-choice">Multiple Choice</ToggleButton>
      </ToggleButtonGroup>

      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel>Maximum time per question</InputLabel>
        <Select
          value={timerLimit}
          label="Maximum time per question"
          onChange={handleTimerLimitChange}
          disabled={limitEditing}
        >
          {TIMER_LIMITS.map((limit, i) => (
            <MenuItem key={i} value={limit}>
              {limit}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        label="Tag"
        helperText={'Optional'}
        variant="outlined"
        value={tags}
        onChange={(event) => setTags(event.target.value)}
      />

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={isPrivate}
              onChange={(event) => setIsPrivate(event.target.checked)}
            />
          }
          label="Private"
        />
        <FormHelperText>
          Private games won't appear in the main list, but can be played by
          anyone with a direct link
        </FormHelperText>
      </FormGroup>

      <Box display={'flex'} justifyContent={'space-between'}>
        <Button onClick={handleBackClick}>Cancel</Button>

        <Button
          variant="contained"
          onClick={handleNextClick}
          startIcon={isPending ? <CircularProgress size={16} /> : null}
          disabled={isPending}
        >
          {customSet ? 'Update' : 'Next'}
        </Button>
      </Box>
    </Stack>
  );
};

export default EditConfigs;
