import { formattedScore } from '../scorecard/ScorecardTable';
import { UserNotification } from './types';

export const getFormattedNotification = (notification: UserNotification) => {
  if (notification.type === 'customSetPlayed') {
    const avatarPublicId = notification.metadata.avatarPublicId;
    const customSetId = notification.metadata.customSetId;
    const header = `${notification.metadata.username} played "${notification.metadata.title}"`;
    const content = `They scored: ${formattedScore(
      notification.metadata.score
    )}`;

    return {
      avatarPublicId,
      customSetId,
      header,
      content,
    };
  }

  return {
    avatarPublicId: '',
    customSetId: '',
    header: '',
    content: '',
  };
};
