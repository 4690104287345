import React, { useState } from 'react';
import {
  IconButton,
  Avatar,
  Menu,
  Divider,
  MenuItem,
  ListItemIcon,
  Typography,
  SwipeableDrawer,
  Stack,
} from '@mui/material';
import { Logout } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getAvatarSrc } from '../../../api/cloudinary';
import { Profile } from '../../../types/types';
import { useLogout } from '../../auth/hooks/useLogout';
import { DrawerHeader } from './drawer-header';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { FaDiscord, FaXTwitter } from 'react-icons/fa6';
import { SocialMediaIconButton } from './custom-icon-buttons';

type PropTypes = {
  onLogoutSuccess: () => void;
  profile: Profile;
};

export const ProfileMenu = ({ onLogoutSuccess, profile }: PropTypes) => {
  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = useState(false);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const { mutate: logout } = useLogout(onLogoutSuccess);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleGameSettingsClick = () => {
    navigate('/game-settings');
    handleCloseUserMenu();
  };

  const handleHelpClick = () => {
    navigate('/help');
    handleCloseUserMenu();
  };

  const handleLogout = () => {
    logout();
  };

  const handleProfileClick = () => {
    navigate(`/profile/${profile?._id}`);
    handleCloseUserMenu();
  };

  return (
    <>
      <IconButton
        onClick={() => setOpenDrawer(true)}
        sx={{
          borderRadius: 1,
          '&:hover': {
            borderRadius: 1,
          },
        }}
      >
        <Avatar
          alt={profile?.username}
          src={getAvatarSrc(
            profile?.avatarPublicId,
            profile?.avatarUrl,
            100,
            100
          )}
          sx={{
            borderRadius: 1,
          }}
        />
      </IconButton>

      <SwipeableDrawer
        anchor={'right'}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
      >
        <DrawerHeader
          handleCloseUserMenu={handleCloseUserMenu}
          profile={profile}
        />
        <Divider />

        <MenuItem onClick={handleProfileClick}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          <Typography textAlign="center">Profile</Typography>
        </MenuItem>

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>

        <Divider>
          <Typography variant="overline">Follow us</Typography>
        </Divider>
        <Stack
          direction="row"
          spacing={4}
          alignItems={'center'}
          justifyContent={'center'}
          px={2}
          width={'100%'}
          pb={1}
        >
          <SocialMediaIconButton
            brandColor="#5865F2"
            link="https://discord.gg/upX48KPfHd"
            icon={<FaDiscord />}
          />
          <SocialMediaIconButton
            brandColor="#000000"
            link="https://twitter.com/ParTrivia"
            icon={<FaXTwitter />}
          />
        </Stack>
      </SwipeableDrawer>
    </>
  );
};
