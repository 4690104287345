import React from 'react';
import { Stack, Typography, Box } from '@mui/material';
import { ScorecardTable } from './ScorecardTable';
import ScoreAvatar from './ScoreAvatar';
import { useNavigate } from 'react-router-dom';
import { Player, Question } from '../../../types';

type PropTypes = {
  players: Record<string, Player>;
  profileId: string;
  questions: Question[];
};

const Scorecard = ({ players, profileId, questions }: PropTypes) => {
  const navigate = useNavigate();

  return (
    <>
      <Stack position={'relative'} display={'flex'} justifyContent={'center'}>
        <Stack alignItems={'center'} justifyContent={'center'} mb={2}>
          <Typography
            variant="h6"
            my={1}
            textAlign={'center'}
            sx={{
              textShadow:
                'rgb(255, 186, 24) 0px 0px 0px, rgb(255, 186, 24) 0px 0px 0px, rgb(255, 186, 24) 0px 0px 16px',
            }}
          >
            Your score
          </Typography>
          <ScoreAvatar score={players[profileId].parScore} />
        </Stack>

        <Box width={'100%'}>
          <ScorecardTable
            players={players}
            profileId={profileId}
            questions={questions}
          />
        </Box>
      </Stack>
    </>
  );
};

export default Scorecard;
