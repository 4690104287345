import { Game, Player, Question, RoundScore } from '../../pages/game/types';
import { CustomSet } from '../../types/custom-sets';
import {
  DELAY_BEFORE_LETTER_REVEAL_COUNT,
  DELAY_BEFORE_QUESTION_REVEAL_COUNT,
  GAME_STAGES,
  NEXT_QUESTION_DELAY_COUNT,
  DISPLAY_BOXES_COUNT,
  games,
  COUNTDOWN_DURATION,
  QUESTIONS_IN_SET,
} from '../constants';
import { gameEvents } from '../event-emitter';

export const createGame = (
  customSet: CustomSet,
  gameId: string,
  profileId: string,
  questions: Question[],
  answerLettersRevealOrder: Record<number, (number | string)[][]>,
  username: string,
  avatarPublicId?: string,
  skipCountdown?: boolean
) => {
  const player = createPlayer(
    profileId,
    username,
    avatarPublicId,
    questions.length
  );

  gameEvents.emit('player', player);

  games[gameId] = {
    ...initGame(),
    answerLettersRevealOrder: answerLettersRevealOrder,
    createdAt: Date.now(),
    createdBy: profileId,
    gameId: gameId,
    gameType: 'custom',
    mode: 'solo',
    players: {
      [profileId]: player,
    },
    questionSet: questions,
    questionIds: questions.map((q) => q._id),
    questionsCount: questions.length,
    skipCountdown: skipCountdown,
    timerLimit: customSet.configs.timerLimit,
    type: customSet.configs.type,
    version: customSet.version,
  };

  return games[gameId];
};

export const createPlayer = (
  profileId: string,
  username: string,
  avatarPublicId: string,
  questionsCount: number
): Player => ({
  avatarPublicId: avatarPublicId,
  correctAnswer: false,
  currentScore: null,
  incorrectAnswer: false,
  fuzzyScore: null,
  isPromptAnswer: false,
  parScore: 0,
  profileId: profileId,
  roundScores: createRoundScores(questionsCount),
  timerLimitReached: false,
  totalPenalty: 0,
  username: username,
});

const createRoundScores = (
  questionsInSet: number
): Record<number, RoundScore> => {
  const obj: Record<number, RoundScore> = {};
  const roundScores: RoundScore = {
    score: null,
    fuzzyPenalty: null,
    answerAttempts: [],
    penalty: null,
    timerLimitReached: false,
  };
  for (let i = 0; i < questionsInSet; i++) {
    obj[i] = { ...roundScores };
  }
  return obj;
};

// add global constants here!!!!
const initGame = (): Game => ({
  activePlayers: [],
  allPlayersAnsweredCorrectly: false,
  answerLetters: [],
  answerLettersRevealOrder: null,
  answerLettersRevealOrderCount: 0,
  buzzerAttemptsRemaining: 3,
  collapsedAlternateAnswers: [],
  collapsedAnswer: '',
  countdown: COUNTDOWN_DURATION + 1, // +1 to account for the gameloop offset
  createdAt: null,
  createdBy: null,
  customSetId: null,
  delayBeforeLetterRevealCount: DELAY_BEFORE_LETTER_REVEAL_COUNT,
  delayBeforeQuestionRevealCount: DELAY_BEFORE_QUESTION_REVEAL_COUNT,
  gameClock: 0,
  gameClockIntervalTimestamp: null,
  gameId: null,
  gameOver: false,
  gameStage: GAME_STAGES.COUNT_DOWN,
  gameStarted: true,
  gameType: 'casual', // pro vs casual
  mode: null, // 'match', 'solo', 'pool', 'bracket', 'sit-n-go'
  nextQuestionDelayCount: NEXT_QUESTION_DELAY_COUNT,
  paused: false,
  pausedTimestamp: null,
  players: {},
  preventAnswers: {},
  questionData: null,
  questionIndex: 0,
  questionMetadata: {
    category: '',
    par: 0,
    questionSetCount: 0,
  },
  questionsCount: 10,
  questionIds: [],
  questionSet: [],
  questionWordIndex: 0,
  questionWords: [],
  ranked: false,
  revealAnswer: null,
  revealAnswerAtSecond: null,
  revealBoxesCount: DISPLAY_BOXES_COUNT,
  revealedLetters: [],
  revealModOffset: null,
  revealQuestion: '',
  revealTiming: 0,
  startTime: 0,
  timerLimit: 20,
  timerLimitReached: false,
  type: 'buzzer',
  unrevealedIndex: null,
  unrevealedProperNameIndex: null,
  version: 1,
});
