import { Avatar, Box } from '@mui/material';
import React from 'react';
import { formattedScore } from '../../scorecard/ScorecardTable';

type Props = {
  score: number;
  type: 'error' | 'success';
};
export const DisplayScore = ({ score, type }: Props) => {
  return (
    <Box justifyContent={'center'} display={'flex'}>
      <Avatar
        sx={{
          bgcolor: `${type}.main`,
          color: `${type}.contrastText`,
          // width: 32,
          // height: 32,
        }}
      >
        {formattedScore(score)}
      </Avatar>
    </Box>
  );
};
