import { useQuery } from '@tanstack/react-query';
import { getProfileById } from '../../../api/apiServices';

const useGetProfileById = (profileId: string) => {
  const { data, refetch } = useQuery({
    queryKey: ['getProfileById', { profileId }],
    queryFn: getProfileById,
  });

  return {
    refetch,
    data,
  };
};

export default useGetProfileById;
