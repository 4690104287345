import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CustomSet } from '../../../types/custom-sets';
import QuestionCard from './QuestionCard';
import EditQuestionDialog from './dialogs/EditQuestionDialog/EditQuestionDialog';
import useGetCustomSetQuestions from './hooks/useGetCustomSetQuestions';
import SettingsIcon from '@mui/icons-material/Settings';
import AddIcon from '@mui/icons-material/Add';
import { Question } from '../../game/types';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { games } from '../../../game-engine/constants';
import Previewer from '../Previewer';
import { Profile } from '../../../types/types';
import DeleteIcon from '@mui/icons-material/Delete';
import useDeleteCustomSet from './hooks/useDeleteCustomSet';
import DeleteConfirmDialog from './dialogs/DeleteConfirmDialog';
import PublishIcon from '@mui/icons-material/Publish';
import PublishConfirmDialog from './dialogs/PublishConfirmDialog';
import usePublishCustomSet from './hooks/usePublishCustomSet';
import SortIcon from '@mui/icons-material/Sort';
import DraggableSortDialog from './dialogs/DraggableSortDialog/DraggableSortDialog';

type Props = {
  customSet: CustomSet;
  handleBackClick: () => void;
  handleGameConfigsClick: () => void;
  handleOnSetDeleteSuccess: () => void;
  handleOnSetPublishSuccess: () => void;
  handleOnQuestionCreateSuccess: () => void;
  handleOnQuestionDeleteSuccess: () => void;
  onPlayTestEnd: () => void;
  onPlayTestStart: () => void;
  profile: Profile;
};

const CreateQuestions = ({
  customSet,
  handleBackClick,
  handleGameConfigsClick,
  handleOnSetDeleteSuccess,
  handleOnSetPublishSuccess,
  handleOnQuestionCreateSuccess,
  handleOnQuestionDeleteSuccess,
  onPlayTestEnd,
  onPlayTestStart,
  profile,
}: Props) => {
  const [startPlayTest, setStartPlayTest] = useState<'all' | Question | null>(
    null
  );
  const [selectedQuestion, setSelectedQuestion] = useState<Question>();
  const [openEditQuestionDialog, setOpenEditQuestionDialog] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [openPublishConfirmDialog, setOpenPublishConfirmDialog] =
    useState(false);
  const [openSortDialog, setOpenSortDialog] = useState(false);

  const {
    data: customSetQuestions,
    isPending,
    refetch,
  } = useGetCustomSetQuestions(customSet?.customSetId);

  const handleNewQuestionClick = () => {
    setSelectedQuestion(undefined);
    setOpenEditQuestionDialog(true);
  };

  const handleQuestionCardClick = (question: Question) => {
    setSelectedQuestion(question);
    setOpenEditQuestionDialog(true);
  };

  const handleQuestionCreateSuccess = () => {
    setOpenEditQuestionDialog(false);
    refetch();
    handleOnQuestionCreateSuccess();
  };

  const handlePlayTestClick = () => {
    setStartPlayTest('all');
    onPlayTestStart();
  };

  const handlePlayQuestionClick = (e: any, question: Question) => {
    e.stopPropagation();
    setStartPlayTest(question);
    onPlayTestStart();
  };

  const handleGameOver = () => {
    setStartPlayTest(null);
    onPlayTestEnd();
    delete games['test-game-id'];
  };

  const handleSetDeleteSuccess = () => {
    setOpenDeleteConfirmDialog(false);
    handleOnSetDeleteSuccess();
  };

  const handleQuestionDeleteSuccess = () => {
    refetch();
    setOpenEditQuestionDialog(false);
    handleOnQuestionDeleteSuccess();
  };

  const { mutate: deleteCustomSet } = useDeleteCustomSet(
    handleSetDeleteSuccess
  );

  const handleDeleteSetClick = () => {
    if (!customSet?.customSetId) return;
    setOpenDeleteConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    if (!customSet?.customSetId) return;
    deleteCustomSet({ customSetId: customSet.customSetId });
  };

  const {
    mutate: publishCustomSet,
    isPending: publishCustomSetIsPending,
    isSuccess: publishCustomSetIsSuccess,
  } = usePublishCustomSet();

  const handlePublishSetClick = () => {
    if (!customSet?.customSetId) return;
    setOpenPublishConfirmDialog(true);
  };

  const handleConfirmPublish = () => {
    if (!customSet?.customSetId) return;
    publishCustomSet({ customSetId: customSet.customSetId });
    handleOnSetPublishSuccess();
  };

  const handleClosePublishDialog = () => {
    setOpenPublishConfirmDialog(false);
  };

  const handleUpdateSortSuccess = () => {
    refetch();
    setOpenSortDialog(false);
  };

  return (
    <>
      {startPlayTest ? (
        <Previewer
          avatarPublicId={profile?.avatarPublicId}
          avatarUrl={profile?.avatarUrl}
          customSet={customSet}
          gameId="test-game-id"
          handleGameOver={handleGameOver}
          profileId={profile?._id}
          questions={
            startPlayTest === 'all' ? customSetQuestions : [startPlayTest]
          }
          skipCountdown
          username={profile?.username}
        />
      ) : (
        <Stack>
          <Stack
            spacing={2}
            height={'calc(100dvh - 162px)'}
            overflow={'auto'}
            pb={4}
          >
            <Stack>
              <Stack direction={'row'} justifyContent={'left'}>
                <Button
                  startIcon={<ArrowBackIcon />}
                  onClick={handleBackClick}
                  size="small"
                >
                  Lists
                </Button>
              </Stack>

              <Typography textAlign={'center'}>"{customSet?.title}"</Typography>
            </Stack>

            <Stack direction={'row'} spacing={2}>
              <Button
                variant="outlined"
                onClick={handleGameConfigsClick}
                startIcon={<SettingsIcon />}
                fullWidth
              >
                Game Configs
              </Button>
              <Button
                variant="outlined"
                onClick={handleNewQuestionClick}
                startIcon={<AddIcon />}
                fullWidth
              >
                New Question
              </Button>
            </Stack>

            <Stack direction={'row'} justifyContent={'space-between'}>
              <Button
                onClick={handlePlayTestClick}
                startIcon={<PlayCircleOutlineIcon />}
                color="secondary"
                size="small"
                disabled={!customSetQuestions?.length}
              >
                Play Test
              </Button>

              <Button
                onClick={() => setOpenSortDialog(true)}
                startIcon={<SortIcon />}
                color="creamOrange"
                size="small"
                disabled={!customSetQuestions?.length}
              >
                Sort
              </Button>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showDetails}
                      onChange={(event) => setShowDetails(event.target.checked)}
                      disabled={!customSetQuestions?.length}
                      size="small"
                    />
                  }
                  label="Details"
                />
              </FormGroup>
            </Stack>

            <Stack>
              {customSetQuestions?.length ? (
                customSetQuestions
                  .sort((a, b) => a.sortOrder - b.sortOrder)
                  .map((question, i) => (
                    <QuestionCard
                      key={i}
                      handleCardClick={() => handleQuestionCardClick(question)}
                      handlePlayQuestionClick={(e, question) =>
                        handlePlayQuestionClick(e, question)
                      }
                      question={question}
                      showDetails={showDetails}
                    />
                  ))
              ) : (
                <Typography textAlign={'center'} color="lightgray">
                  No questions yet
                </Typography>
              )}
            </Stack>
          </Stack>

          <Stack direction={'row'} spacing={2}>
            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={handleDeleteSetClick}
              color="error"
              fullWidth
              size="small"
            >
              Delete Set
            </Button>
            <Button
              variant="contained"
              startIcon={<PublishIcon />}
              onClick={handlePublishSetClick}
              color="success"
              fullWidth
              size="small"
              disabled={!customSetQuestions?.length}
            >
              Publish
            </Button>
          </Stack>
        </Stack>
      )}

      <EditQuestionDialog
        customSetId={customSet?.customSetId}
        existingQuestion={selectedQuestion}
        handleOnCreateSuccess={handleQuestionCreateSuccess}
        handleOnDeleteSuccess={handleQuestionDeleteSuccess}
        open={openEditQuestionDialog}
        onClose={() => setOpenEditQuestionDialog(false)}
        profileId={profile._id}
        type={customSet?.configs?.type}
      />

      <DeleteConfirmDialog
        confirmAction={handleConfirmDelete}
        onClose={() => setOpenDeleteConfirmDialog(false)}
        open={openDeleteConfirmDialog}
      />

      <PublishConfirmDialog
        confirmAction={handleConfirmPublish}
        customSetId={customSet?.customSetId}
        isPending={publishCustomSetIsPending}
        open={openPublishConfirmDialog}
        onClose={handleClosePublishDialog}
        published={publishCustomSetIsSuccess}
        title={customSet?.title}
      />

      {customSetQuestions && (
        <DraggableSortDialog
          handleUpdateSortSuccess={handleUpdateSortSuccess}
          open={openSortDialog}
          onClose={() => setOpenSortDialog(false)}
          customSetId={customSet?.customSetId}
          customSetQuestions={customSetQuestions}
        />
      )}
    </>
  );
};

export default CreateQuestions;
