import CloseIcon from '@mui/icons-material/Close';
import {
  useMediaQuery,
  Dialog,
  DialogContent,
  IconButton,
  useTheme,
  DialogTitle,
  Rating,
  Button,
  DialogActions,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import useUpdateRating from './useUpdateRating';

type Props = {
  customSetId: string;
  onClose: () => void;
  open: boolean;
  profileId: string;
  refetchProfile: () => void;
};

const RatingsDialog = ({
  customSetId,
  onClose,
  open,
  profileId,
  refetchProfile,
}: Props) => {
  const [rating, setRating] = useState<number | null>(null);
  const [comment, setComment] = useState<string>('');

  const handleOnSuccess = () => {
    refetchProfile();
    onClose();
  };

  const { mutate: updateRating } = useUpdateRating(handleOnSuccess);

  const handleRatingChange = (
    event: React.ChangeEvent<{}>,
    value: number | null
  ) => {
    setRating(value);
  };

  const handleSubmitClick = () => {
    if (rating) {
      updateRating({ profileId, customSetId, rating, comment });
    }
  };

  return (
    <Dialog onClose={onClose} open={open} fullWidth>
      <DialogTitle textAlign={'center'}>Rate this set</DialogTitle>
      <DialogContent>
        <Stack
          py={2}
          spacing={2}
          width={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Typography variant="body2" textAlign={'center'}>
            Your feedback will be anonymous
          </Typography>
          <Rating value={rating} onChange={handleRatingChange} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <Button
          onClick={handleSubmitClick}
          color="primary"
          disabled={rating === null}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default RatingsDialog;
