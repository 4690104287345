import { useQuery } from '@tanstack/react-query';
import { getCustomSets } from '../../../api/apiServices';

const useGetCustomSets = () => {
  const { data, refetch } = useQuery({
    queryKey: ['customSets'],
    queryFn: getCustomSets,
  });

  return {
    refetch,
    data,
  };
};

export default useGetCustomSets;
