import { useQuery } from '@tanstack/react-query';
import { getCustomGame } from '../../../api/apiServices';

const useGetCustomGame = (
  profileId: string,
  customSetId: string,
  played: boolean,
  isGuest: boolean
) => {
  const { data, isPending, refetch } = useQuery({
    queryKey: ['customGame', { profileId, customSetId }],
    queryFn: getCustomGame,
    enabled: played && !isGuest,
  });

  return {
    refetch,
    data,
    isPending,
  };
};

export default useGetCustomGame;
