import { Game } from '../../pages/game/types';
import { EXCLUDED_ARTICLES } from '../constants';
import { EXACT_MATCH_REQUIRED_LENGTH, fuzzyMatchSequence } from './fuzzy';

const isMatchExcludingArticles = (
  playerInput: string,
  collapsedAnswer: string
) => {
  for (const article of EXCLUDED_ARTICLES) {
    if (playerInput.substring(0, article.length) === article) {
      const playerInputWithoutArticle = playerInput.substring(article.length);
      if (playerInputWithoutArticle === collapsedAnswer) {
        return true;
      }
    }
  }
  return false;
};

const isExactMatch = (playerInput: string, collapsedAnswer: string) => {
  if (playerInput === collapsedAnswer) {
    return true;
  }
  if (isMatchExcludingArticles(playerInput, collapsedAnswer)) {
    return true;
  }
  return false;
};

const qualifiesForPristine = (
  answerAttempts: string[],
  answer: string,
  submittedPromptAnswer?: string
) => {
  if (Object.keys(answerAttempts).length > 0) return false;
  if (submittedPromptAnswer) {
    return (
      submittedPromptAnswer.length + answer.length > EXACT_MATCH_REQUIRED_LENGTH
    );
  }
  return answer.length > EXACT_MATCH_REQUIRED_LENGTH;
};

const isExactMatchOnAlternateAnswer = (
  playerInput: string,
  alternateAnswers: string[]
) => {
  for (const altAnswer of alternateAnswers) {
    if (playerInput === altAnswer) {
      return altAnswer;
    }
    if (isMatchExcludingArticles(playerInput, altAnswer)) {
      return altAnswer;
    }
  }
  return null;
};

const removeFirstWordArticles = (words: string) => {
  const firstWord = words.substring(0, words.indexOf(' '));
  if (EXCLUDED_ARTICLES.includes(firstWord.toUpperCase())) {
    const withoutFirstWord = words.substring(words.indexOf(' ') + 1);
    return withoutFirstWord;
  }
  return words;
};

const removeRomanNumeralsFromAnswer = (answer: string) => {
  const words = answer.split(' ');
  const newWord = [];
  let romanNumerals = null;
  // for (const word of words) {
  //   if (isRomanNumerals(word)) {
  //     romanNumerals = word;
  //   } else {
  //     newWord.push(word);
  //   }
  // }
  // TEMP: currently assuming all roman numerals are at the end of the answer
  // This doesn't always work, but since answer that start with "I" isn't handled right now,
  // this is a temporary assumption until we figure out how to distinguish between I vs I
  const lastWord = words[words.length - 1];
  if (isRomanNumerals(lastWord)) {
    romanNumerals = lastWord;
  } else {
    newWord.push(...words);
  }
  return { answerWithoutRomanNumerals: newWord.join(' '), romanNumerals };
};

const isRomanNumerals = (str: string) => {
  const regex = /^M{0,3}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/;
  return regex.test(str);
};

const removeNumbersFromAnswer = (answer: string) => {
  const words = answer.split(' ');
  const newWord = [];
  const numbers = [];
  for (const word of words) {
    if (+word === +word) {
      // if is a number
      numbers.push(word);
    } else {
      newWord.push(word);
    }
  }
  return { answerWithoutNumbers: newWord.join(' '), numbers: numbers.join('') };
};

const splitAnswerAndNormalize = (answer: string) => {
  const splitAnswer = answer.split(' ');
  if (EXCLUDED_ARTICLES.includes(splitAnswer[0].toUpperCase())) {
    splitAnswer.shift();
  }
  return splitAnswer.map((w) => removeAccentsAndReplaceSpecialCharacters(w));
};

const removeAccentsAndReplaceSpecialCharacters = (text: string) =>
  text
    .trimEnd()
    .toUpperCase()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .replace(/&/g, 'AND')
    .replace(/[\W_]+/g, '');

export const isCorrectAnswer = (
  game: Game,
  playerInput: string,
  collapsedAnswer: string,
  collapsedAlternateAnswers: string[], // collapsed
  gameClock: number,
  profileId: string,
  questionIndex: number,
  plural?: string,
  submittedPromptAnswer?: string
) => {
  const cleanedCollapsedAlternateAnswers = [
    ...new Set(
      collapsedAlternateAnswers.filter((a) => a !== submittedPromptAnswer)
    ),
  ].filter((a) => !!a);

  const t0 = performance.now();
  const roundScores = game.players[profileId].roundScores;
  if (isExactMatch(playerInput, collapsedAnswer)) {
    // Check if it is a "Pristine" answer: first attempt no spelling errors
    const answerAttempts = roundScores[questionIndex].answerAttempts;
    if (
      game.type === 'buzzer' &&
      qualifiesForPristine(
        Object.keys(answerAttempts),
        collapsedAnswer,
        submittedPromptAnswer
      )
    ) {
      return { correct: true, fuzzy: 1 };
    }
    return { correct: true, fuzzy: 0 };
  }

  const matchingAltAnswer = isExactMatchOnAlternateAnswer(
    playerInput,
    cleanedCollapsedAlternateAnswers
  );
  if (matchingAltAnswer) {
    // Check if it is a "Pristine" answer: first attempt no spelling errors
    const answerAttempts = roundScores[questionIndex].answerAttempts;
    if (
      qualifiesForPristine(
        Object.keys(answerAttempts),
        matchingAltAnswer,
        submittedPromptAnswer
      )
    ) {
      return { correct: true, fuzzy: 1 };
    }
    return { correct: true, fuzzy: 0 };
  }

  const answer = game.questionData.answer;
  const { answerWithoutRomanNumerals, romanNumerals } =
    removeRomanNumeralsFromAnswer(removeFirstWordArticles(answer));
  const { answerWithoutNumbers: answerWoNumbersAndRomanNumerals, numbers } =
    removeNumbersFromAnswer(answerWithoutRomanNumerals);

  const answerWordList = submittedPromptAnswer
    ? splitAnswerAndNormalize(answer).filter(
        (a) => a.toUpperCase() !== submittedPromptAnswer.toUpperCase()
      )
    : splitAnswerAndNormalize(answer).filter((a) => !!a);

  const { correct, fuzzy } = fuzzyMatchSequence(
    playerInput,
    collapsedAnswer,
    answerWoNumbersAndRomanNumerals,
    romanNumerals,
    numbers,
    gameClock,
    answerWordList,
    plural
  );

  if (correct) {
    const t1 = performance.now();
    console.log(`FUZZY TOOK: ${t1 - t0} milliseconds.`);
    return { correct, fuzzy };
  }

  // if player's answer is prepended with an article, remove and check if correct with main answer fuzzy
  for (const article of EXCLUDED_ARTICLES) {
    if (playerInput.substring(0, article.length) === article) {
      const playerInputWithoutArticle = playerInput.substring(article.length);
      const { correct, fuzzy } = fuzzyMatchSequence(
        playerInputWithoutArticle,
        collapsedAnswer,
        answerWoNumbersAndRomanNumerals,
        romanNumerals,
        numbers,
        gameClock,
        answerWordList
      );
      if (correct) {
        const t1 = performance.now();
        console.log(`Articles FUZZY TOOK: ${t1 - t0} milliseconds.`);
        return { correct: true, fuzzy: fuzzy };
      }
    }
  }

  const altAnswers = game.questionData.alternateAnswers;
  const alternateAnswers = submittedPromptAnswer
    ? altAnswers.filter(
        (a) => a.toUpperCase() !== submittedPromptAnswer.toUpperCase()
      )
    : altAnswers.filter((a) => !!a);
  // console.log('alternateAnswers', alternateAnswers);
  // Handle fuzzy cases on alternate answers
  // NOTE: Alternate answers are generally more rare, so it's efficient to separate this check from the main answer
  // NOTE2: alternate answers already include collapsed versions of
  //  'last word', 'last two words', 'first word', 'first two words'
  for (let i = 0; i < cleanedCollapsedAlternateAnswers.length; i++) {
    if (!cleanedCollapsedAlternateAnswers[i] || !alternateAnswers[i]) continue;
    const {
      answerWithoutRomanNumerals: altAnswerWithoutRomanNumerals,
      romanNumerals: altRomanNumerals,
    } = removeRomanNumeralsFromAnswer(
      removeFirstWordArticles(alternateAnswers[i])
    );
    const {
      answerWithoutNumbers: altAnswerWoNumbersAndRomanNumerals,
      numbers: altNumbers,
    } = removeNumbersFromAnswer(altAnswerWithoutRomanNumerals);
    const altAnswerWordList = splitAnswerAndNormalize(alternateAnswers[i]);

    const { correct, fuzzy } = fuzzyMatchSequence(
      playerInput,
      cleanedCollapsedAlternateAnswers[i],
      altAnswerWoNumbersAndRomanNumerals,
      altRomanNumerals,
      altNumbers,
      gameClock,
      altAnswerWordList,
      plural
    );

    if (correct) {
      const t1 = performance.now();
      console.log(`ALT FUZZY TOOK: ${t1 - t0} milliseconds.`);
      return { correct, fuzzy };
    }
  }

  // Do we need another fuzzy check here for alternate answer without articles??? Maybe can get away with not having it and save some cpu

  return { correct: false, fuzzy: 0 };
};
