import React, { FormEvent, useEffect, useRef, useState } from 'react';

import { LoginPresenter } from './LoginPresenter';
import { useLocation } from 'react-router-dom';
import { useResendEmailVerification } from '../hooks/useResendEmailVerification';
import useAuthSignup from '../hooks/useSignup';
import { useGoogleLogin } from '../hooks/useGoogleLogin';
import useLogin from '../hooks/useLogin';
import useForgotPassword from '../hooks/useForgotPassword';

type LocationState = {
  resendVerficationEmail: string;
  showResendVerification: boolean;
};

type Props = {
  refetchProfile: () => void;
};

export const Login = ({ refetchProfile }: Props) => {
  const location = useLocation();
  const locationState = location.state as LocationState;

  const handleLoginSuccess = () => {
    refetchProfile();
  };

  const handleLoginError = (error: any) => {
    setPasswordErrorMessage(error?.message);
  };

  const { mutate: login } = useLogin(handleLoginSuccess, handleLoginError);

  const { googleLogin, error: googleError } = useGoogleLogin(refetchProfile);

  const handleSignupSuccess = () => {
    setShowVerifyEmailMessage(true);
  };

  const handleSignupError = (error: any) => {
    setEmailErrorMessage(error?.message);
  };

  const { mutate: signup } = useAuthSignup(
    handleSignupSuccess,
    handleSignupError
  );

  const handleForgotPasswordSuccess = () => {
    setShowResetPasswordMessage(true);
  };

  const handleForgotPasswordError = (error: any) => {};

  const { mutate: forgotPassword } = useForgotPassword(
    handleForgotPasswordSuccess,
    handleForgotPasswordError
  );

  const handleResendEmailSuccess = () => {
    setShowResendVerificationMessage(false);
    setShowVerifyEmailMessage(true);
  };

  const handleResendEmailError = (error: any) => {};

  const { mutate: resendEmailVerification } = useResendEmailVerification(
    handleResendEmailSuccess,
    handleResendEmailError
  );

  const [authType, setAuthType] = useState<
    'login' | 'signUp' | 'forgotPassword' | 'resendVerification'
  >('login');
  const [email, setEmail] = useState('');
  const emailRef = useRef<HTMLInputElement>(null);
  const [password, setPassword] = useState('');
  const passwordRef = useRef<HTMLInputElement>(null);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [showVerifyEmailMessage, setShowVerifyEmailMessage] = useState(false);
  const [showResetPasswordMessage, setShowResetPasswordMessage] =
    useState(false);
  const [showResendVerificationMessage, setShowResendVerificationMessage] =
    useState(false);

  useEffect(() => {
    if (locationState?.showResendVerification) {
      setEmail(locationState.resendVerficationEmail);
      setAuthType('resendVerification');
      setShowResendVerificationMessage(true);
    }
  }, [locationState]);

  const handleGoogleLogin = googleLogin;

  const handleLoginClick = async () => {
    if (!password) {
      setPasswordErrorMessage('Please enter a password.');
    } else {
      setPasswordErrorMessage('');
      login({ email, password });
    }
  };

  const handleSignUpClick = async () => {
    if (!password) {
      setPasswordErrorMessage('Please enter a password.');
    } else if (password.length < 8) {
      setPasswordErrorMessage(
        'Please provide a password that is at least 8 characters.'
      );
    } else {
      setPasswordErrorMessage('');
      signup({ email, password });
    }
  };

  const handleResetPasswordClick = async () => {
    if (!email) {
      setEmailErrorMessage('Please enter your email address.');
      return;
    }
    forgotPassword({ email });
  };

  const handleResendVerificationEmailClick = async () => {
    if (!email) {
      setEmailErrorMessage('Email required');
      return;
    }
    resendEmailVerification({ email });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    if (authType === 'login') {
      handleLoginClick();
    } else if (authType === 'forgotPassword') {
      handleResetPasswordClick();
    } else if (authType === 'resendVerification') {
      handleResendVerificationEmailClick();
    } else if (authType === 'signUp') {
      handleSignUpClick();
    }
  };

  return (
    <LoginPresenter
      authType={authType}
      email={email}
      emailRef={emailRef}
      password={password}
      passwordRef={passwordRef}
      emailErrorMessage={emailErrorMessage}
      passwordErrorMessage={passwordErrorMessage}
      showVerifyEmailMessage={showVerifyEmailMessage}
      showResetPasswordMessage={showResetPasswordMessage}
      showResendVerificationMessage={showResendVerificationMessage}
      handleGoogleLogin={handleGoogleLogin}
      handleSignUpClick={handleSignUpClick}
      handleSubmit={handleSubmit}
      setAuthType={setAuthType}
      setEmail={setEmail}
      setPassword={setPassword}
      setEmailErrorMessage={setEmailErrorMessage}
      setPasswordErrorMessage={setPasswordErrorMessage}
      googleError={googleError?.message}
    />
  );
};
