import { useMutation } from '@tanstack/react-query';
import { postUpdateProfile } from '../../../api/apiServices';

const useUpdateProfile = (handleOnSuccess: () => void) => {
  const { mutate, isPending } = useMutation({
    mutationFn: postUpdateProfile,
    onSuccess: handleOnSuccess,
  });

  return {
    mutate,
    isPending,
  };
};

export default useUpdateProfile;
