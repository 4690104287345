import { useQuery } from '@tanstack/react-query';
import { getUnseenUserNotifications } from '../../../api/apiServices';

const useGetUnseenUserNotifications = (shouldFetch: boolean) => {
  const { data, refetch } = useQuery({
    queryKey: ['unseenUserNotifications'],
    queryFn: getUnseenUserNotifications,
    enabled: shouldFetch,
  });

  return {
    refetch,
    data,
  };
};

export default useGetUnseenUserNotifications;
