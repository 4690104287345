import {
  Box,
  CardActionArea,
  CardMedia,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { getAvatarSrc } from '../../../api/cloudinary';
import { useNavigate } from 'react-router-dom';
import { Profile } from '../../../types/types';

type PropTypes = {
  handleCloseUserMenu: () => void;
  profile: Profile;
};
export const DrawerHeader = ({ handleCloseUserMenu, profile }: PropTypes) => {
  const navigate = useNavigate();

  const handleProfilePictureClick = () => {
    navigate(`/profile/${profile?._id}`);
    handleCloseUserMenu();
  };

  return (
    <Stack width={240}>
      <Box
        bgcolor={'info.dark'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        px={2}
      >
        <Typography
          flex={1}
          textAlign={'left'}
          fontWeight={600}
          sx={{
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            whiteSpace: 'normal',
            flexShrink: 1,
            minWidth: 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {profile?.username}
        </Typography>

        <CardActionArea
          onClick={handleProfilePictureClick}
          sx={{
            borderRadius: '8px',
            border: '2px solid',
            borderColor: 'background.paper',
            position: 'relative',
            top: '24px',
            height: '60px',
            width: '60px',
          }}
        >
          <CardMedia
            component="img"
            image={getAvatarSrc(
              profile?.avatarPublicId,
              profile?.avatarUrl,
              100,
              100
            )}
            alt="profile picture"
            sx={{
              height: '60px',
              width: '60px',
            }}
          />
        </CardActionArea>
      </Box>
      <Box px={2} py={1}>
        {profile?.fullName ? (
          <>
            <Typography variant="subtitle2" textAlign={'left'}>
              {profile?.fullName}{' '}
            </Typography>
            <Typography variant="caption">
              {profile?.genderPronoun ? `${profile.genderPronoun}` : ''}
            </Typography>
          </>
        ) : null}
      </Box>
    </Stack>
  );
};
