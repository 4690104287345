import React from 'react';
import GameSession from '../game/GameSession';
import { Button, Stack, Typography, colors } from '@mui/material';
import { games } from '../../game-engine/constants';
import { Question } from '../game/types';

type Props = {
  avatarPublicId: string;
  avatarUrl: string;
  customSet: any;
  gameId: string;
  handleGameOver: () => void;
  profileId: string;
  questions: Question[];
  skipCountdown?: boolean;
  username: string;
};

const Previewer = ({
  avatarPublicId,
  avatarUrl,
  customSet,
  gameId,
  handleGameOver,
  profileId,
  questions,
  skipCountdown,
  username,
}: Props) => {
  const handleExitClick = () => {
    games[gameId].gameOver = true;
    handleGameOver();
  };

  return (
    <Stack>
      <Stack
        direction={'row'}
        spacing={1}
        justifyContent={'space-between'}
        alignItems={'center'}
        bgcolor={colors.deepOrange[900]}
      >
        <Typography>Test mode</Typography>

        <Button onClick={handleExitClick} color="secondary" size="small">
          Exit
        </Button>
      </Stack>
      <GameSession
        answerLettersRevealOrder={null}
        avatarPublicId={avatarPublicId}
        avatarUrl={avatarUrl}
        customSet={customSet}
        gameId={gameId}
        handleGameOver={handleGameOver}
        profileId={profileId}
        questions={questions}
        skipCountdown={skipCountdown}
        username={username}
      />
    </Stack>
  );
};

export default Previewer;
