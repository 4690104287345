import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Stack } from '@mui/material';
import { AUTH_TYPE_BUTTON_LABEL } from './constants';
import { TitleAuth } from './TitleAuth';
import { InputPassword } from './InputPassword';
import useAuthResetPassword from '../../hooks/useResetPassword';
import { useParams } from 'react-router-dom';

type Props = {
  refetchProfile: () => void;
};

export const ResetPassword = ({ refetchProfile }: Props) => {
  const { token } = useParams();

  const handleOnSuccess = () => {
    refetchProfile();
  };

  const handleOnError = (error: any) => {
    setPasswordErrorMessage(error?.message);
  };

  const { mutate: resetPassword } = useAuthResetPassword(
    handleOnSuccess,
    handleOnError
  );
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const passwordRef = useRef<HTMLInputElement>(null);
  const [password, setPassword] = useState('');

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!password) {
      return setPasswordErrorMessage('Fill in all fields');
    }

    setPasswordErrorMessage('');
    resetPassword({ token, password });
  };

  return (
    <Container maxWidth="xs" sx={{ mt: 5 }}>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2} padding={2}>
          <TitleAuth authType={'resetPassword'} />

          <InputPassword
            inputRef={passwordRef}
            passwordErrorMessage={passwordErrorMessage}
            setPasswordErrorMessage={setPasswordErrorMessage}
            password={password}
            setPassword={setPassword}
          />

          <Button type="submit" variant="contained" fullWidth>
            {AUTH_TYPE_BUTTON_LABEL['resetPassword']}
          </Button>
        </Stack>
      </form>
    </Container>
  );
};
