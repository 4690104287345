import { useMutation } from '@tanstack/react-query';
import { postCustomGame } from '../../../api/apiServices';

const useUploadCustomGame = (handleOnSuccess: () => void) => {
  const { mutate } = useMutation({
    mutationFn: postCustomGame,
    onSuccess: handleOnSuccess,
  });

  return {
    mutate,
  };
};

export default useUploadCustomGame;
