import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  useTheme,
  Slide,
  TextField,
  Stack,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormHelperText,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { BUZZER_ANSWER_REVEAL_OPTIONS, PAR_OPTIONS } from './constants';
import { Add, Visibility, VisibilityOff } from '@mui/icons-material';
import useCreateCustomSetQuestion from './hooks/useCreateCustomSetQuestion';
import { Question } from '../../../../game/types';
import useDeleteCustomSetQuestion from './hooks/useDeleteCustomSetQuestion';
import FontDownloadIcon from '@mui/icons-material/FontDownload';

const MAX_CATEGORY_LENGTH = 16;
const MAX_QUESTION_LENGTH = 300;
const MAX_ANSWER_LENGTH = 40;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type PropTypes = {
  customSetId: string;
  existingQuestion: Question;
  handleOnCreateSuccess: () => void;
  handleOnDeleteSuccess: () => void;
  limitEditing?: boolean;
  onClose: () => void;
  open: boolean;
  profileId: string;
  type: 'buzzer' | 'multiple-choice';
};

const EditQuestionDialog = ({
  customSetId,
  existingQuestion,
  handleOnCreateSuccess,
  handleOnDeleteSuccess,
  limitEditing,
  onClose,
  open,
  profileId,
  type,
}: PropTypes) => {
  const theme = useTheme();

  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [category, setCategory] = useState('');
  const [par, setPar] = useState('');
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [questionType, setQuestionType] = useState<
    'buzzer' | 'multiple-choice'
  >(type || 'buzzer');
  const [revealAnswerAtSecond, setRevealAnswerAtSecond] = useState(
    type === 'buzzer' ? 'Reveal after question' : 4
  );
  const [alternateAnswers, setAlternateAnswers] = useState('');
  const [promptAnswers, setPromptAnswers] = useState('');
  const [multipleChoice1, setMultipleChoice1] = useState('');
  const [multipleChoice2, setMultipleChoice2] = useState('');
  const [multipleChoice3, setMultipleChoice3] = useState('');
  const [multipleChoice4, setMultipleChoice4] = useState('');

  useEffect(() => {
    if (open) {
      setAttemptedSubmit(false);
      setShowDeleteConfirmation(false);
      setCategory('');
      setPar('');
      setQuestion('');
      setAnswer('');
      setQuestionType(type);
      setAlternateAnswers('');
      setPromptAnswers('');
      setRevealAnswerAtSecond(type === 'buzzer' ? 'Reveal after question' : 4);
      setMultipleChoice1('');
      setMultipleChoice2('');
      setMultipleChoice3('');
      setMultipleChoice4('');
    }
    if (existingQuestion) {
      setCategory(existingQuestion.category);
      setPar(existingQuestion.par.toString());
      setQuestion(existingQuestion.question);
      setAnswer(existingQuestion.answer);
      setQuestionType(existingQuestion.type);
      setRevealAnswerAtSecond(
        existingQuestion.revealAnswerAtSecond
          ? existingQuestion.revealAnswerAtSecond
          : type === 'buzzer'
          ? 'Reveal after question'
          : 4
      );
      setAlternateAnswers(
        existingQuestion.alternateAnswers
          ? existingQuestion.alternateAnswers?.join('; ')
          : ''
      );
      setPromptAnswers(
        existingQuestion.promptAnswer
          ? existingQuestion.promptAnswer.join('; ')
          : ''
      );
      setMultipleChoice1(existingQuestion.multipleChoiceAnswers[0] || '');
      setMultipleChoice2(existingQuestion.multipleChoiceAnswers[1] || '');
      setMultipleChoice3(existingQuestion.multipleChoiceAnswers[2] || '');
      setMultipleChoice4(existingQuestion.multipleChoiceAnswers[3] || '');
    }
  }, [open, existingQuestion]);

  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= MAX_CATEGORY_LENGTH) {
      setAttemptedSubmit(false);
      setCategory(event.target.value);
    }
  };

  const handleParChange = (event: SelectChangeEvent) => {
    setAttemptedSubmit(false);
    setPar(event.target.value);
  };

  const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= MAX_QUESTION_LENGTH) {
      setAttemptedSubmit(false);
      setQuestion(event.target.value);
    }
  };

  const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= MAX_ANSWER_LENGTH) {
      setAttemptedSubmit(false);
      setAnswer(event.target.value);
    }
  };

  const handleTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    type: 'buzzer' | 'multiple-choice'
  ) => {
    setQuestionType(type);
  };

  const handleRevealSecondChange = (event: SelectChangeEvent) => {
    setRevealAnswerAtSecond(event.target.value);
  };

  const handleAlternateAnswersChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAttemptedSubmit(false);
    setAlternateAnswers(event.target.value);
  };

  const handlePromptAnswersChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAttemptedSubmit(false);
    setPromptAnswers(event.target.value);
  };

  const handleMultipleChoice1Change = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAttemptedSubmit(false);
    setMultipleChoice1(event.target.value);
  };

  const handleMultipleChoice2Change = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAttemptedSubmit(false);
    setMultipleChoice2(event.target.value);
  };

  const handleMultipleChoice3Change = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAttemptedSubmit(false);
    setMultipleChoice3(event.target.value);
  };

  const handleMultipleChoice4Change = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAttemptedSubmit(false);
    setMultipleChoice4(event.target.value);
  };

  const {
    mutate: createCustomSetQuestion,
    isPending: createCustomSetQuestionIsPending,
  } = useCreateCustomSetQuestion(handleOnCreateSuccess);

  const handleAddQuestionClick = () => {
    if (!category || !par || !question || !answer) {
      setAttemptedSubmit(true);
      return;
    }

    if (questionType === 'multiple-choice') {
      if (!multipleChoice1 || !multipleChoice2) {
        setAttemptedSubmit(true);
        return;
      }
    }

    let bodyObj;

    if (limitEditing) {
      bodyObj = {
        profileId,
        customSetId,
        questionId: existingQuestion?._id,
        alternateAnswers: alternateAnswers
          ? alternateAnswers.split(';').map((alt) => alt.trim())
          : [],
        promptAnswers: promptAnswers
          ? promptAnswers.split(';').map((alt) => alt.trim())
          : [],
      };
    } else {
      bodyObj = {
        profileId,
        customSetId,
        questionId: existingQuestion?._id,
        category,
        par,
        question,
        answer,
        revealAnswerAtSecond,
        type: questionType,
        alternateAnswers: alternateAnswers
          ? alternateAnswers.split(';').map((alt) => alt.trim())
          : [],
        promptAnswers: promptAnswers
          ? promptAnswers.split(';').map((alt) => alt.trim())
          : [],
        multipleChoiceAnswers: [
          multipleChoice1,
          multipleChoice2,
          multipleChoice3,
          multipleChoice4,
        ].filter((choice) => choice),
      };
    }

    createCustomSetQuestion(bodyObj);
  };

  const {
    mutate: deleteCustomSetQuestion,
    isPending: deleteCustomSetQuestionIsPending,
  } = useDeleteCustomSetQuestion(handleOnDeleteSuccess);

  const handleDeleteQuestionClick = () => {
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = () => {
    if (existingQuestion) {
      deleteCustomSetQuestion({
        customSetId,
        questionId: existingQuestion._id,
      });
    }
  };

  return (
    <Dialog open={open} fullScreen TransitionComponent={Transition}>
      {showDeleteConfirmation ? (
        <Stack
          spacing={4}
          height={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Typography textAlign={'center'}>Are you sure?</Typography>
          <Stack direction={'row'} spacing={2}>
            <Button
              variant="outlined"
              onClick={() => setShowDeleteConfirmation(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleConfirmDelete}
              startIcon={
                deleteCustomSetQuestionIsPending ? (
                  <CircularProgress size={16} />
                ) : undefined
              }
            >
              Delete Question
            </Button>
          </Stack>
        </Stack>
      ) : (
        <>
          <DialogTitle>
            {existingQuestion ? 'Edit' : 'New'} Question
          </DialogTitle>
          <DialogContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
            <Stack spacing={2} p={2}>
              <TextField
                label="Category"
                error={attemptedSubmit && !category}
                helperText={
                  attemptedSubmit && !category
                    ? 'Category is required'
                    : limitEditing
                    ? 'Category cannot be edited'
                    : `${MAX_CATEGORY_LENGTH - category.length} characters left`
                }
                variant="outlined"
                value={category}
                onChange={handleCategoryChange}
                disabled={limitEditing}
              />

              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel error={attemptedSubmit && !par}>PAR</InputLabel>
                <Select
                  value={par}
                  label="PAR"
                  onChange={handleParChange}
                  error={attemptedSubmit && !par}
                  disabled={limitEditing}
                >
                  {PAR_OPTIONS.map((limit, i) => (
                    <MenuItem key={i} value={limit}>
                      {limit}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={attemptedSubmit && !par}>
                  {attemptedSubmit && !par
                    ? 'PAR is required'
                    : limitEditing
                    ? 'PAR cannot be edited'
                    : 'A PAR score should be considered very good'}
                </FormHelperText>
              </FormControl>

              <TextField
                label="Question"
                error={attemptedSubmit && !question}
                disabled={limitEditing}
                helperText={
                  attemptedSubmit && !question
                    ? 'Question is required'
                    : limitEditing
                    ? 'Question cannot be edited'
                    : `${MAX_QUESTION_LENGTH - question.length} characters left`
                }
                variant="outlined"
                multiline
                value={question}
                onChange={handleQuestionChange}
              />

              <TextField
                label="Answer"
                error={attemptedSubmit && !answer}
                disabled={limitEditing}
                helperText={
                  attemptedSubmit && !answer
                    ? 'Answer is required'
                    : limitEditing
                    ? 'Answer cannot be edited'
                    : `${MAX_ANSWER_LENGTH - answer.length} characters left`
                }
                variant="outlined"
                value={answer}
                onChange={handleAnswerChange}
              />

              <ToggleButtonGroup
                color="primary"
                value={questionType}
                exclusive
                onChange={handleTypeChange}
                fullWidth
                size="small"
                disabled={limitEditing}
              >
                <ToggleButton value="buzzer">Buzzer</ToggleButton>
                <ToggleButton value="multiple-choice">
                  Multiple Choice
                </ToggleButton>
              </ToggleButtonGroup>

              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel>
                  {questionType === 'buzzer'
                    ? 'Answer box reveal timing'
                    : 'Multiple choice options reveal timing'}
                </InputLabel>
                <Select
                  value={revealAnswerAtSecond}
                  label={
                    questionType === 'buzzer'
                      ? 'Answer box reveal timing'
                      : 'Multiple choice options reveal timing'
                  }
                  onChange={handleRevealSecondChange}
                  disabled={limitEditing}
                >
                  {BUZZER_ANSWER_REVEAL_OPTIONS.map((option, i) => (
                    <MenuItem key={i} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {questionType === 'buzzer' ? (
                <>
                  <TextField
                    label="Alternate Answers"
                    helperText={`Each answer should be separated by a semicolon`}
                    variant="outlined"
                    multiline
                    value={alternateAnswers}
                    onChange={handleAlternateAnswersChange}
                  />

                  <TextField
                    label="Prompt for more specific answer"
                    helperText={`Each prompt should be separated by a semicolon`}
                    variant="outlined"
                    multiline
                    value={promptAnswers}
                    onChange={handlePromptAnswersChange}
                  />
                </>
              ) : questionType === 'multiple-choice' ? (
                <>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="mc1">Multiple Choice 1</InputLabel>
                    <OutlinedInput
                      id="mc1"
                      value={multipleChoice1}
                      onChange={handleMultipleChoice1Change}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() => setMultipleChoice1(answer)}
                          >
                            <FontDownloadIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Multiple Choice 1"
                    />
                    <FormHelperText>
                      {attemptedSubmit && !multipleChoice1
                        ? 'Multiple Choice 1 is required'
                        : limitEditing
                        ? 'Multiple Choice 1 cannot be edited'
                        : `${
                            MAX_ANSWER_LENGTH - multipleChoice1.length
                          } characters left`}
                    </FormHelperText>
                  </FormControl>

                  <FormControl variant="outlined">
                    <InputLabel htmlFor="mc2">Multiple Choice 2</InputLabel>
                    <OutlinedInput
                      id="mc2"
                      value={multipleChoice2}
                      onChange={handleMultipleChoice2Change}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() => setMultipleChoice2(answer)}
                          >
                            <FontDownloadIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Multiple Choice 2"
                    />
                    <FormHelperText>
                      {attemptedSubmit && !multipleChoice2
                        ? 'Multiple Choice 2 is required'
                        : limitEditing
                        ? 'Multiple Choice 2 cannot be edited'
                        : `${
                            MAX_ANSWER_LENGTH - multipleChoice2.length
                          } characters left`}
                    </FormHelperText>
                  </FormControl>

                  <FormControl variant="outlined">
                    <InputLabel htmlFor="mc3">Multiple Choice 3</InputLabel>
                    <OutlinedInput
                      id="mc3"
                      value={multipleChoice3}
                      onChange={handleMultipleChoice3Change}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() => setMultipleChoice3(answer)}
                          >
                            <FontDownloadIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Multiple Choice 3"
                    />
                    <FormHelperText>
                      {attemptedSubmit && !multipleChoice3
                        ? 'Multiple Choice 3 is required'
                        : limitEditing
                        ? 'Multiple Choice 3 cannot be edited'
                        : `${
                            MAX_ANSWER_LENGTH - multipleChoice3.length
                          } characters left`}
                    </FormHelperText>
                  </FormControl>

                  <FormControl variant="outlined">
                    <InputLabel htmlFor="mc4">Multiple Choice 4</InputLabel>
                    <OutlinedInput
                      id="mc4"
                      value={multipleChoice4}
                      onChange={handleMultipleChoice4Change}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() => setMultipleChoice4(answer)}
                          >
                            <FontDownloadIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Multiple Choice 4"
                    />
                    <FormHelperText>
                      {attemptedSubmit && !multipleChoice4
                        ? 'Multiple Choice 4 is required'
                        : limitEditing
                        ? 'Multiple Choice 4 cannot be edited'
                        : `${
                            MAX_ANSWER_LENGTH - multipleChoice4.length
                          } characters left`}
                    </FormHelperText>
                  </FormControl>
                </>
              ) : null}
            </Stack>
          </DialogContent>

          <DialogActions>
            <Stack
              direction={'row'}
              justifyContent={
                !limitEditing && existingQuestion ? 'space-between' : 'right'
              }
              width={'100%'}
            >
              {!limitEditing && existingQuestion && (
                <Button
                  variant="outlined"
                  color="error"
                  disabled={
                    createCustomSetQuestionIsPending ||
                    deleteCustomSetQuestionIsPending
                  }
                  startIcon={
                    deleteCustomSetQuestionIsPending ? (
                      <CircularProgress size={16} />
                    ) : undefined
                  }
                  onClick={handleDeleteQuestionClick}
                >
                  Delete
                </Button>
              )}

              <Stack direction={'row'} spacing={2}>
                <Button onClick={onClose} fullWidth>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleAddQuestionClick}
                  startIcon={
                    createCustomSetQuestionIsPending ? (
                      <CircularProgress size={16} />
                    ) : !existingQuestion ? (
                      <Add />
                    ) : undefined
                  }
                  disabled={
                    createCustomSetQuestionIsPending ||
                    deleteCustomSetQuestionIsPending
                  }
                  fullWidth
                >
                  {existingQuestion ? 'Update' : 'Add'}
                </Button>
              </Stack>
            </Stack>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default EditQuestionDialog;
