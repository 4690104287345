import {
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Table,
  Typography,
  TableBody,
  Stack,
  Avatar,
} from '@mui/material';
import React from 'react';
import { getAvatarSrc } from '../../../../../api/cloudinary';
import { Player } from '../../../types';

type PropTypes = {
  leaderboard: Player[];
  loading: boolean;
  profileId?: string;
};

export const LeaderboardScores = ({
  leaderboard,
  loading,
  profileId,
}: PropTypes) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle2" width="1%">
                Rank
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">Player</Typography>
            </TableCell>
            <TableCell>
              <Typography textAlign={'center'} variant="subtitle2">
                Score
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={4}>
                <Typography variant="subtitle2" textAlign={'center'}>
                  Loading players...
                </Typography>
              </TableCell>
            </TableRow>
          ) : !leaderboard?.length ? (
            <TableRow>
              <TableCell colSpan={3}>
                <Typography
                  variant="subtitle2"
                  textAlign={'center'}
                  color="warning.light"
                >
                  No one has played yet
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            leaderboard?.map((p, i) => (
              <TableRow
                key={i}
                sx={{
                  bgcolor: p.profileId === profileId ? '#475d4b' : null,
                }}
              >
                <TableCell width={'5%'}>
                  <Typography variant="subtitle2">{p.rank}</Typography>
                </TableCell>
                <TableCell>
                  <Stack direction={'row'} spacing={1}>
                    <Avatar
                      src={getAvatarSrc(p.avatarPublicId, p.avatarUrl, 50, 50)}
                      variant="rounded"
                      sx={{
                        height: 20,
                        width: 20,
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      sx={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        width: '150px',
                      }}
                    >
                      {p.username}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle2"
                    fontWeight={600}
                    textAlign={'center'}
                    sx={{
                      color: p.parScore < 0 ? 'success.main' : null,
                    }}
                  >
                    {p.parScore === null
                      ? '--'
                      : `${p.parScore > 0 ? '+' : ''}${p.parScore}`}
                  </Typography>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
