import { Container } from '@mui/material';
import React from 'react';

type Props = {
  children: React.ReactNode;
  hasHeader?: boolean;
  px?: number;
};

const BasicLayout = ({ children, hasHeader, px }: Props) => {
  return (
    <Container
      maxWidth={'sm'}
      sx={{
        pt: hasHeader ? '60px' : 0,
        pb: hasHeader ? '60px' : 0,
        px: px || 0,
      }}
    >
      {children}
    </Container>
  );
};

export default BasicLayout;
