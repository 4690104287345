import {
  Stack,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import React, { useState } from 'react';
import Scorecard from './Scorecard/Scorecard';
import PeopleIcon from '@mui/icons-material/People';
import ListAltIcon from '@mui/icons-material/ListAlt';

import Leaderboard from './Leaderboard/Leaderboard';
import { CustomSetLeaderboard } from './types';
import { Player, Question } from '../../types';

type Props = {
  customSetLeaderboard: CustomSetLeaderboard;
  customSetLeaderboardIsPending: boolean;
  isGuest: boolean;
  players: Record<string, Player>;
  profileId: string;
  questions: Question[];
  title: string;
};

const Results = ({
  customSetLeaderboard,
  customSetLeaderboardIsPending,
  isGuest,
  players,
  profileId,
  questions,
  title,
}: Props) => {
  const [toggle, setToggle] = useState<'scorecard' | 'leaderboard'>(
    'scorecard'
  );

  return (
    <Stack sx={{ px: 2 }}>
      <Stack>
        <Typography textAlign={'center'}>"{title}"</Typography>
        <ToggleButtonGroup
          value={toggle}
          exclusive
          onChange={(_, value) => setToggle(value)}
          sx={{
            height: 30,
            mt: 1,
            mb: 1,
          }}
          fullWidth
        >
          <ToggleButton value="scorecard">
            <ListAltIcon sx={{ pr: 1 }} />
            Scorecard
          </ToggleButton>
          <ToggleButton value="leaderboard">
            <PeopleIcon sx={{ pr: 1 }} />
            Leaderboard
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      {toggle === 'scorecard' ? (
        <Scorecard
          players={players}
          profileId={profileId}
          questions={questions}
        />
      ) : toggle === 'leaderboard' ? (
        <Leaderboard
          isGuest={isGuest}
          leaderboard={customSetLeaderboard?.leaderboard}
          loading={customSetLeaderboardIsPending}
          medianScores={customSetLeaderboard?.medianScores}
          players={players}
          profileId={profileId}
          questions={questions}
          topScores={customSetLeaderboard?.topScores}
        />
      ) : null}
    </Stack>
  );
};

export default Results;
