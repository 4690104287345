import { useQuery } from '@tanstack/react-query';
import { getCustomSetLeaderboard } from '../../../api/apiServices';

const useGetLeaderboard = (customSetId: string, shouldFetch: boolean) => {
  const { data, isPending, refetch } = useQuery({
    queryKey: ['leaderboard', { customSetId }],
    queryFn: getCustomSetLeaderboard,
    enabled: !!customSetId && shouldFetch,
  });

  return {
    refetch,
    data,
    isPending,
  };
};

export default useGetLeaderboard;
