import { useMutation } from '@tanstack/react-query';
import { postArchiveCustomSet } from '../../../api/apiServices';

const useUpdateArchiveSet = (handleArchiveSuccess: () => void) => {
  const { mutate } = useMutation({
    mutationFn: postArchiveCustomSet,
    onSuccess: handleArchiveSuccess,
  });

  return {
    mutate,
  };
};

export default useUpdateArchiveSet;
