import { useMutation } from '@tanstack/react-query';
import { postUserNotificationsSeen } from '../../../api/apiServices';

const useUpdateUserNotificationsSeen = () => {
  const { mutate, isPending } = useMutation({
    mutationFn: postUserNotificationsSeen,
  });

  return {
    mutate,
    isPending,
  };
};

export default useUpdateUserNotificationsSeen;
