import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';

type Props = {
  confirmAction: () => void;
  customSetId: string;
  isPending: boolean;
  onClose: () => void;
  open: boolean;
  published: boolean;
  title: string;
};

const PublishConfirmDialog = ({
  confirmAction,
  customSetId,
  isPending,
  onClose,
  open,
  published,
  title,
}: Props) => {
  const handleShare = async () => {
    const link = `/game/${customSetId}`;
    if (navigator.share) {
      try {
        await navigator.share({
          title: `PAR: ${title}`,
          text: '',
          url: link,
        });
      } catch (err) {}
    }

    // or to just copy text
    // await navigator.clipboard.writeText(link);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogContent>
        {published ? (
          <Stack spacing={4}>
            <Typography variant="body2">
              Your set is now published! Share it with your friends and family.
            </Typography>

            <Button
              variant="outlined"
              onClick={handleShare}
              color="secondary"
              startIcon={<ShareIcon />}
            >
              Share
            </Button>
          </Stack>
        ) : (
          <Typography variant="body2">
            Are you sure you want to publish this set? Once published, you
            cannot make major edits and it will be available for anyone to play.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{published ? 'Close' : 'Cancel'}</Button>
        {!published && (
          <Button
            variant="contained"
            onClick={confirmAction}
            startIcon={isPending && <CircularProgress size={16} />}
            color="success"
            disabled={isPending}
          >
            Publish!
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PublishConfirmDialog;
