import { Circle } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Card,
  CardActionArea,
  Stack,
  Avatar,
  Typography,
  Rating,
  Divider,
  IconButton,
  Chip,
} from '@mui/material';
import React from 'react';
import { getAvatarSrc } from '../../api/cloudinary';
import { SquareChip } from '../../styled-components/SquareChip';
import { timeSince } from '../../utils/date-time';
import { CustomSetListItem } from '../../types/custom-sets';
import { useNavigate } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';
import ShieldIcon from '@mui/icons-material/Shield';
import { getTagColor } from './utilts';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

type Props = {
  customSet: CustomSetListItem;
  customSetPlayed?: boolean;
  handleCardClick: (customSetId: string) => void;
  profileId: string;
};

const CustomSetCard = ({
  customSet,
  customSetPlayed,
  handleCardClick,
  profileId,
}: Props) => {
  const navigate = useNavigate();

  const handleProfileClick = (e: any, profileId: string) => {
    e.stopPropagation();
    navigate(`/profile/${profileId}`);
  };

  const handleShare = async (e: any) => {
    e.stopPropagation();
    if (!customSet) return;
    const link = `/game/${customSet.customSetId}`;
    if (navigator.share) {
      try {
        await navigator.share({
          title: `PAR: ${customSet.title}`,
          text: '',
          url: link,
        });
      } catch (err) {}
    }
  };

  return (
    <Box>
      <Card sx={{ py: 1, bgcolor: 'transparent' }}>
        <CardActionArea onClick={() => handleCardClick(customSet.customSetId)}>
          <Stack px={2} spacing={1}>
            <Stack
              direction={'row'}
              spacing={1}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Avatar
                  onClick={(e) =>
                    handleProfileClick(e, customSet.creator.profileId)
                  }
                  src={getAvatarSrc(
                    customSet.creator.avatarPublicId,
                    customSet.creator.avatarUrl,
                    40,
                    40
                  )}
                  alt={customSet.creator.username}
                  sx={{ width: 24, height: 24 }}
                />
                <Typography
                  variant="body2"
                  onClick={(e) =>
                    handleProfileClick(e, customSet.creator.profileId)
                  }
                >
                  {customSet.creator.username}
                </Typography>
                <Circle sx={{ width: 4, height: 4 }} />
                <Typography variant="body2">
                  {timeSince(new Date(customSet.publishedAt), true)}
                </Typography>
              </Stack>

              {customSetPlayed ? (
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <Typography variant="body2" color="success.main">
                    Played
                  </Typography>
                  <CheckIcon color="success" fontSize="small" />
                </Stack>
              ) : profileId === customSet.creator.profileId ? (
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <Typography variant="body2" color="secondary">
                    Yours
                  </Typography>
                  <ShieldIcon color="secondary" fontSize="small" />
                </Stack>
              ) : null}
            </Stack>
            <Typography>{customSet.title}</Typography>

            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              {customSet.tags?.length && customSet.tags[0] ? (
                <Chip
                  label={customSet.tags[0]}
                  size="small"
                  variant="outlined"
                  sx={{
                    borderColor: getTagColor(customSet.tags[0]),
                    color: getTagColor(customSet.tags[0]),
                  }}
                />
              ) : null}

              <SquareChip
                variant="filled"
                label={customSet.configs.type === 'buzzer' ? 'Buzzer' : 'MC'}
                size="small"
              />

              <SquareChip
                variant="filled"
                label={`${customSet.questionsCount} Qs`}
                size="small"
              />
            </Stack>

            <Stack
              direction={'row'}
              spacing={1}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              {customSet.rating ? (
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <Typography variant="body2">
                    {customSet.rating.toFixed(1)}
                  </Typography>
                  <Rating
                    name="read-only"
                    value={customSet.rating}
                    readOnly
                    size="small"
                  />
                  <Typography variant="body2">
                    ({customSet.ratingsCount})
                  </Typography>
                </Stack>
              ) : (
                <Typography variant="body2" color="grey">
                  No ratings
                </Typography>
              )}

              <Typography variant="body2">
                {customSet.totalPlays || 0} plays
              </Typography>

              <IconButton onClick={(e) => handleShare(e)}>
                <ReplyIcon
                  sx={{
                    transform: 'scaleX(-1)',
                  }}
                />
              </IconButton>
            </Stack>

            {customSet.isPrivate && (
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <VisibilityOffIcon />
                <Typography variant="caption">
                  Private sets only visible to you
                </Typography>
              </Stack>
            )}
          </Stack>
        </CardActionArea>
      </Card>

      <Divider />
    </Box>
  );
};

export default CustomSetCard;
