import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import GooglePlaces from './GooglePlaces';
import useFetchPlacesGeocode from './useFetchPlacesGeocode';
import { Profile } from '../../../types/types';
import { GooglePlacesPrediction, UpdateProfileBody } from '../types';
import useUpdateProfile from '../hooks/useUpdateProfile';

type PropTypes = {
  close: () => void;
  handleSaveSuccess: () => void;
  open: boolean;
  profile: Profile;
};

export const EditUsernameDialog = ({
  close,
  handleSaveSuccess,
  open,
  profile,
}: PropTypes) => {
  const [newUsername, setNewUsername] = useState(profile.username);
  const [newFullName, setNewFullName] = useState<string>(profile.fullName);
  const [pronoun, setPronoun] = useState<string>(profile.genderPronoun);
  const [placeId, setPlaceId] = useState<string>();
  const [userLocation, setUserLocation] = useState<{
    location: string;
    locationLatitude: number;
    locationLongitude: number;
    locationPlaceId: string;
  }>();

  const { geocode } = useFetchPlacesGeocode(placeId);

  const { mutate: updateProfile, isPending } =
    useUpdateProfile(handleSaveSuccess);

  useEffect(() => {
    if (geocode) {
      setUserLocation((prev) => ({
        ...prev,
        locationLatitude: geocode.geometry.location.lat,
        locationLongitude: geocode.geometry.location.lng,
      }));
    }
  }, [geocode, placeId]);

  const handlePronounChange = (event: SelectChangeEvent) => {
    setPronoun(event.target.value);
  };

  const handlePredictionSelect = (prediction: GooglePlacesPrediction) => {
    setUserLocation((prev) => ({
      ...prev,
      location: prediction.description,
      locationPlaceId: prediction.place_id,
    }));
    setPlaceId(prediction.place_id);
  };

  const handleSaveProfileClick = () => {
    const updatedProfile: UpdateProfileBody = {
      username: newUsername,
      fullName: newFullName,
      genderPronoun: pronoun,
      location: userLocation?.location,
      locationPlaceId: userLocation?.locationPlaceId,
      locationLatitude: userLocation?.locationLatitude,
      locationLongitude: userLocation?.locationLongitude,
    };
    updateProfile(updatedProfile);
  };

  return (
    <Dialog open={open} onClose={close} fullScreen>
      <DialogTitle>
        <Typography letterSpacing={2} textAlign={'center'}>
          EDIT PROFILE
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3} padding={2}>
          <TextField
            error={newUsername === ''}
            value={newUsername}
            autoFocus
            label="Username"
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNewUsername(event.target.value);
            }}
          />

          <TextField
            value={newFullName}
            autoFocus
            label="Full Name (Optional)"
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNewFullName(event.target.value);
            }}
          />

          {/* <GooglePlaces
            handlePredictionSelect={handlePredictionSelect}
            value={profile.location}
          /> */}

          <FormControl fullWidth>
            <InputLabel>Pronoun (Optional)</InputLabel>
            <Select
              variant="outlined"
              value={pronoun}
              onChange={handlePronounChange}
              label="Pronoun (Optional)"
            >
              <MenuItem value={'He/Him'}>He/Him</MenuItem>
              <MenuItem value={'She/Her'}>She/Her</MenuItem>
              <MenuItem value={'They/Their'}>They/Them</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} fullWidth>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSaveProfileClick}
          fullWidth
          disabled={isPending}
          startIcon={isPending ? <CircularProgress size={20} /> : null}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
