import React from 'react';
import { Box } from '@mui/material';
import { IncorrectAnswer } from './IncorrectAnswer';
import { CorrectAnswer } from './CorrectAnswer';
import { Player } from '../types';

type Props = {
  inputValue: string;
  player: Player;
};

export const RoundEndFeedback = ({ inputValue, player }: Props) => {
  return (
    <Box position={'relative'} bottom={31} left={0} height={31}>
      {player.correctAnswer ? (
        <CorrectAnswer
          inputValue={inputValue}
          playerCurrentScore={player?.currentScore}
          playerFuzzyScore={player?.fuzzyScore}
        />
      ) : (
        <IncorrectAnswer
          currentScore={player.currentScore}
          inputValue={inputValue}
        />
      )}
    </Box>
  );
};
