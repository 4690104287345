import { Avatar, Box, Divider, Stack, Typography, colors } from '@mui/material';
import React, { useEffect } from 'react';
import BasicLayout from '../BasicLayout';
import { useNavigate } from 'react-router-dom';
import { Header } from '../header';
import { Profile } from '../../types/types';
import BottomNav from '../BottomNav/BottomNav';
import useGetUserNotifications from './hooks/useGetUserNotifications';
import { getFormattedNotification } from './utils';
import { getAvatarSrc } from '../../api/cloudinary';
import useUpdateUserNotificationsSeen from './hooks/useUpdateUserNotificationsSeen';
import { UserNotification } from './types';
import ActivityCard from './ActivityCard';

type Props = {
  clearNotifications: () => void;
  clearProfile: () => void;
  profile: Profile;
};

const Activity = ({ clearNotifications, clearProfile, profile }: Props) => {
  const navigate = useNavigate();

  const { data: userNotifications } = useGetUserNotifications();
  console.log('userNotifications', userNotifications);

  const { mutate: updateUserNotificationsSeen } =
    useUpdateUserNotificationsSeen();

  useEffect(() => {
    clearNotifications();
    updateUserNotificationsSeen();
  }, []);

  return (
    <BasicLayout hasHeader>
      <Header clearProfile={clearProfile} profile={profile} />

      <Stack p={2}>
        <Typography variant="h5">Activity</Typography>

        <Stack pt={2}>
          {userNotifications?.length ? (
            userNotifications
              .sort((a, b) => b.createdAt - a.createdAt)
              .map((notif, i) => <ActivityCard key={i} notification={notif} />)
          ) : (
            <Typography variant="caption">No activity</Typography>
          )}
        </Stack>
      </Stack>

      <BottomNav bottomNavValue={2} />
    </BasicLayout>
  );
};

export default Activity;
