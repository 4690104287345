import { useMutation } from '@tanstack/react-query';
import { postLogout } from '../../../api/apiServices';

export const useLogout = (handleOnSuccess: () => void) => {
  const { mutate } = useMutation({
    mutationFn: postLogout,
    onSuccess: handleOnSuccess,
  });

  return { mutate };
};
