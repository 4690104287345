import { Circle } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Card,
  CardActionArea,
  Stack,
  Avatar,
  Typography,
  Divider,
} from '@mui/material';
import React from 'react';
import { getAvatarSrc } from '../../api/cloudinary';
import { SquareChip } from '../../styled-components/SquareChip';
import { timeSince } from '../../utils/date-time';
import { CustomSet } from '../../types/custom-sets';
import { Profile } from '../../types/types';

type Props = {
  customSet: CustomSet;
  customSetPlayed?: boolean;
  handleCardClick: (customSetId: string) => void;
  profile: Profile;
};

const CustomSetDraftCard = ({
  customSet,
  customSetPlayed,
  handleCardClick,
  profile,
}: Props) => {
  return (
    <Box>
      <Card sx={{ py: 1, bgcolor: 'transparent' }}>
        <CardActionArea onClick={() => handleCardClick(customSet.customSetId)}>
          <Stack px={2} spacing={1}>
            <Stack
              direction={'row'}
              spacing={1}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Avatar
                  src={getAvatarSrc(
                    profile?.avatarPublicId,
                    profile?.avatarUrl,
                    40,
                    40
                  )}
                  alt={profile?.username}
                  sx={{ width: 24, height: 24 }}
                />
                <Typography variant="body2">{profile?.username}</Typography>
                <Circle sx={{ width: 4, height: 4 }} />
                <Typography variant="body2">
                  {timeSince(new Date(customSet.createdAt), true)}
                </Typography>
              </Stack>

              {customSet.archived && (
                <Typography variant="body2" color="error.main">
                  Archived
                </Typography>
              )}

              {customSetPlayed && (
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <Typography variant="body2" color="success.main">
                    Played
                  </Typography>
                  <CheckIcon color="success" fontSize="small" />
                </Stack>
              )}
            </Stack>
            <Typography>{customSet.title}</Typography>

            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <SquareChip
                variant="filled"
                label={customSet.configs.type === 'buzzer' ? 'Buzzer' : 'MC'}
                size="small"
              />

              <SquareChip
                variant="filled"
                label={`${customSet.questions?.length || 0} Qs`}
                size="small"
              />
            </Stack>
          </Stack>
        </CardActionArea>
      </Card>

      <Divider />
    </Box>
  );
};

export default CustomSetDraftCard;
