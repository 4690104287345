import { useMutation } from '@tanstack/react-query';
import { postCustomGameStarted } from '../../../api/apiServices';

const useUploadGameStarted = () => {
  const { mutate } = useMutation({
    mutationFn: postCustomGameStarted,
  });

  return {
    mutate,
  };
};

export default useUploadGameStarted;
