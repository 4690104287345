import { Alert, Snackbar, Stack } from '@mui/material';
import React, { useState } from 'react';
import BasicLayout from '../BasicLayout';
import { Profile } from '../../types/types';
import BottomNav from '../BottomNav/BottomNav';
import { Header } from '../header';
import SetLists from './SetLists';
import EditConfigs from './CreateFlow/EditConfigs';
import useGetCustomSetsDrafts from './hooks/useGetCustomSetsDrafts';
import EditQuestions from './CreateFlow/EditQuestions';
import { CustomSet } from '../../types/custom-sets';
import useGetCustomSetsPublished from './hooks/useGetCustomSetsPublished';
import CreateQuestions from './CreateFlow/CreateQuestions';

type Props = {
  clearProfile: () => void;
  profile: Profile;
  unseenNotifications: number;
};

const CreateSet = ({ clearProfile, profile, unseenNotifications }: Props) => {
  const [step, setStep] = useState<
    'list' | 'game-config' | 'create-questions' | 'edit-questions'
  >('list');
  const [selectedDraft, setSelectedDraft] = useState<CustomSet>();
  const [inPlayTest, setInPlayTest] = useState(false);
  const [showToast, setShowToast] = useState('');

  const { data: customSetsDrafts, refetch: customSetsDraftsRefetch } =
    useGetCustomSetsDrafts(profile?._id);

  const { data: customSetsPublished, refetch: customSetsPublishedRefetch } =
    useGetCustomSetsPublished(profile?._id);

  const handleCreateNewSetClick = () => {
    setSelectedDraft(undefined);
    setStep('game-config');
  };

  const handleCreateSuccess = (response: any) => {
    customSetsDraftsRefetch();
    customSetsPublishedRefetch();
    if (selectedDraft) {
      setStep('edit-questions');
      return;
    }

    setSelectedDraft(response.data as CustomSet);
    setStep('create-questions');
  };

  const handleDraftCardClick = (customSetId: string) => {
    const draft = customSetsDrafts.find(
      (draft) => draft.customSetId === customSetId
    );
    if (draft) {
      setSelectedDraft(draft);
      setStep('create-questions');
    }
  };

  const handlePublishedCardClick = (customSetId: string) => {
    console.log('customSetId', customSetId);
    const published = customSetsPublished.find(
      (published) => published.customSetId === customSetId
    );
    if (published) {
      setSelectedDraft(published);
      setStep('edit-questions');
    }
  };

  const handleOnSetDeleteSuccess = () => {
    customSetsDraftsRefetch();
    setStep('list');
    setShowToast('Set Deleted');
  };

  const handleOnSetPublishSuccess = () => {
    customSetsDraftsRefetch();
    customSetsPublishedRefetch();
    setStep('list');
    setShowToast('Set Published');
  };

  const handleOnQuestionCreateSuccess = () => {
    setShowToast('Question updated successfully');
  };

  const handleOnQuestionDeleteSuccess = () => {
    setShowToast('Question deleted successfully');
  };

  const handleOnSetArchiveSuccess = () => {
    customSetsPublishedRefetch();
    setShowToast('Set Archived');
  };

  const handleGameConfigBackClick = () => {
    if (selectedDraft) {
      setStep('edit-questions');
      return;
    }
    setStep('list');
  };

  return (
    <>
      <BasicLayout hasHeader={!inPlayTest} px={2}>
        {!inPlayTest && (
          <Header clearProfile={clearProfile} profile={profile} />
        )}

        <Stack py={1}>
          {step === 'list' && (
            <SetLists
              customSetsDrafts={customSetsDrafts}
              customSetsPublished={customSetsPublished}
              handleDraftCardClick={handleDraftCardClick}
              handlePublishedCardClick={handlePublishedCardClick}
              handleCreateNewSetClick={handleCreateNewSetClick}
              profile={profile}
            />
          )}

          {step === 'game-config' && (
            <EditConfigs
              customSet={selectedDraft}
              handleBackClick={handleGameConfigBackClick}
              handleCreateSuccess={handleCreateSuccess}
              profileId={profile._id}
            />
          )}

          {step === 'create-questions' && (
            <CreateQuestions
              customSet={selectedDraft}
              handleBackClick={() => setStep('list')}
              handleGameConfigsClick={() => setStep('game-config')}
              handleOnQuestionCreateSuccess={handleOnQuestionCreateSuccess}
              handleOnSetDeleteSuccess={handleOnSetDeleteSuccess}
              handleOnSetPublishSuccess={handleOnSetPublishSuccess}
              handleOnQuestionDeleteSuccess={handleOnQuestionDeleteSuccess}
              onPlayTestEnd={() => setInPlayTest(false)}
              onPlayTestStart={() => setInPlayTest(true)}
              profile={profile}
            />
          )}

          {step === 'edit-questions' && (
            <EditQuestions
              customSet={selectedDraft}
              handleBackClick={() => setStep('list')}
              handleGameConfigsClick={() => setStep('game-config')}
              handleOnQuestionCreateSuccess={handleOnQuestionCreateSuccess}
              handleOnSetArchiveSuccess={handleOnSetArchiveSuccess}
              handleOnQuestionDeleteSuccess={handleOnQuestionDeleteSuccess}
              onPlayTestEnd={() => setInPlayTest(false)}
              onPlayTestStart={() => setInPlayTest(true)}
              profile={profile}
            />
          )}
        </Stack>

        {!inPlayTest && (
          <BottomNav
            bottomNavValue={1}
            unseenNotifications={unseenNotifications}
          />
        )}
      </BasicLayout>

      <Snackbar
        open={!!showToast}
        onClose={() => setShowToast('')}
        autoHideDuration={2000}
        sx={{
          zIndex: 9999,
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: '100%', color: 'white' }}
        >
          {showToast}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateSet;
