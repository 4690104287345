import { useQuery } from '@tanstack/react-query';
import { getProfile } from '../../api/apiServices';

const useFetchProfile = () => {
  const { data, isPending, refetch } = useQuery({
    queryKey: ['profile'],
    queryFn: getProfile,
    retry: false,
  });

  return {
    isPending,
    data,
    refetch,
  };
};

export default useFetchProfile;
