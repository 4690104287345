import React from 'react';
import { Box, Stack, Typography, styled, useTheme } from '@mui/material';
import { formattedScore } from '../../scorecard/ScorecardTable';
import { DisplayScore } from './DisplayScore';
import { DisplayFuzzy } from '../fuzzy/DisplayFuzzy';
import { ShinyTypography, getAveName, getAveShineColor } from './constants';

const AnswerText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: 18,
  color: theme.palette.success.main,
}));

type Props = {
  inputValue: string;
  playerCurrentScore: number;
  playerFuzzyScore: number;
};

export const CorrectAnswer = ({
  inputValue,
  playerCurrentScore,
  playerFuzzyScore,
}: Props) => {
  const theme = useTheme();

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      spacing={2}
    >
      <Box sx={{ rotate: '-11deg' }}>
        <DisplayFuzzy
          correctAnswer={true}
          customFontSize={10}
          fuzzyPenalty={null}
          fuzzyScore={playerFuzzyScore}
        />
      </Box>
      <AnswerText>{inputValue}</AnswerText>
      {playerCurrentScore < 2 ? (
        <ShinyTypography
          fontSize={12}
          textAlign={'center'}
          color={getAveShineColor(
            getAveName(playerCurrentScore || 0),
            theme.palette.mode
          )}
        >
          {`${getAveName(playerCurrentScore).toUpperCase()} (${formattedScore(
            playerCurrentScore
          )})`}
        </ShinyTypography>
      ) : (
        <DisplayScore score={playerCurrentScore} type="success" />
      )}
    </Stack>
  );
};
