// import { useMutation } from '@tanstack/react-query';
// import { useGoogleLogin as useGoogleLoginOauth } from '@react-oauth/google';
// import { postGoogleLogin } from '../../../api/apiServices';

// export const useGoogleLogin = () => {
//   const mutation = useMutation({
//     mutationFn: postGoogleLogin,
//   });

//   const googleLogin = useGoogleLoginOauth({
//     onSuccess: async (codeResponse: any) => {
//       mutation.mutate(codeResponse);
//     },
//     flow: 'auth-code',
//   });

//   return {
//     data: mutation.data,
//     error: mutation.error,
//     isLoading: mutation.isPending,
//     googleLogin,
//   };
// };

import { Methods } from './constants';
import { useEffect } from 'react';
import { useGoogleLogin as useGoogleLoginOauth } from '@react-oauth/google';
import { useApi } from '../../../api/useApi';

export const useGoogleLogin = (handleOnSuccess: () => void) => {
  const { data, error, loading, sendRequest } = useApi();

  useEffect(() => {
    if (data && !error) {
      handleOnSuccess();
    }
  }, [data, error]);

  const googleLogin = useGoogleLoginOauth({
    onSuccess: async (codeResponse: any) => {
      sendRequest({
        url: '/authenticate/google-sso',
        method: Methods.POST,
        body: codeResponse,
      });
    },
    flow: 'auth-code',
  });

  return { data, error, loading, googleLogin };
};
