import {
  Paper,
  BottomNavigation,
  BottomNavigationAction,
  Badge,
} from '@mui/material';
import React, { useState } from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import HomeIcon from '@mui/icons-material/Home';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom';
import { UserNotification } from '../Activity/types';

type Props = {
  bottomNavValue: number;
  unseenNotifications?: number;
};

const BottomNav = ({ bottomNavValue, unseenNotifications }: Props) => {
  const navigate = useNavigate();

  const handleBottomNavChange = (event, newValue) => {
    if (newValue === 0) {
      navigate('/');
    } else if (newValue === 1) {
      navigate('/create');
    } else if (newValue === 2) {
      navigate('/activity');
    }
  };

  return (
    <Paper
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={bottomNavValue}
        onChange={handleBottomNavChange}
      >
        <BottomNavigationAction label="Home" icon={<HomeIcon />} />
        <BottomNavigationAction label="Create" icon={<AddCircleIcon />} />
        <BottomNavigationAction
          label="Activity"
          icon={
            <Badge badgeContent={unseenNotifications} color="error">
              <NotificationsIcon />
            </Badge>
          }
        />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNav;
