import { IconButton, Stack, Typography, colors } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BasicLayout from '../BasicLayout';
import useGetCustomSets from './hooks/useGetCustomSets';
import { useNavigate } from 'react-router-dom';
import { Header } from '../header';
import { Profile } from '../../types/types';
import BottomNav from '../BottomNav/BottomNav';
import CustomSetCard from './CustomSetCard';
import { isChrome, isFirefox } from 'react-device-detect';
import { UserNotification } from '../Activity/types';
import CloseIcon from '@mui/icons-material/Close';
import {
  getParsedLocalStorageItem,
  setStringifiedLocalStorageItem,
} from '../../local-storage';

type Props = {
  clearProfile: () => void;
  profile: Profile;
  unseenNotifications: number;
};

const Main = ({ clearProfile, profile, unseenNotifications }: Props) => {
  const navigate = useNavigate();

  const { data: customSetsData } = useGetCustomSets();

  const [showBrowserWarning, setShowBrowserWarning] = useState(false);

  useEffect(() => {
    const dismissBrowserWarning = getParsedLocalStorageItem(
      'dismissBrowserWarning'
    );
    if (dismissBrowserWarning) {
      setShowBrowserWarning(false);
    } else {
      setShowBrowserWarning(true);
    }
  }, []);

  const handleCardClick = (customSetId: string) => {
    navigate(`/game/${customSetId}`);
  };

  const customSetPlayed = (customSetId: string) => {
    return profile?.customSetsPlayed
      ?.map((set) => set.customSetId)
      .includes(customSetId);
  };

  const dismissBrowserWarning = () => {
    setStringifiedLocalStorageItem('dismissBrowserWarning', 'true');
    setShowBrowserWarning(false);
  };

  return (
    <BasicLayout hasHeader>
      <Header clearProfile={clearProfile} profile={profile} />

      <Stack>
        {showBrowserWarning && !isChrome && !isFirefox && (
          <Stack
            direction={'row'}
            bgcolor={colors.deepOrange[800]}
            px={1}
            alignItems={'center'}
          >
            <Typography
              variant="caption"
              color="white"
              fontWeight={600}
              textAlign={'center'}
            >
              Use Chrome or Firefox for the best experience
            </Typography>
            <IconButton sx={{ height: 12 }} onClick={dismissBrowserWarning}>
              <CloseIcon color="secondary" />
            </IconButton>
          </Stack>
        )}
        {customSetsData
          ?.sort((a, b) => b.publishedAt - a.publishedAt)
          .map((customSet, i) => (
            <CustomSetCard
              key={i}
              customSet={customSet}
              customSetPlayed={customSetPlayed(customSet.customSetId)}
              handleCardClick={handleCardClick}
              profileId={profile?._id}
            />
          ))}
      </Stack>

      <BottomNav bottomNavValue={0} unseenNotifications={unseenNotifications} />
    </BasicLayout>
  );
};

export default Main;
