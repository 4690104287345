import { useMutation } from '@tanstack/react-query';
import { deleteCustomSetQuestion } from '../../../../../../api/apiServices';

const useDeleteCustomSetQuestion = (handleOnSuccess: () => void) => {
  const { mutate, isPending } = useMutation({
    mutationFn: deleteCustomSetQuestion,
    onSuccess: handleOnSuccess,
  });

  return {
    mutate,
    isPending,
  };
};

export default useDeleteCustomSetQuestion;
