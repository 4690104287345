import {
  Stack,
  Avatar,
  Typography,
  Chip,
  Box,
  Button,
  CircularProgress,
  Rating,
  colors,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { timeSince } from '../../utils/date-time';
import { CustomSet } from '../../types/custom-sets';
import RatingsDialog from './dialogs/RatingsDialog/RatingsDialog';
import { SquareChip } from '../../styled-components/SquareChip';
import ReplyIcon from '@mui/icons-material/Reply';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { getTagColor } from '../main/utilts';
import { isChrome, isFirefox } from 'react-device-detect';
import CloseIcon from '@mui/icons-material/Close';
import {
  getParsedLocalStorageItem,
  setStringifiedLocalStorageItem,
} from '../../local-storage';

type Props = {
  customSet: CustomSet;
  customSetIsPending: boolean;
  customSetRating: number;
  customSetWasStartedButNotPlayed: boolean;
  customSetWasPlayed: boolean;
  handlePlayClick: () => void;
  isCreator: boolean;
  isGuest: boolean;
  profileId: string;
  refetchProfile: () => void;
};

const GameInfo = ({
  customSet,
  customSetIsPending,
  customSetRating,
  customSetWasStartedButNotPlayed,
  customSetWasPlayed,
  handlePlayClick,
  isCreator,
  isGuest,
  profileId,
  refetchProfile,
}: Props) => {
  const navigate = useNavigate();

  const [openRatingDialog, setOpenRatingDialog] = useState(false);

  const [showBrowserWarning, setShowBrowserWarning] = useState(false);

  useEffect(() => {
    const dismissBrowserWarning = getParsedLocalStorageItem(
      'dismissBrowserWarning'
    );
    if (dismissBrowserWarning) {
      setShowBrowserWarning(false);
    } else {
      setShowBrowserWarning(true);
    }
  }, []);

  const handleShare = async (e: any) => {
    e.stopPropagation();
    if (!customSet) return;
    const link = `/game/${customSet.customSetId}`;
    if (navigator.share) {
      try {
        await navigator.share({
          title: `PAR: ${customSet.title}`,
          text: '',
          url: link,
        });
      } catch (err) {}
    }
  };

  const handleBackClick = () => {
    navigate('/');
  };

  const dismissBrowserWarning = () => {
    setStringifiedLocalStorageItem('dismissBrowserWarning', 'true');
    setShowBrowserWarning(false);
  };

  return (
    <>
      {customSetIsPending ? (
        <Stack
          spacing={2}
          py={4}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Typography variant="h6" textAlign={'center'}>
            Loading...
          </Typography>
        </Stack>
      ) : customSet ? (
        <Stack
          spacing={2}
          pt={1}
          pb={2}
          justifyContent={'center'}
          alignItems={'center'}
        >
          {showBrowserWarning && !isChrome && !isFirefox && (
            <Stack
              direction={'row'}
              bgcolor={colors.deepOrange[800]}
              px={1}
              alignItems={'center'}
            >
              <Typography
                variant="caption"
                color="white"
                fontWeight={600}
                textAlign={'center'}
              >
                Use Chrome or Firefox for the best experience
              </Typography>
              <IconButton sx={{ height: 12 }} onClick={dismissBrowserWarning}>
                <CloseIcon color="secondary" />
              </IconButton>
            </Stack>
          )}

          <Stack
            direction={'row'}
            width={'100%'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Button onClick={handleBackClick} startIcon={<ArrowBackIcon />}>
              Back
            </Button>

            <Button
              onClick={handleShare}
              startIcon={<ReplyIcon sx={{ transform: 'scaleX(-1)' }} />}
            >
              Share
            </Button>
          </Stack>
          <Stack
            direction={'row'}
            spacing={1}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Avatar
              variant="rounded"
              src={customSet?.creator.avatarUrl}
              alt={customSet?.creator.username}
              sx={{ width: 24, height: 24 }}
            />
            <Typography variant="body1">
              {customSet?.creator.username}
            </Typography>
          </Stack>
          <Typography variant="h6" textAlign={'center'} fontWeight={600}>
            {customSet?.title || 'Loading...'}
          </Typography>
          {customSet?.description && (
            <Typography variant="body2" textAlign={'center'}>
              {customSet?.description}
            </Typography>
          )}
          <Stack
            direction={'row'}
            spacing={1}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography>{customSet.rating?.toFixed(1)}</Typography>
            <Rating value={customSet.rating} readOnly precision={0.1} />
            <Typography>({customSet.ratingsCount || 0})</Typography>
          </Stack>
          <Stack direction={'row'} spacing={2} justifyContent={'center'}>
            {customSet.tags?.length && customSet.tags[0] ? (
              <Chip
                label={customSet.tags[0]}
                size="small"
                variant="outlined"
                sx={{
                  borderColor: getTagColor(customSet.tags[0]),
                  color: getTagColor(customSet.tags[0]),
                }}
              />
            ) : null}
          </Stack>
          <Stack direction={'row'} spacing={2} justifyContent={'center'}>
            <SquareChip
              variant="filled"
              label={
                customSet?.configs.type === 'buzzer'
                  ? 'Buzzer'
                  : 'Multiple Choice'
              }
              size="small"
            />
            <SquareChip
              variant="filled"
              label={`${customSet?.questions.length} Qs`}
              size="small"
            />
            <SquareChip
              variant="filled"
              label={`Timer: ${customSet?.configs.timerLimit}s`}
              size="small"
            />
          </Stack>
          <Typography variant="caption" textAlign={'center'}>
            Total plays: {customSet?.totalPlays || 0}
          </Typography>
          <Typography variant="caption" textAlign={'center'}>
            Published {timeSince(new Date(customSet?.publishedAt), true)} ago
          </Typography>
          <Box display={'flex'} width={'100%'} justifyContent={'center'} py={2}>
            {customSetWasStartedButNotPlayed ? (
              <Button variant="contained" disabled>
                Game Abandoned
              </Button>
            ) : (
              <Button
                variant={
                  customSetWasPlayed || isCreator ? 'outlined' : 'contained'
                }
                onClick={() => handlePlayClick()}
                disabled={!customSet && !isCreator}
                startIcon={
                  !customSet ? <CircularProgress size={16} /> : undefined
                }
                color={isCreator ? 'secondary' : 'primary'}
              >
                {isCreator
                  ? 'VIEW STATS (CREATOR)'
                  : customSetWasPlayed
                  ? 'RESULTS'
                  : 'PLAY'}
              </Button>
            )}
          </Box>

          {customSet?.archived && (
            <Typography variant="body2" textAlign={'center'} color="error">
              This set is archived
            </Typography>
          )}

          {!isGuest && customSetRating ? (
            <Stack>
              <Typography variant="caption" textAlign={'center'}>
                Your rating
              </Typography>
              <Rating value={customSetRating} readOnly size="small" />
            </Stack>
          ) : !isGuest && customSetWasPlayed ? (
            <Button color="secondary" onClick={() => setOpenRatingDialog(true)}>
              Give this set a rating
            </Button>
          ) : null}
        </Stack>
      ) : (
        <Stack
          spacing={2}
          py={4}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Typography variant="h6" textAlign={'center'} color="error">
            Bad link. No set found.
          </Typography>
        </Stack>
      )}

      {customSet && (
        <RatingsDialog
          customSetId={customSet.customSetId}
          open={openRatingDialog}
          onClose={() => setOpenRatingDialog(false)}
          profileId={profileId}
          refetchProfile={refetchProfile}
        />
      )}
    </>
  );
};

export default GameInfo;
