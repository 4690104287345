import React from 'react';
import {
  Avatar,
  Badge,
  BadgeProps,
  PaletteColor,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { getAvatarSrc } from '../../api/cloudinary';
import { Player } from './types';

const PenaltyBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -5,
    top: 22,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

type Props = {
  avatarUrl?: string;
  avatarPublicId?: string;
  player: Player;
  timerLimitReached: boolean;
};

export const PlayerDisplay = ({
  avatarUrl,
  avatarPublicId,
  player,
  timerLimitReached,
}: Props) => {
  const theme = useTheme();

  const avatarSource = getAvatarSrc(avatarPublicId, avatarUrl, 50, 50);
  const color = player?.correctAnswer
    ? theme.palette.success
    : theme.palette.error;
  const hasShadow = timerLimitReached || player?.correctAnswer;

  const boxShadow = (paletteColor: PaletteColor) =>
    `${paletteColor.main} 0px 0px 5px -1px, ${paletteColor.main} 0px 0px 5px 0px, ${paletteColor.main} 0px 0px 5px 0px`;

  return (
    <PenaltyBadge badgeContent={player?.totalPenalty} color="warning">
      <Avatar
        src={
          player?.timerLimitReached || player?.correctAnswer
            ? null
            : avatarSource
        }
        sx={{
          boxShadow: hasShadow ? boxShadow(color) : null,
          backgroundColor:
            player?.timerLimitReached || player?.correctAnswer
              ? 'rgb(117, 117, 117, 0.2)'
              : 1,
        }}
      >
        {player?.currentScore !== null ? (
          <Typography
            variant="h6"
            color={player?.timerLimitReached ? '#cd0d01' : '#0db586'}
            fontWeight={600}
          >
            {player?.currentScore > 0
              ? `+${player?.currentScore}`
              : player?.currentScore}
          </Typography>
        ) : null}
      </Avatar>
    </PenaltyBadge>
  );
};
