import { useQuery } from '@tanstack/react-query';
import { getCustomSetsPlayed } from '../../../api/apiServices';

const useGetCustomSetsPlayed = (profileId: string) => {
  const { data, refetch } = useQuery({
    queryKey: ['getCustomSetsPlayed', { profileId }],
    queryFn: getCustomSetsPlayed,
    enabled: !!profileId,
  });

  return {
    refetch,
    data,
  };
};

export default useGetCustomSetsPlayed;
