import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuthVerify from './hooks/useAuthVerify';

type Props = {
  refetchProfile: () => void;
};

export const Verify = ({ refetchProfile }: Props) => {
  const navigate = useNavigate();

  const { token } = useParams();

  const { data, error, isPending } = useAuthVerify(token);

  useEffect(() => {
    if (isPending) return;
    if (error) {
      navigate('/login', {
        state: {
          showResendVerification: true,
        },
      });
      return;
    }
    if (data) {
      refetchProfile();
    }
  }, [data, isPending, error]);

  return <></>;
};
