import { Box } from '@mui/material';
import React from 'react';
import { isMobile } from 'react-device-detect';

type Props = {
  handleBuzz: () => void;
  disableBuzzer: () => boolean;
  isLocked: boolean;
};

const Buzzer = ({ handleBuzz, disableBuzzer, isLocked }: Props) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 50,
        height: 200,
        width: '100%',
        padding: 5,
      }}
    >
      <Box
        key={isLocked ? 'locked' : 'unlocked'}
        position={'relative'}
        display={'flex'}
        justifyContent={'center'}
      >
        <button
          className="buzzer"
          style={{
            letterSpacing: 5,
          }}
          disabled={disableBuzzer()}
          onClick={handleBuzz}
        >
          {isLocked
            ? '1 SEC PENALTY LOCK'
            : disableBuzzer()
            ? 'WAIT'
            : !isMobile
            ? 'TAP [SPACE] TO ANSWER'
            : 'TAP TO ANSWER'}
        </button>
      </Box>
    </Box>
  );
};

export default Buzzer;
