import { useMutation } from '@tanstack/react-query';
import { postAuthResendEmailVerification } from '../../../api/apiServices';

export const useResendEmailVerification = (
  handleOnSuccess: () => void,
  handleOnError: (error: any) => void
) => {
  const { mutate } = useMutation({
    mutationFn: postAuthResendEmailVerification,
    onSuccess: handleOnSuccess,
    onError: handleOnError,
  });

  return {
    mutate,
  };
};
