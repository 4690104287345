import { UserNotification } from '../pages/Activity/types';
import {
  GeocodeResponse,
  PredictionResponse,
  UpdateProfileBody,
} from '../pages/Profile/types';
import { CustomSetLeaderboard } from '../pages/game/dialogs/ResultsDialog/types';
import { Game, Question } from '../pages/game/types';
import { CustomSet, CustomSetListItem } from '../types/custom-sets';
import { Profile } from '../types/types';
import apiClient from './apiClient';
import { API_ROUTES } from './apiRoutes';

export const postLogout = async () => apiClient.post(API_ROUTES.postLogout);

export const postLogin = async ({ email, password }) =>
  apiClient.post(API_ROUTES.postLogin, { email, password });

export const postGoogleLogin = async ({ codeResponse }) =>
  apiClient.post(API_ROUTES.postGoogleLogin, { codeResponse });

export const getAuthVerify = async ({
  queryKey,
}): Promise<CustomSetLeaderboard> => {
  const [, { token }] = queryKey;
  const res = await apiClient.get(`${API_ROUTES.getAuthVerify}/${token}`);
  return res.data;
};

export const postAuthSignup = async ({ email, password }) =>
  apiClient.post(API_ROUTES.postAuthSignup, { email, password });

export const postAuthForgotPassword = async ({ email }) =>
  apiClient.post(API_ROUTES.postAuthForgotPassword, { email });

export const postAuthResetPassword = async ({ token, password }) =>
  apiClient.post(`${API_ROUTES.postAuthResetPassword}/${token}`, { password });

export const postAuthResendEmailVerification = async ({ email }) =>
  apiClient.post(API_ROUTES.postAuthResendEmailVerification, { email });

export const getCustomSets = async (): Promise<CustomSetListItem[]> => {
  const res = await apiClient.get(API_ROUTES.getCustomSets);
  return res.data;
};

export const getCustomSetsDrafts = async ({
  queryKey,
}): Promise<CustomSet[]> => {
  const [, { profileId }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.getCustomSetsDrafts}/${profileId}`
  );
  return res.data;
};

export const getCustomSetsPublished = async ({
  queryKey,
}): Promise<CustomSet[]> => {
  const [, { profileId }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.getCustomSetsPublished}/${profileId}`
  );
  return res.data;
};

export const getCustomSetQuestions = async ({
  queryKey,
}): Promise<Question[]> => {
  const [, { customSetId }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.getCustomSetQuestions}/${customSetId}`
  );
  return res.data;
};

export const getCustomSetsCreated = async ({
  queryKey,
}): Promise<CustomSetListItem[]> => {
  const [, { profileId }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.getCustomSetsCreated}/${profileId}`
  );
  return res.data;
};

export const getCustomSetsPlayed = async ({
  queryKey,
}): Promise<CustomSetListItem[]> => {
  const [, { profileId }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.getCustomSetsPlayed}/${profileId}`
  );
  return res.data;
};

export const getProfile = async (): Promise<Profile> => {
  const res = await apiClient.get(API_ROUTES.getProfile);
  return res.data;
};

export const getProfileById = async ({ queryKey }): Promise<Profile> => {
  const [, { profileId }] = queryKey;
  const res = await apiClient.get(`${API_ROUTES.getProfileById}/${profileId}`);
  return res.data;
};

export const postUpdateProfile = async (body: UpdateProfileBody) =>
  apiClient.post(API_ROUTES.postUpdateProfile, body);

export const fetchPlacesPredictions = async ({
  queryKey,
}): Promise<PredictionResponse> => {
  const [, { input }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchPlacesPredictions}?input=${input}`
  );
  return res.data;
};

export const fetchPlacesGeocode = async ({
  queryKey,
}): Promise<GeocodeResponse> => {
  const [, { placeId }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.fetchPlacesGeocode}/${placeId}`
  );
  return res.data;
};

export const getCustomGame = async ({ queryKey }): Promise<Game> => {
  const [, { profileId, customSetId }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.getCustomGame}/${profileId}/${customSetId}`
  );
  return res.data;
};

export const getCustomSetLeaderboard = async ({
  queryKey,
}): Promise<CustomSetLeaderboard> => {
  const [, { customSetId }] = queryKey;
  const res = await apiClient.get(
    `${API_ROUTES.getCustomSetLeaderboard}/${customSetId}`
  );
  return res.data;
};

export const postCustomGameStarted = async ({
  profileId,
  customSetId,
  version,
}) =>
  apiClient.post(API_ROUTES.postCustomGameStarted, {
    profileId,
    customSetId,
    version,
  });

export const postCustomGame = async ({ profileId, game }) =>
  apiClient.post(API_ROUTES.postCustomGame, { profileId, game });

export const postCustomSetRating = async ({
  profileId,
  customSetId,
  rating,
  comment,
}) =>
  apiClient.post(API_ROUTES.postCustomSetRating, {
    profileId,
    customSetId,
    rating,
    comment,
  });

export const getCustomSet = async ({ queryKey }): Promise<CustomSet> => {
  const [, { customSetId }] = queryKey;
  const res = await apiClient.get(`${API_ROUTES.getCustomSet}/${customSetId}`);
  return res.data;
};

export const postCreateCustomSet = async ({
  profileId,
  customSetId,
  title,
  description,
  type,
  timerLimit,
  tags,
  isPrivate,
}) =>
  apiClient.post(API_ROUTES.postCreateCustomSet, {
    profileId,
    customSetId,
    title,
    description,
    type,
    timerLimit,
    tags,
    isPrivate,
  });

export const deleteCustomSet = async ({ customSetId }) =>
  apiClient.delete(`${API_ROUTES.deleteCustomSet}/${customSetId}`);

export const postPublishCustomSet = async ({ customSetId }) =>
  apiClient.post(API_ROUTES.postPublishCustomSet, {
    customSetId,
  });

export const postArchiveCustomSet = async ({ customSetId }) =>
  apiClient.post(API_ROUTES.postArchiveCustomSet, {
    customSetId,
  });

export const postCreateCustomSetQuestion = async ({
  profileId,
  questionId,
  customSetId,
  question,
  answer,
  revealAnswerAtSecond,
  par,
  category,
  type,
  alternateAnswers,
  promptAnswers,
  multipleChoiceAnswers,
}) =>
  apiClient.post(API_ROUTES.postCreateCustomSetQuestion, {
    profileId,
    questionId,
    customSetId,
    question,
    answer,
    revealAnswerAtSecond,
    par,
    category,
    type,
    alternateAnswers,
    promptAnswers,
    multipleChoiceAnswers,
  });

export const postUpdateQuestionsSortOrder = async ({
  customSetId,
  questions,
}) =>
  apiClient.post(API_ROUTES.postUpdateQuestionsSortOrder, {
    customSetId,
    questions,
  });

export const deleteCustomSetQuestion = async ({ customSetId, questionId }) =>
  apiClient.delete(
    `${API_ROUTES.deleteCustomSetQuestion}/${customSetId}/${questionId}`
  );

export const getUserNotifications = async (): Promise<UserNotification[]> => {
  const res = await apiClient.get(API_ROUTES.getUserNotifications);
  return res.data;
};

export const getUnseenUserNotifications = async (): Promise<{
  unseenUserNotificationsCount: number;
}> => {
  const res = await apiClient.get(API_ROUTES.getUnseenUserNotifications);
  return res.data;
};

export const postUserNotificationsSeen = async () =>
  apiClient.post(API_ROUTES.postUserNotificationsSeen, {});
