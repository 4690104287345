import {
  Box,
  Button,
  CardMedia,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import BasicLayout from '../BasicLayout';
import BottomNav from '../BottomNav/BottomNav';
import { Header } from '../header';
import { Profile } from '../../types/types';
import { useParams } from 'react-router-dom';
import { PhotoCamera } from '@mui/icons-material';
import { getAvatarSrc, uploadImageToCloudinary } from '../../api/cloudinary';
import useGetProfileById from './hooks/useGetProfileById';
import useUpdateProfile from './hooks/useUpdateProfile';
import dayjs from 'dayjs';
import { SquareChip } from '../../styled-components/SquareChip';
import CircleIcon from '@mui/icons-material/Circle';
import ProfileTabs from './ProfileTabs/ProfileTabs';
import useGetCustomSetsPlayed from './hooks/useGetCustomSetsPlayed';
import useGetCustomSetsCreated from './hooks/useGetCustomSetsCreated';
import { UserNotification } from '../Activity/types';
import { EditUsernameDialog } from './EditProfile/EditUsernameDialog';

type Props = {
  clearProfile: () => void;
  profile: Profile;
  refetchProfile: () => void;
  unseenNotifications: number;
};

const ProfilePage = ({
  clearProfile,
  profile,
  refetchProfile,
  unseenNotifications,
}: Props) => {
  const theme = useTheme();
  const { viewProfileId } = useParams();

  const [openEditProfileDialog, setOpenEditProfileDialog] = useState(false);
  const [dimAvatar, setDimAvatar] = useState(false);

  const { data: viewProfile } = useGetProfileById(viewProfileId);

  const { data: customSetsPlayed } = useGetCustomSetsPlayed(viewProfile?._id);
  const { data: customSetsCreated } = useGetCustomSetsCreated(viewProfile?._id);

  const { mutate: updateProfile } = useUpdateProfile(refetchProfile);

  const fileUploadRef = useRef<HTMLInputElement>(null);

  const imageUpload = async (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    const cloudinaryRes = await uploadImageToCloudinary(file);
    updateProfile({
      avatarPublicId: cloudinaryRes.public_id,
      avatarUrl: cloudinaryRes.url,
    });
  };

  const handleSaveProfileSuccess = () => {
    refetchProfile();
    setOpenEditProfileDialog(false);
  };

  const allowEdits = profile?._id === viewProfileId;

  return (
    <>
      <BasicLayout hasHeader>
        <Header clearProfile={clearProfile} profile={profile} />

        <Stack p={2}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Box width={'100px'} sx={{ position: 'relative' }}>
              <CardMedia
                component="img"
                image={getAvatarSrc(
                  viewProfile?.avatarPublicId,
                  viewProfile?.avatarUrl,
                  300,
                  300
                )}
                alt="viewProfile picture"
                sx={{
                  height: 80,
                  width: 80,
                  opacity: dimAvatar ? 0.5 : 1,
                  border: '1px solid',
                  borderColor: theme.palette.primary.dark,
                  borderRadius: 1,
                }}
              />
              {allowEdits ? (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    height: 80,
                    width: 80,
                    opacity: 0,
                    '&:hover': { opacity: 1 },
                  }}
                >
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    sx={{ height: '100%', width: '100%' }}
                    onMouseEnter={() => setDimAvatar(true)}
                    onMouseLeave={() => setDimAvatar(false)}
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      ref={fileUploadRef}
                      onChange={(e) => imageUpload(e)}
                    />
                    <PhotoCamera />
                  </IconButton>
                </Box>
              ) : null}
            </Box>

            {allowEdits && (
              <Box>
                <Button
                  variant="outlined"
                  onClick={() => setOpenEditProfileDialog(true)}
                >
                  Edit
                </Button>
              </Box>
            )}
          </Stack>

          <Typography pt={1} variant="h4" fontWeight={600}>
            {viewProfile?.username}
          </Typography>

          <Stack
            pt={1}
            pb={2}
            direction={'row'}
            spacing={1}
            alignItems={'center'}
          >
            {viewProfile?.location && (
              <Typography variant="caption">{viewProfile?.location}</Typography>
            )}

            {viewProfile?.location && (
              <CircleIcon sx={{ width: 4, height: 4 }} />
            )}

            <Typography variant="caption">
              {dayjs(viewProfile?.createdAt).format('MMM D, YYYY')}
            </Typography>
          </Stack>

          <Divider />

          <ProfileTabs
            customSetsCreated={customSetsCreated}
            customSetsPlayed={customSetsPlayed}
            profileId={profile?._id}
          />
        </Stack>

        <BottomNav
          bottomNavValue={null}
          unseenNotifications={unseenNotifications}
        />
      </BasicLayout>

      {allowEdits && profile && (
        <EditUsernameDialog
          close={() => setOpenEditProfileDialog(false)}
          handleSaveSuccess={handleSaveProfileSuccess}
          open={openEditProfileDialog}
          profile={profile}
        />
      )}
    </>
  );
};

export default ProfilePage;
