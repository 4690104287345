import { Game } from '../pages/game/types';

export let games: Record<string, Game> = {};

export const GAME_STAGES = {
  COUNT_DOWN: 'COUNT_DOWN',
  DELAY_BEFORE_QUESTION_REVEAL: 'DELAY_BEFORE_QUESTION_REVEAL',
  REVEAL_QUESTION: 'REVEAL_QUESTION',
  REVEAL_BOXES: 'REVEAL_BOXES',
  DELAY_BEFORE_LETTER_REVEAL: 'DELAY_BEFORE_LETTER_REVEAL',
  PAUSED: 'PAUSED',
  REVEAL_ANSWER: 'REVEAL_ANSWER',
  WAIT_UNTIL_TIME_LIMIT: 'WAIT_UNTIL_TIME_LIMIT',
  NEXT_QUESTION: 'NEXT_QUESTION',
};

export const UNPAUSE_COUNT = 3;
export const DELAY_EXTRA_BEFORE_BOX_REVEAL_COUNT = 4;
export const DELAY_BEFORE_LETTER_REVEAL_COUNT = 0;
export const DELAY_BEFORE_QUESTION_REVEAL_COUNT = 0;
export const DISPLAY_BOXES_COUNT = 0.5;
export const MAX_PLAYERS = 10000;
export const NEXT_QUESTION_DELAY_COUNT = 2;
export const REVEAL_TIME_LIMIT = 10;

export const COUNTDOWN_DURATION = 3;
export const QUESTIONS_IN_SET = 6;

export const EXCLUDED_ARTICLES = ['THE', 'AN', 'A'];
