import { Game, RoundScore } from '../../pages/game/types';
import { GAME_STAGES } from '../constants';
import { gameEvents } from '../event-emitter';
import { collapseWords, roundToTwoDecimals } from '../game-loop/utils';
import { isCorrectAnswer } from './check-answer';
import { calculateFuzzyPenalty } from './fuzzy';

export const playerInput = (
  game: Game,
  profileId: string,
  input: string,
  collapsedInput: string
) => {
  const gameClock = game.gameClock;
  const gameStage = game.gameStage;
  // console.log('playerInput', input, collapsedInput, gameClock, gameStage);
  if (
    gameClock >= 20 ||
    gameClock < 0 ||
    gameStage === GAME_STAGES.DELAY_BEFORE_QUESTION_REVEAL ||
    gameStage === GAME_STAGES.NEXT_QUESTION
  ) {
    return;
  }

  // const penaltyTimestamp = getPlayerPenaltyTimestsamp(gameId, profileId);
  // if (penaltyTimestamp) {
  //   if (
  //     Date.now() < penaltyTimestamp + WRONG_ANSWER_PENALTY_DURATION &&
  //     gameStage === GAME_STAGES.REVEAL_QUESTION
  //   ) {
  //     console.log(
  //       'playerInput preventAnswer',
  //       input,
  //       collapsedInput,
  //       user?.username
  //     );
  //     return;
  //   } else {
  //     setPlayerPenaltyTimestamp(gameId, profileId, null);
  //   }
  // }

  const questionIndex = game.questionIndex;
  const player = game.players[profileId];
  const roundScores = player.roundScores;
  if (roundScores[questionIndex].score !== null) {
    return;
  }
  updatePlayerInput(game, input, collapsedInput, profileId);
};

export const updatePlayerInput = (
  game: Game,
  playerInput: string,
  playerCollapsedInput: string,
  profileId: string
) => {
  const questionIndex = game.questionIndex;
  const questionData = game.questionData;
  const startTime = game.startTime;
  const par = questionData.par;
  const promptAnswers = questionData.promptAnswer;
  const collapsedAnswer = game.collapsedAnswer;
  const collapsedAlternateAnswers = game.collapsedAlternateAnswers;
  const gameClock = game.gameClock;
  const plural = questionData.plural;
  const isPlayerPromptAnswer = game.players[profileId].isPromptAnswer;
  const submittedPromptAnswer = game.players[profileId].submittedPromptAnswer;
  if (isPlayerPromptAnswer && playerCollapsedInput) {
    const collapsedAlternateAnswersIfPrompted =
      game.collapsedAlternateAnswersIfPrompted;
    if (
      collapsedAlternateAnswersIfPrompted &&
      collapsedAlternateAnswersIfPrompted[submittedPromptAnswer]
    ) {
      collapsedAlternateAnswers.push(
        ...collapsedAlternateAnswersIfPrompted[submittedPromptAnswer]
      );
    } else {
      console.log(
        'BUG: collapsedAlternateAnswersIfPrompted',
        collapsedAlternateAnswers,
        submittedPromptAnswer
      );
    }
  }
  if (
    promptAnswers &&
    promptAnswers.length > 0 &&
    !isPlayerPromptAnswer &&
    playerCollapsedInput
  ) {
    const promptFuzzyResults = isPromptAnswer(
      playerCollapsedInput,
      promptAnswers
    );
    if (promptFuzzyResults.correct) {
      game.players[profileId].incorrectAnswer = false;
      game.players[profileId].isPromptAnswer = true;
      game.players[profileId].submittedPromptAnswer = playerCollapsedInput;
      const player = { ...game.players[profileId] };
      gameEvents.emit('player', player);
      return;
    }
  }

  const { correct, fuzzy } = isCorrectAnswer(
    game,
    playerCollapsedInput,
    collapsedAnswer,
    collapsedAlternateAnswers,
    gameClock,
    profileId,
    questionIndex,
    plural,
    submittedPromptAnswer
  );

  const rawSeconds =
    (game.pausedTimestamp - game.gameClockIntervalTimestamp) / 1000;

  if (rawSeconds >= 20) {
    console.log('raw seconds > 20', rawSeconds);
    return;
  }
  if (correct) {
    const seconds = Math.floor(rawSeconds);
    // Defense against seconds being greater than 20 in case server has a hiccup with Date.now (this happened in game before and the players had score +500)
    const score =
      seconds < 0 ? 0 - par : seconds >= 20 ? 20 - par : seconds - par;

    // console.log(
    //   'updatePlayerInput correct',
    //   playerInput,
    //   playerCollapsedInput,
    //   seconds,
    //   score,
    //   par
    // );
    updatePlayerScore(
      game,
      profileId,
      questionIndex,
      score,
      true,
      fuzzy,
      playerInput,
      rawSeconds, // don't use gameclock here because gameloop might push the next second and we need to preserve the exact second the user answers
      submittedPromptAnswer
    );
  } else {
    // console.log(
    //   'updatePlayerInput incorrect',
    //   playerInput,
    //   playerCollapsedInput
    // );
    updatePlayerAnswerAttempts(
      game,
      profileId,
      questionIndex,
      playerInput,
      rawSeconds,
      submittedPromptAnswer
    );

    // Lock user from answering if letters haven't been revealed yet
    const gameStage = game.previousGameStage;
    if (
      game.type === 'buzzer' &&
      gameStage !== GAME_STAGES.COUNT_DOWN &&
      gameStage !== GAME_STAGES.DELAY_BEFORE_QUESTION_REVEAL &&
      gameStage !== GAME_STAGES.REVEAL_ANSWER &&
      gameStage !== GAME_STAGES.DELAY_BEFORE_LETTER_REVEAL &&
      gameStage !== GAME_STAGES.REVEAL_BOXES &&
      gameStage !== GAME_STAGES.WAIT_UNTIL_TIME_LIMIT &&
      gameStage !== GAME_STAGES.NEXT_QUESTION
    ) {
      updatePreventAnswer(game, profileId, questionIndex);
    } else if (
      game.type === 'buzzer' &&
      gameStage !== GAME_STAGES.COUNT_DOWN &&
      gameStage !== GAME_STAGES.NEXT_QUESTION
    ) {
      lockoutPlayer(game, profileId);
    }

    game.players[profileId].incorrectAnswer = true;
    game.players[profileId].isPromptAnswer = false;
    game.players[profileId].submittedPromptAnswer = null;
  }

  const player = { ...game.players[profileId] };
  gameEvents.emit('player', player);

  // update gameloop server if all players answered correctly to move on to next question
  if (allPlayersAnsweredCorrectly(game)) {
    game.allPlayersAnsweredCorrectly = true;
  }
};

const isPromptAnswer = (
  playerCollapsedInput: string,
  promptAnswers: string[]
) => {
  const upperPromptAnswers = promptAnswers.map((a) =>
    collapseWords(a).toUpperCase()
  );
  for (const answer of upperPromptAnswers) {
    if (playerCollapsedInput === answer) {
      return { correct: true, fuzzy: 0 };
    }
    // TODO: not sure how to handle fuzzy and prompt. Test with "George H.W. Bush"
    // const fuzzyScore = calcFuzzyScore(playerCollapsedInput, answer);
    // if (fuzzyScore > 0.95) {
    //   return { correct: true, fuzzy: fuzzyScore };
    // }
  }
  return { correct: false, fuzzy: 0 };
};

export const updatePlayerScore = (
  game: Game,
  profileId: string,
  questionIndex: number,
  score: number,
  correctAnswer: boolean,
  fuzzy: number,
  playerInput?: string,
  gameClock?: number,
  submittedPromptAnswer?: string
) => {
  const roundScores = game.players[profileId].roundScores;
  const questionScore = roundScores[questionIndex];
  const answerAttempts = questionScore ? questionScore.answerAttempts : [];
  const updatedAnswerAttempts = [
    ...answerAttempts,
    {
      input: submittedPromptAnswer
        ? `(${submittedPromptAnswer}) ${playerInput}`
        : playerInput,
      gameClock: gameClock,
    },
  ];
  const fuzzyPenalty = calculateFuzzyPenalty(fuzzy);
  const updateRoundScores: Record<number, RoundScore> = {
    ...roundScores,
    [questionIndex]: {
      score: score,
      fuzzyPenalty: fuzzyPenalty,
      answerAttempts: playerInput ? updatedAnswerAttempts : answerAttempts,
      penalty: questionScore.penalty,
      timerLimitReached: !correctAnswer,
    },
  };
  game.players[profileId].roundScores = updateRoundScores;
  const parScore = game.players[profileId].parScore;
  const newParScore = parScore + score + questionScore.penalty + fuzzyPenalty;
  game.players[profileId].parScore = newParScore;
  game.players[profileId].correctAnswer = correctAnswer;
  game.players[profileId].currentScore = score;
  game.players[profileId].fuzzyScore = fuzzy;
  game.players[profileId].timerLimitReached = !correctAnswer;

  // if answer is a fuzzy correct, emit the full answer
  // so users can see how it should've been spelled
  if (fuzzy) {
    const playerFuzzy = {
      [profileId]: fuzzy,
    };
    gameEvents.emit('fuzzy', playerFuzzy);
  }
};

export const updatePlayerAnswerAttempts = (
  game: Game,
  profileId: string,
  questionIndex: number,
  playerInput: string,
  gameClock: number,
  submittedPromptAnswer?: string
) => {
  const roundScores = game.players[profileId].roundScores;
  const questionScore = roundScores[questionIndex];
  const answerAttempts = questionScore ? questionScore.answerAttempts : [];
  const updatedAnswerAttempts = [
    ...answerAttempts,
    {
      input: submittedPromptAnswer
        ? `(${submittedPromptAnswer}) ${playerInput}`
        : playerInput,
      gameClock: gameClock,
    },
  ];
  const updateRoundScores = {
    ...roundScores,
    [questionIndex]: {
      ...questionScore,
      answerAttempts: playerInput ? updatedAnswerAttempts : answerAttempts,
    },
  };
  game.players[profileId].roundScores = updateRoundScores;
};

export const allPlayersAnsweredCorrectly = (game: Game) => {
  const players = game.players;
  for (const player of Object.values(players)) {
    if (!player.correctAnswer) return false;
  }
  return true;
};

const updatePreventAnswer = async (
  game: Game,
  profileId: string,
  questionIndex: number
) => {
  const roundScores = game.players[profileId].roundScores;
  game.players[profileId].roundScores[questionIndex].penalty =
    roundScores[questionIndex].penalty + 1;
  const totalPenalty = game.players[profileId].totalPenalty;
  game.players[profileId].totalPenalty = totalPenalty + 1;
  lockoutPlayer(game, profileId);
};

const lockoutPlayer = (game: Game, profileId: string) => {
  const penaltyTimestamp = Date.now();
  game.players[profileId].penaltyTimestamp = penaltyTimestamp;
  gameEvents.emit('penalty', { profileId, penaltyTimestamp });
};

export const updatePlayerStatesBeforeNextQuestion = async (game: Game) => {
  const players = game.players;
  Object.values(players).forEach((p) => {
    p.correctAnswer = false;
    p.currentScore = null;
    p.incorrectAnswer = false;
    p.fuzzyScore = null;
    p.timerLimitReached = false;
    p.isPromptAnswer = false;
    p.submittedPromptAnswer = null;
  });
};
