import { Box, Tabs, Tab, useTheme, Typography } from '@mui/material';
import React from 'react';
import { a11yProps, TabPanel } from './constants';
import CreatedList from './CreatedList';
import { CustomSetListItem } from '../../../types/custom-sets';
import PlayedList from './PlayedList';

type Props = {
  customSetsCreated?: CustomSetListItem[];
  customSetsPlayed?: CustomSetListItem[];
  profileId: string;
};

const ProfileTabs = ({
  customSetsCreated,
  customSetsPlayed,
  profileId,
}: Props) => {
  const theme = useTheme();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="inherit"
        variant="fullWidth"
      >
        <Tab
          label={`Created (${customSetsCreated?.length || 0})`}
          {...a11yProps(0)}
          sx={{
            textTransform: 'none',
          }}
        />
        <Tab
          label={`Played (${customSetsPlayed?.length || 0})`}
          {...a11yProps(1)}
          sx={{
            textTransform: 'none',
          }}
        />
      </Tabs>

      <TabPanel value={value} index={0} dir={theme.direction}>
        <CreatedList
          customSetsCreated={customSetsCreated}
          profileId={profileId}
        />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <PlayedList customSetsPlayed={customSetsPlayed} profileId={profileId} />
      </TabPanel>
    </Box>
  );
};

export default ProfileTabs;
