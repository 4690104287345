import { Box, Button, Fade, Stack, colors } from '@mui/material';
import React, { useState } from 'react';
import { collapseWords } from '../../game-engine/game-loop/utils';
import { playerInput } from '../../game-engine/game-input/player-input';
import { games } from '../../game-engine/constants';

type Props = {
  gameId: string;
  profileId: string;
  revealMultipleChoiceAnswer: string;
  revealMultipleChoiceAnswers: string[];
  selectedMultipleChoiceAnswer: string;
};

const MultipleChoiceAnswers = ({
  gameId,
  profileId,
  revealMultipleChoiceAnswer,
  revealMultipleChoiceAnswers,
  selectedMultipleChoiceAnswer,
}: Props) => {
  const isCorrect = revealMultipleChoiceAnswer === selectedMultipleChoiceAnswer;
  console.log('selectedMultipleChoiceAnswer', selectedMultipleChoiceAnswer);
  const handleMultipleChoiceAnswerClick = (answer: string) => {
    const collapsedAnswer = collapseWords(answer);
    games[gameId].pausedTimestamp = Date.now();
    playerInput(games[gameId], profileId, answer, collapsedAnswer);

    // leveraging the buzzer mechanic here to end the question immediately
    games[gameId].buzzerAttemptsRemaining = 0;
  };

  const getColor = (answer: string) => {
    if (!revealMultipleChoiceAnswer) {
      return 'primary';
    } else if (revealMultipleChoiceAnswer === answer) {
      return 'success';
    } else if (!isCorrect && selectedMultipleChoiceAnswer === answer) {
      return 'error';
    } else {
      return 'gray';
    }
  };

  return (
    <Fade in={!!revealMultipleChoiceAnswers?.length} timeout={500}>
      <Stack
        spacing={1}
        width={'100%'}
        px={2}
        position={'absolute'}
        bottom={100}
      >
        {revealMultipleChoiceAnswers?.map((answer, index) => (
          <Button
            key={index}
            variant="outlined"
            fullWidth
            onClick={() => handleMultipleChoiceAnswerClick(answer)}
            size="large"
            color={getColor(answer)}
            sx={{
              height: 50,
            }}
          >
            {answer}
          </Button>
        ))}
      </Stack>
    </Fade>
  );
};

export default MultipleChoiceAnswers;
