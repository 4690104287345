import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import React from 'react';

type Props = {
  confirmAction: () => void;
  onClose: () => void;
  open: boolean;
};

const DeleteConfirmDialog = ({ confirmAction, onClose, open }: Props) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Are you sure you want to delete this set?</DialogTitle>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={confirmAction} color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmDialog;
