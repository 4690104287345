import { useQuery } from '@tanstack/react-query';
import { getCustomSetQuestions } from '../../../../api/apiServices';

const useGetCustomSetQuestions = (customSetId: string) => {
  const { data, isPending, refetch } = useQuery({
    queryKey: ['customSetQuestions', { customSetId }],
    queryFn: getCustomSetQuestions,
    enabled: !!customSetId,
  });

  return {
    data,
    isPending,
    refetch,
  };
};

export default useGetCustomSetQuestions;
