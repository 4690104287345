import { useMutation } from '@tanstack/react-query';
import { postUpdateQuestionsSortOrder } from '../../../../../api/apiServices';

const useUpdateQuestionOrder = (handleOnSuccess: () => void) => {
  const { mutate, isPending } = useMutation({
    mutationFn: postUpdateQuestionsSortOrder,
    onSuccess: handleOnSuccess,
  });

  return {
    mutate,
    isPending,
  };
};

export default useUpdateQuestionOrder;
