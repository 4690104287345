import { useMutation } from '@tanstack/react-query';
import { postCustomSetRating } from '../../../../api/apiServices';

const useUpdateRating = (handleOnSuccess: () => void) => {
  const { mutate } = useMutation({
    mutationFn: postCustomSetRating,
    onSuccess: handleOnSuccess,
  });

  return {
    mutate,
  };
};

export default useUpdateRating;
