import React from 'react';
import { UserNotification } from './types';
import { getFormattedNotification } from './utils';
import { Stack, Box, Avatar, Typography, Divider, colors } from '@mui/material';
import { getAvatarSrc } from '../../api/cloudinary';
import { useNavigate } from 'react-router-dom';
import { timeSince } from '../../utils/date-time';

type Props = {
  notification: UserNotification;
};

const ActivityCard = ({ notification }: Props) => {
  const navigate = useNavigate();

  const notif = getFormattedNotification(notification);

  const handleClick = () => {
    navigate(`/game/${notif.customSetId}`);
  };

  const handleProfileClick = (e: any, profileId: string) => {
    e.stopPropagation();
    navigate(`/profile/${profileId}`);
  };

  return (
    <>
      <Stack
        direction={'row'}
        spacing={1}
        py={1}
        bgcolor={!notification.seen ? colors.grey[900] : undefined}
        onClick={handleClick}
      >
        <Stack
          onClick={(e) =>
            handleProfileClick(e, notification.metadata.profileId)
          }
        >
          <Avatar src={getAvatarSrc(notif.avatarPublicId, undefined, 40, 40)} />
        </Stack>

        <Stack width={'100%'}>
          <Typography
            variant="caption"
            fontWeight={!notification.seen ? 600 : undefined}
          >
            {notif.header}
          </Typography>
          <Stack
            direction={'row'}
            width={'100%'}
            justifyContent={'space-between'}
          >
            <Typography variant="caption">{notif.content}</Typography>
            <Typography variant="caption">
              {timeSince(new Date(notification.createdAt), true)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Divider />
    </>
  );
};

export default ActivityCard;
