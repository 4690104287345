export const generateQuestionData = (
  questionScores: number[],
  par: number,
  timerLimit: number
) => {
  const answersCounts = Array.from({ length: timerLimit + 1 }, () => 0);

  questionScores.forEach((score) => {
    const answer = score + par;
    answersCounts[answer] += 1;
  });

  return answersCounts;
};
