import { useQuery } from '@tanstack/react-query';
import { getCustomSet } from '../../api/apiServices';

const useGetCustomSet = (customSetId: string) => {
  const { data, isPending, refetch } = useQuery({
    queryKey: ['customSet', { customSetId }],
    queryFn: getCustomSet,
    enabled: !!customSetId,
    retry: 1,
  });

  return {
    refetch,
    data,
    isPending,
  };
};

export default useGetCustomSet;
