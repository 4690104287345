import { useQuery } from '@tanstack/react-query';
import { getUserNotifications } from '../../../api/apiServices';

const useGetUserNotifications = () => {
  const { data, refetch } = useQuery({
    queryKey: ['userNotifications'],
    queryFn: getUserNotifications,
  });

  return {
    refetch,
    data,
  };
};

export default useGetUserNotifications;
