import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

type Props = {
  countdown: number;
  skipCountdown?: boolean;
};
export const CountdownGameStart = ({ countdown, skipCountdown }: Props) => {
  return (
    <Box
      position={'absolute'}
      left={'50%'}
      top={'20%'}
      width={'fit-content'}
      sx={{ transform: 'translate(-50%, 0%)' }}
    >
      {!skipCountdown && (
        <>
          <CircularProgress
            size={80}
            variant="determinate"
            value={(100 / 3) * (4 - countdown)}
          />
          <Typography
            fontSize={40}
            sx={{ position: 'absolute', top: 8, left: 28 }}
          >
            {countdown}
          </Typography>
        </>
      )}
    </Box>
  );
};
