import React from 'react';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { MathJaxContext } from 'better-react-mathjax';
import { DisplayQuestion } from './DisplayQuestion';
import { DisplayAnswer } from './DisplayAnswer';
import './styles.css';
import { ProperNameShortAnswer } from '../types';

type PropTypes = {
  answer?: string[][];
  correctAnswer: boolean;
  fullQuestion?: string;
  isBuzzed: boolean;
  properNameShortAnswer?: ProperNameShortAnswer;
  question: string;
  questionIndex: number;
  startBuzzerCountdown: boolean;
  timerLimitReached: boolean;
  unrevealedIndex?: number[];
};

export const QuestionAnswer = ({
  answer,
  correctAnswer,
  fullQuestion,
  isBuzzed,
  properNameShortAnswer,
  question,
  questionIndex,
  startBuzzerCountdown,
  timerLimitReached,
  unrevealedIndex,
}: PropTypes) => {
  const theme = useTheme();
  const xsBreakpoint = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <MathJaxContext>
      <Stack spacing={1} className={isBuzzed ? 'focused-section' : ''}>
        <DisplayQuestion question={question} fullQuestion={fullQuestion} />
        <DisplayAnswer
          answer={answer}
          correctAnswer={correctAnswer}
          properNameShortAnswer={properNameShortAnswer}
          timerLimitReached={timerLimitReached}
          unrevealedIndex={unrevealedIndex}
        />
      </Stack>
    </MathJaxContext>
  );
};
