import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tableCellClasses,
  tooltipClasses,
  useTheme,
} from '@mui/material';
import { Player, Question } from '../../../types';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RemoveIcon from '@mui/icons-material/Remove';
import StarIcon from '@mui/icons-material/Star';
import { IconButton } from '@mui/material';
import { getAvatarSrc } from '../../../../../api/cloudinary';
import { TopScorer } from './types';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    paddingLeft: 5,
    borderLeft: `1px solid #4c4e50`,
  },
}));

const PaddedLeftTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    paddingLeft: 10,
  },
}));

const NoPaddedLeftTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    paddingLeft: 0,
  },
}));

type PropTypes = {
  loading: boolean;
  medianScores: Record<number, number>;
  players: Record<string, Player>;
  profileId?: string;
  questions: Question[];
  topScores: Record<string, TopScorer[]>;
};

export const LeaderboardQuestions = ({
  loading,
  medianScores,
  players,
  profileId,
  questions,
  topScores,
}: PropTypes) => {
  const theme = useTheme();
  const [anchorTopScoresEl, setAnchorTopScoresEl] =
    useState<HTMLButtonElement | null>(null);
  const [clickedTopScoresQuestionIndex, setClickedTopScoresQuestionIndex] =
    useState<number>(null);
  const [clickedSelfScoreQuestionIndex, setClickedSelfScoreQuestionIndex] =
    useState<number>(null);

  const handleTopScoresClick = (event: any, questionIndex: number) => {
    setAnchorTopScoresEl(event.currentTarget);
    setClickedSelfScoreQuestionIndex(null);
    setClickedTopScoresQuestionIndex(questionIndex);
  };

  const handleTopScoresClose = () => {
    setAnchorTopScoresEl(null);
  };

  const handleSelfScoreClick = (event: any, questionIndex: number) => {
    setAnchorTopScoresEl(event.currentTarget);
    setClickedTopScoresQuestionIndex(null);
    setClickedSelfScoreQuestionIndex(questionIndex);
  };

  const openTopScores = Boolean(anchorTopScoresEl);

  const getMedianScore = (index: number) => {
    if (!medianScores) return null;
    return `${medianScores[index] > 0 ? '+' : ''}${medianScores[index]}`;
  };

  const TopScoresTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      // backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const getTopScores = (index: number) => {
    if (!topScores) return null;
    const topScorers = topScores[index];
    if (!topScorers) return '--';
    return (
      <>
        {topScorers.length > 3 ? (
          <Stack direction="row" spacing={1}>
            <Typography
              variant="subtitle2"
              textAlign={'right'}
              paddingRight={1}
              width={'30px'}
              alignSelf={'center'}
            >
              {topScorers[0]?.score > 0 ? '+' : ''}
              {topScorers[0]?.score}
            </Typography>
            <Chip
              label={`${topScorers.length} players`}
              variant="outlined"
              size="small"
              color="primary"
              clickable={true}
              onClick={(e) => handleTopScoresClick(e, index)}
            />
          </Stack>
        ) : (
          <Stack direction="row" spacing={1}>
            {topScorers.length ? (
              <Typography
                variant="subtitle2"
                textAlign={'right'}
                paddingRight={1}
                width={'30px'}
                alignSelf={'center'}
              >
                {topScorers[0]?.score > 0 ? '+' : ''}
                {topScorers[0]?.score}
              </Typography>
            ) : null}
            {topScorers.map((u, i) => (
              <IconButton
                key={i}
                sx={{ padding: 0 }}
                onClick={(e) => handleTopScoresClick(e, index)}
              >
                <Avatar
                  src={getAvatarSrc(u.avatarPublicId, u.avatarUrl, 50, 50)}
                  variant="rounded"
                  sx={{
                    height: 25,
                    width: 25,
                  }}
                />
              </IconButton>
            ))}
          </Stack>
        )}
      </>
    );
  };

  const bestScoreTooltipList = (index: number) => {
    if (!topScores) return;
    const topScorers = topScores[index];
    if (!topScorers) return;
    return (
      <Stack spacing={1} padding={1}>
        {topScorers.map((u, i) => (
          <Stack key={i} direction="row" spacing={1}>
            <Avatar
              src={getAvatarSrc(u.avatarPublicId, u.avatarUrl, 50, 50)}
              variant="rounded"
              sx={{
                height: 25,
                width: 25,
              }}
            />
            <Typography
              variant="subtitle1"
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: '130px',
                color:
                  theme.palette.mode === 'light'
                    ? theme.palette.secondary.main
                    : theme.palette.text.primary,
                textShadow:
                  theme.palette.mode === 'dark'
                    ? `0 0 1px ${theme.palette.secondary.main}, 10px 0px 20px ${theme.palette.secondary.main}, 0px 0px 16px ${theme.palette.secondary.main}`
                    : 'none',
              }}
            >
              {u.username}
            </Typography>
            <Typography variant="subtitle1" textAlign="center">
              {u.score > 0 ? '+' : ''}
              {u.score}
            </Typography>
          </Stack>
        ))}
      </Stack>
    );
  };

  const getPlayerScore = (index: number) => {
    if (!players || !players[profileId]) return '--';
    const roundScore = players[profileId].roundScores[index];
    if (!roundScore) return '--';
    const score =
      roundScore.score +
      (roundScore.penalty || 0) +
      (roundScore.fuzzyPenalty || 0);
    return `${score > 0 ? '+' : ''}${score}`;
  };

  const removeIcon = (
    <RemoveIcon
      sx={{
        fontSize: 20,
        display: 'flex',
        alignSelf: 'center',
        color: 'gray',
      }}
    />
  );

  const getArrowIcon = (index: number) => {
    if (!medianScores || !players || !players[profileId]) return removeIcon;
    const roundScore = players[profileId].roundScores[index];
    const medianScore = medianScores[index];
    const score =
      roundScore.score + roundScore.penalty + (roundScore.fuzzyPenalty || 0);
    const topScorers = topScores[index];
    if (score === topScorers[0]?.score) {
      return (
        <StarIcon
          sx={{
            fontSize: 20,
            display: 'flex',
            alignSelf: 'center',
            color: 'yellow',
          }}
        />
      );
    }
    if (score === medianScore) {
      return removeIcon;
    }
    if (score < medianScore) {
      return (
        <KeyboardArrowDownIcon
          sx={{
            fontSize: 20,
            display: 'flex',
            alignSelf: 'center',
            color: theme.palette.success.main,
          }}
        />
      );
    }
    if (score > medianScore) {
      return (
        <KeyboardArrowUpIcon
          sx={{
            fontSize: 20,
            display: 'flex',
            alignSelf: 'center',
            color: theme.palette.error.main,
          }}
        />
      );
    }
    return removeIcon;
  };

  const popoverContent = () => {
    if (clickedSelfScoreQuestionIndex !== null) {
      return (
        <Stack spacing={1} sx={{ padding: 1 }}>
          <Typography variant="subtitle2">
            {questions[clickedSelfScoreQuestionIndex].question}
          </Typography>
          <Typography
            textAlign={'right'}
            sx={{ color: theme.palette.dailies.main }}
          >
            {questions[clickedSelfScoreQuestionIndex].answer}
          </Typography>
        </Stack>
      );
    }
    if (clickedTopScoresQuestionIndex !== null) {
      return bestScoreTooltipList(clickedTopScoresQuestionIndex);
    }
  };

  return (
    <Box mt={2}>
      <Popover
        open={openTopScores}
        anchorEl={anchorTopScoresEl}
        onClose={handleTopScoresClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          '.MuiPopover-paper': {
            backgroundColor: 'black',
            border: '1px solid',
            borderColor: theme.palette.primary.main,
          },
        }}
      >
        {popoverContent()}
      </Popover>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">#</Typography>
              </TableCell>
              <NoPaddedLeftTableCell width={'5%'}>
                <Typography variant="subtitle2" textAlign={'right'}>
                  Med
                </Typography>
              </NoPaddedLeftTableCell>
              <PaddedLeftTableCell width={'60%'}>
                <Typography variant="subtitle2">Top Scores</Typography>
              </PaddedLeftTableCell>
              <StyledTableCell width={'2%'} colSpan={2}>
                <Typography textAlign={'center'} variant="subtitle2">
                  You
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography variant="subtitle2" textAlign={'center'}>
                    Loading stats...
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              Object.values(medianScores)?.map((q, i) => (
                <TableRow hover key={i}>
                  <TableCell>
                    <Stack direction={'row'} spacing={2}>
                      <Typography variant="subtitle2">{+i + 1}.</Typography>
                    </Stack>
                  </TableCell>

                  <NoPaddedLeftTableCell>
                    <Typography variant="subtitle2" textAlign={'right'}>
                      {getMedianScore(i)}
                    </Typography>
                  </NoPaddedLeftTableCell>
                  <PaddedLeftTableCell>{getTopScores(i)}</PaddedLeftTableCell>
                  <StyledTableCell>
                    <Button
                      sx={{ padding: 0 }}
                      onClick={(e) => handleSelfScoreClick(e, i)}
                    >
                      <Stack
                        direction="row"
                        width={'100%'}
                        spacing={1}
                        justifyContent={'space-between'}
                      >
                        <Typography variant="subtitle2">
                          {getPlayerScore(i)}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          {getArrowIcon(i)}
                        </Typography>
                      </Stack>
                    </Button>
                  </StyledTableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
