import React from 'react';
import { Box, Typography } from '@mui/material';

export const PANICKY_FUZZY_SCORE = 0.76;
export const PASSABLE_FUZZY_SCORE = 0.84;
export const POISED_FUZZY_SCORE = 0.92;

export const Putts = ({
  fontSize,
  rotate,
  text,
}: {
  fontSize?: number;
  rotate?: string;
  text: string;
}) => {
  return (
    <Typography
      color={'#64FF00'}
      fontSize={fontSize ?? 16}
      sx={{ rotate: rotate ?? null }}
    >
      {text}
    </Typography>
  );
};

export const Panicky = ({
  fontSize,
  rotate,
  text,
}: {
  fontSize?: number;
  rotate?: string;
  text: string;
}) => {
  return (
    <Box
      border={'1px solid #ffa500'}
      borderRadius={4}
      px={0.5}
      sx={{ rotate: rotate ?? null }}
    >
      <Typography
        fontFamily={'schoolbell'}
        fontSize={fontSize ? fontSize + 2 : 16}
      >
        {text}
      </Typography>
    </Box>
  );
};

export const Passable = ({
  fontSize,
  rotate,
  text,
}: {
  fontSize?: number;
  rotate?: string;
  text: string;
}) => {
  return (
    <Box border={'1px solid #5f9ea0'} sx={{ rotate: rotate ?? null }}>
      <Typography
        border={'1px solid #5f9ea0'}
        color={'#5f9ea0'}
        fontFamily={'round-pixel'}
        fontSize={fontSize ?? 16}
        m={'2px'}
        paddingLeft={0.5}
      >
        {text}
      </Typography>
    </Box>
  );
};

export const Poised = ({
  fontSize,
  rotate,
  text,
}: {
  fontSize?: number;
  rotate?: string;
  text: string;
}) => {
  return (
    <Typography
      color={'#ff0000'}
      fontFamily={'barbatrick'}
      fontSize={fontSize ? fontSize + 4 : 16}
      paddingX={0.5}
      sx={{ rotate: rotate ?? null }}
    >
      {text}
    </Typography>
  );
};

export const Pristine = ({
  fontSize,
  rotate,
  text,
}: {
  fontSize?: number;
  rotate?: string;
  text: string;
}) => {
  return (
    <Box
      bgcolor={'#d3d3d3'}
      borderRadius={2}
      boxShadow={`
        inset 0 0 50px #e0e0e0,      /* inner white */
        inset 20px 0 80px #f0f,   /* inner left magenta short */
        inset -20px 0 80px #0ff,  /* inner right cyan short */
        inset 20px 0 300px #f0f,  /* inner left magenta broad */
        inset -20px 0 300px #0ff, /* inner right cyan broad */
        0 0 10px #e0e0e0,            /* outer white */
        -5px 0 40px #f0f,        /* outer left magenta */
        5px 0 40px #0ff;         /* outer right cyan */
      `}
      sx={{ rotate: rotate ?? null }}
    >
      <Typography
        color={'#006400'}
        fontFamily={'blacksword'}
        fontSize={fontSize ?? 16}
        fontWeight={600}
        letterSpacing={4}
        padding={0.5}
      >
        {text}
      </Typography>
    </Box>
  );
};
