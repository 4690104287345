import React from 'react';
import { Stack, Typography, styled } from '@mui/material';
import { DisplayScore } from './DisplayScore';

const AnswerText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: 18,
  color: theme.palette.error.main,
  borderBottom: `1px solid ${theme.palette.error.main}`,
}));

type Props = {
  currentScore: number;
  inputValue: string;
};

export const IncorrectAnswer = ({ currentScore, inputValue }: Props) => {
  return (
    <Stack alignItems={'center'} justifyContent={'center'}>
      <AnswerText>
        {inputValue || inputValue !== '' ? inputValue : ' '}
      </AnswerText>

      <Stack
        direction={'row'}
        spacing={2}
        alignItems={'center'}
        marginTop={!inputValue ? '19px' : '-9px'} // based on Input height of 31 (after removing input padding 31 is what remained)
      >
        <Typography color={'error.main'} variant="caption">
          Time is up!
        </Typography>
        <DisplayScore score={currentScore} type="error" />
      </Stack>
    </Stack>
  );
};
