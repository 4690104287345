import { Player } from './pages/game/types';

export type LocalStorageGameData = {
  countdown: number;
  gameClock: number;
  gameId: string;
  gameOver: boolean;
  gameStarted: boolean;
  players: Record<string, Player>;
  questionIndex: number;
  updatedAt: number;
};

export const LOCAL_STORAGE_DATA = {
  PROFILE_ID: 'par_profile_id',
};

const getLocalStorageItem = (itemName: string) =>
  localStorage.getItem(itemName);

const setLocalStorageItem = (itemName: string, itemValue: string) =>
  localStorage.setItem(itemName, itemValue);

export const getParsedLocalStorageItem = (itemName: string) => {
  const lsItem = getLocalStorageItem(itemName);
  try {
    return JSON.parse(lsItem);
  } catch {
    return lsItem;
  }
};

export const setStringifiedLocalStorageItem = (
  itemName: string,
  itemValue: any
) => {
  let stringifiedItemValue = itemValue;
  if (typeof itemValue !== 'string') {
    stringifiedItemValue = JSON.stringify(itemValue);
  }
  setLocalStorageItem(itemName, stringifiedItemValue);
};
