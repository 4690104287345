import React from 'react';
import { AppBar, Toolbar, Stack, Paper, Button, Box } from '@mui/material';
import { Profile } from '../../types/types';
import { ProfileMenu } from './profile-menu';
import { useLocation, useNavigate } from 'react-router-dom';
import { FlickeringBeta } from './FlickeringBeta';

type PropTypes = {
  clearProfile: () => void;
  profile: Profile;
};

export const Header = ({ profile, clearProfile }: PropTypes) => {
  const location = useLocation();
  const navigate = useNavigate();

  // if a game is in session then do not display any headers
  if (location.pathname === '/game') {
    return <></>;
  }

  const handleLoginClick = () => {
    navigate('/login');
  };

  const onLogoClick = () => {
    navigate('/');
  };

  return (
    <header>
      <AppBar position="fixed" component="nav" color="transparent">
        <Paper elevation={0} square>
          <Toolbar>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              width={'100%'}
            >
              <div
                onClick={onLogoClick}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  position: 'relative',
                }}
              >
                <img
                  alt="PAR"
                  src={'/logo-blue-transparent.png'}
                  style={{ maxWidth: '100px' }}
                ></img>
                <FlickeringBeta />
              </div>

              {profile ? (
                <ProfileMenu onLogoutSuccess={clearProfile} profile={profile} />
              ) : (
                <Button variant="outlined" onClick={handleLoginClick}>
                  Login
                </Button>
              )}
            </Stack>
          </Toolbar>
        </Paper>
      </AppBar>
    </header>
  );
};
