import { useQuery } from '@tanstack/react-query';
import { getAuthVerify } from '../../../api/apiServices';

const useAuthVerify = (token: string) => {
  const { data, error, isPending, refetch } = useQuery({
    queryKey: ['authVerify', { token }],
    queryFn: getAuthVerify,
    enabled: !!token,
  });

  return {
    data,
    error,
    isPending,
    refetch,
  };
};

export default useAuthVerify;
