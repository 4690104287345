export const getTagColor = (tag: string) => {
  switch (tag) {
    case 'General':
      return 'info.main';
    case 'Sports':
      return 'sports.main';
    case 'Movies':
      return 'dailies.main';
    case 'Wordplay':
      return 'logoPink.main';
    case 'Theme':
      return 'creamOrange.main';
    default:
      return 'primary.main';
  }
};
