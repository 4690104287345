import { Game } from '../../pages/game/types';
import {
  DELAY_BEFORE_QUESTION_REVEAL_COUNT,
  DISPLAY_BOXES_COUNT,
  GAME_STAGES,
  NEXT_QUESTION_DELAY_COUNT,
  REVEAL_TIME_LIMIT,
} from '../constants';
import { gameEvents } from '../event-emitter';
import {
  allPlayersAnsweredCorrectly,
  updatePlayerStatesBeforeNextQuestion,
} from '../game-input/player-input';
import {
  collapseWords,
  create2dAnswerArray,
  create2dRevealedLettersArray,
  createAlternateAnswersIfPrompted,
  findIndexOf2dArray,
  randomIntFromInterval,
  removeFirstWordArticles,
  roundToOneDecimal,
} from './utils';

export const resetStatesForNextQuestion = async (game: Game) => {
  const questionIndex = game.questionIndex;
  game.revealQuestion = null;
  game.revealAnswer = null;
  gameEvents.emit('revealAnswer', game.revealAnswer);
  game.revealedLetters = [];
  game.unrevealedIndex = null;
  gameEvents.emit('unrevealedIndex', game.unrevealedIndex);
  game.questionData = null;
  game.timerLimitReached = false;
  gameEvents.emit('timerLimitReached', game.timerLimitReached);
  game.gameClock = 0;
  gameEvents.emit('gameClock', game.gameClock);
  game.gameClockTenths = 0;
  game.questionWordIndex = 0;
  game.revealBoxesCount = DISPLAY_BOXES_COUNT;
  game.nextQuestionDelayCount = NEXT_QUESTION_DELAY_COUNT;
  game.delayBeforeQuestionRevealCount = DELAY_BEFORE_QUESTION_REVEAL_COUNT;
  game.answerLetters = [];
  game.multipleChoiceAnswers = null;
  game.questionWords = [];
  game.unrevealedProperNameIndex = null;
  game.answerLettersRevealOrderCount = 0;
  game.buzzerAttemptsRemaining = 3;
  const nextQuestionIndex = questionIndex + 1;
  const questionsCount = game.questionsCount;
  if (noRemainingQuestions(nextQuestionIndex, questionsCount)) {
    endGame(game);
    return;
  }
  await setupNextQuestion(game, nextQuestionIndex);
  updatePlayerStatesBeforeNextQuestion(game);
  game.gameStage = GAME_STAGES.DELAY_BEFORE_QUESTION_REVEAL;
};

const noRemainingQuestions = (
  nextQuestionIndex: number,
  questionsCount: number
) => nextQuestionIndex === questionsCount;

export const endGame = (game: Game) => {
  game.gameOver = true;
  gameEvents.emit('gameOver', true);
};

export const setupNextQuestion = async (game: Game, questionIndex: number) => {
  game.questionIndex = questionIndex;
  gameEvents.emit('questionIndex', questionIndex);

  game.questionData = game.questionSet[questionIndex];

  const {
    alternateAnswers,
    answer,
    category,
    multipleChoiceAnswers,
    par,
    properName,
    properNameShortAnswer,
    question,
    revealAnswerAtSecond,
    type,
  } = game.questionData;
  game.type = type;
  gameEvents.emit('type', type);
  const questionSetCount = game.questionsCount;
  game.questionMetadata = {
    category,
    par,
    questionSetCount,
  };
  game.revealAnswerAtSecond = revealAnswerAtSecond;
  gameEvents.emit('questionMetadata', game.questionMetadata);
  const collapsedAnswer = collapseWords(removeFirstWordArticles(answer));
  game.collapsedAnswer = collapsedAnswer;
  const answerArray = answer.toUpperCase().split('');
  const answerLetters = create2dAnswerArray(answerArray);
  game.answerLetters = answerLetters;
  const answerLength = answer.replace(/\s/g, '').length;
  const revealTiming = roundToOneDecimal(REVEAL_TIME_LIMIT / answerLength);
  game.revealTiming = revealTiming;
  const questionWords = question.split(' ');
  game.questionWords = questionWords;
  const revealedLetters = create2dRevealedLettersArray(answerArray);
  game.revealedLetters = revealedLetters;
  game.multipleChoiceAnswers = multipleChoiceAnswers;
  // console.log('answer', answer, alternateAnswers);
  if (properName) {
    game.properNameShortAnswer = properNameShortAnswer;
    gameEvents.emit('properNameShortAnswer', properNameShortAnswer);
    // add properNameShortAnswer to acceptable answers
    const answerWords = answer.toUpperCase().split(' ');
    let properNameAlternateAnswer;
    if (properNameShortAnswer === 'last word') {
      properNameAlternateAnswer = answerWords[answerWords.length - 1];
    }
    if (properNameShortAnswer === 'last two words') {
      const lastTwoWords = answerWords[answerWords.length - 2].concat(
        answerWords[answerWords.length - 1]
      );
      properNameAlternateAnswer = collapseWords(lastTwoWords);
    }
    if (properNameShortAnswer === 'first word') {
      properNameAlternateAnswer = answerWords[0];
    }
    if (properNameShortAnswer === 'first two words') {
      const firstTwoWords = answerWords[0].concat(answerWords[1]);
      properNameAlternateAnswer = collapseWords(firstTwoWords);
    }
    if (properNameAlternateAnswer) {
      alternateAnswers.push(properNameAlternateAnswer);
      game.questionData.alternateAnswers = alternateAnswers;
    }

    // if proper name, aka last word only, etc
    // we dont want to reveal the entire last word
    // so prevent the unrevealed index from being on the last word
    const selectUnrevealedProperNameIndex = randomIntFromInterval(
      0,
      answerWords[answerWords.length - 1].length
    );
    game.unrevealedProperNameIndex = [
      answerWords.length - 1,
      selectUnrevealedProperNameIndex,
    ];
  }
  const promptAnswers = game.questionData.promptAnswer;
  if (promptAnswers && promptAnswers.filter((a) => !!a).length > 0) {
    const alternateAnswersIfPrompted = createAlternateAnswersIfPrompted(
      promptAnswers,
      answer,
      alternateAnswers
    );
    game.questionData.alternateAnswersIfPrompted = alternateAnswersIfPrompted;
    const collapsedAlternateAnswersIfPrompted: Record<string, string[]> = {};
    for (const key of Object.keys(alternateAnswersIfPrompted)) {
      if (!key) continue;
      collapsedAlternateAnswersIfPrompted[
        collapseWords(removeFirstWordArticles(key.toUpperCase()))
      ] = alternateAnswersIfPrompted[key].map((a) => {
        if (a) return collapseWords(removeFirstWordArticles(a.toUpperCase()));
      });
    }
    game.collapsedAlternateAnswersIfPrompted =
      collapsedAlternateAnswersIfPrompted;
  }
  const collapsedAlternateAnswers = alternateAnswers.map((a) => {
    if (a) return collapseWords(removeFirstWordArticles(a.toUpperCase()));
  });
  game.collapsedAlternateAnswers = collapsedAlternateAnswers;
};

export const revealPredeterminedAnswerLetterOrder = (game: Game) => {
  const answerLettersRevealOrder = game.answerLettersRevealOrder;
  const questionIndex = game.questionIndex;
  const answerLetterRevealOrderCount = game.answerLettersRevealOrderCount;
  const nextLetterRevealLocation = findIndexOf2dArray(
    answerLettersRevealOrder[questionIndex],
    answerLetterRevealOrderCount
  ) as [number, number];
  const revealedLetters = game.revealedLetters;
  const answerLetters = game.answerLetters;
  if (!nextLetterRevealLocation) {
    const unrevealedIndex = findIndexOf2dArray(revealedLetters, '');
    game.unrevealedIndex = unrevealedIndex;
    gameEvents.emit('unrevealedIndex', unrevealedIndex);
    game.gameStage = GAME_STAGES.WAIT_UNTIL_TIME_LIMIT;
    return;
  }
  if (answerLetters.length <= nextLetterRevealLocation[0]) {
    console.log(
      'REVIEW POSSIBLE BUG: revealPredeterminedAnswerLetterOrder - 0',
      answerLetters,
      nextLetterRevealLocation
    );
    return;
  }
  if (
    answerLetters[nextLetterRevealLocation[0]].length <=
    nextLetterRevealLocation[1]
  ) {
    console.log(
      'REVIEW POSSIBLE BUG: revealPredeterminedAnswerLetterOrder - 1',
      answerLetters,
      nextLetterRevealLocation
    );
    return;
  }
  revealedLetters[nextLetterRevealLocation[0]][nextLetterRevealLocation[1]] =
    answerLetters[nextLetterRevealLocation[0]][nextLetterRevealLocation[1]];
  game.revealedLetters = revealedLetters;
  game.answerLettersRevealOrderCount = answerLetterRevealOrderCount + 1;
  game.revealAnswer = revealedLetters;
  gameEvents.emit('revealAnswer', [...revealedLetters]);
};

export const simulateGhostPlayer = async (
  game: Game,
  updatedGameClockTenths: number
) => {
  const ghostPlayerProfileId = Object.keys(game.players)[0];
  const ghostPlayer = game.players[ghostPlayerProfileId];
  const questionIndex = game.questionIndex;
  const { answerAttempts, timerLimitReached } =
    ghostPlayer.roundScores[questionIndex];
  let answerSubmittedSecondsOneDecimal;
  if (answerAttempts && answerAttempts.length > 0 && !timerLimitReached) {
    const answerSubmittedSeconds =
      answerAttempts[answerAttempts.length - 1].gameClock;
    answerSubmittedSecondsOneDecimal = roundToOneDecimal(
      answerSubmittedSeconds
    );
  }
  const score = ghostPlayer.roundScores[questionIndex].score;
  const penalty = ghostPlayer.roundScores[questionIndex].penalty;
  const fuzzyPenalty = ghostPlayer.roundScores[questionIndex].fuzzyPenalty;
  // timeouts are not handled here, the last gameclock used in this function is 19.9.
  // timeouts are handled in the handleGameClock function in update-game.ts
  if (
    answerSubmittedSecondsOneDecimal !== undefined &&
    updatedGameClockTenths === answerSubmittedSecondsOneDecimal
  ) {
    const parScore = ghostPlayer.parScore;
    const correctAnswer = updatedGameClockTenths === 20 ? false : true;
    ghostPlayer.parScore = parScore + score + penalty + (fuzzyPenalty || 0);
    ghostPlayer.correctAnswer = correctAnswer;
    ghostPlayer.incorrectAnswer = false;
    ghostPlayer.currentScore = score;
    ghostPlayer.timerLimitReached = !correctAnswer;
    if (penalty) {
      const totalPenalty = ghostPlayer.totalPenalty;
      ghostPlayer.totalPenalty = totalPenalty + penalty;
    }

    gameEvents.emit('opponent', ghostPlayer);
    if (allPlayersAnsweredCorrectly(game)) {
      game.allPlayersAnsweredCorrectly = true;
    }
  }
};
