import {
  Stack,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import React, { useState } from 'react';
import PeopleIcon from '@mui/icons-material/People';
import ListAltIcon from '@mui/icons-material/ListAlt';

import { Player, Question } from '../../types';
import Leaderboard from '../ResultsDialog/Leaderboard/Leaderboard';
import { CustomSetLeaderboard } from '../ResultsDialog/types';
import InsightStats from './InsightStats';
import { CustomSet } from '../../../../types/custom-sets';

type Props = {
  customSet: CustomSet;
  customSetLeaderboard: CustomSetLeaderboard;
  customSetLeaderboardIsPending: boolean;
  players: Record<string, Player>;
  profileId: string;
  questions: Question[];
  title: string;
};

const Insights = ({
  customSet,
  customSetLeaderboard,
  customSetLeaderboardIsPending,
  players,
  profileId,
  questions,
  title,
}: Props) => {
  const [toggle, setToggle] = useState<'questions' | 'leaderboard'>(
    'questions'
  );

  return (
    <Stack sx={{ px: 2 }}>
      <Stack>
        <Typography textAlign={'center'}>"{title}"</Typography>
        <ToggleButtonGroup
          value={toggle}
          exclusive
          onChange={(_, value) => setToggle(value)}
          sx={{
            height: 30,
            mt: 1,
            mb: 1,
          }}
          fullWidth
        >
          <ToggleButton value="questions">
            <ListAltIcon sx={{ pr: 1 }} />
            Questions
          </ToggleButton>
          <ToggleButton value="leaderboard">
            <PeopleIcon sx={{ pr: 1 }} />
            Leaderboard
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      {toggle === 'questions' ? (
        <InsightStats
          questions={questions}
          timerLimit={customSet.configs.timerLimit}
        />
      ) : toggle === 'leaderboard' ? (
        <Leaderboard
          isGuest={false}
          leaderboard={customSetLeaderboard?.leaderboard}
          loading={customSetLeaderboardIsPending}
          medianScores={customSetLeaderboard?.medianScores}
          players={players}
          profileId={profileId}
          questions={questions}
          topScores={customSetLeaderboard?.topScores}
        />
      ) : null}
    </Stack>
  );
};

export default Insights;
