import { useQuery } from '@tanstack/react-query';
import { getCustomSetsDrafts } from '../../../api/apiServices';

const useGetCustomSetsDrafts = (profileId: string) => {
  const { data, isPending, refetch } = useQuery({
    queryKey: ['customSetsDrafts', { profileId }],
    queryFn: getCustomSetsDrafts,
    enabled: !!profileId,
  });

  return {
    data,
    isPending,
    refetch,
  };
};

export default useGetCustomSetsDrafts;
