import { useMutation } from '@tanstack/react-query';
import { postAuthSignup } from '../../../api/apiServices';

const useAuthSignup = (
  handleOnSuccess: () => void,
  handleOnError: (error: any) => void
) => {
  const { mutate } = useMutation({
    mutationFn: postAuthSignup,
    onSuccess: handleOnSuccess,
    onError: handleOnError,
  });

  return {
    mutate,
  };
};

export default useAuthSignup;
