import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Question } from '../../../../game/types';
import DraggableList from './DraggableList';
import useUpdateQuestionOrder from './useUpdateQuestionOrder';

type Props = {
  handleUpdateSortSuccess: () => void;
  open: boolean;
  onClose: () => void;
  customSetId: string;
  customSetQuestions: Question[];
};

const DraggableSortDialog = ({
  handleUpdateSortSuccess,
  open,
  onClose,
  customSetId,
  customSetQuestions,
}: Props) => {
  const [sortedQuestions, setSortedQuestions] =
    useState<Question[]>(customSetQuestions);

  useEffect(() => {
    setSortedQuestions(customSetQuestions);
  }, [customSetQuestions]);

  const onUpdateSort = (sortedQuestions: Question[]) => {
    setSortedQuestions(sortedQuestions);
  };

  const { mutate: updateQuestionOrder } = useUpdateQuestionOrder(
    handleUpdateSortSuccess
  );

  const handleSaveOrderClick = () => {
    const questions = sortedQuestions.map((question, index) => ({
      _id: question._id,
      sortOrder: index,
    }));
    updateQuestionOrder({ customSetId, questions });
  };

  return (
    <Dialog open={open} fullScreen>
      <DialogTitle>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 5,
            top: 5,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography>Drag and drop the questions to reorder them</Typography>
          <DraggableList
            onUpdateSort={onUpdateSort}
            sortedQuestions={sortedQuestions}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} fullWidth>
          Cancel
        </Button>
        <Button variant="outlined" onClick={handleSaveOrderClick} fullWidth>
          Save Order
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DraggableSortDialog;
