import React from 'react';
import { CustomSet } from '../../types/custom-sets';
import { Profile } from '../../types/types';
import CustomSetDraftCard from './CustomSetDraftCard';

type Props = {
  customSetsDrafts: CustomSet[];
  handleCardClick: (customSetId: string) => void;
  profile: Profile;
};

const DraftList = ({ customSetsDrafts, handleCardClick, profile }: Props) => {
  return (
    <>
      {customSetsDrafts?.map((customSet) => (
        <CustomSetDraftCard
          key={customSet.customSetId}
          customSet={customSet}
          handleCardClick={() => handleCardClick(customSet.customSetId)}
          profile={profile}
        />
      ))}
    </>
  );
};

export default DraftList;
