import React, { useEffect, useMemo, useState } from 'react';
import GameSession from './GameSession';
import { Profile } from '../../types/types';
import { generateRandomGuestUsername } from '../../utils/utils';
import useUploadCustomGame from './hooks/useUploadGame';
import { games } from '../../game-engine/constants';
import { Box } from '@mui/material';
import BasicLayout from '../BasicLayout';
import useGetLeaderboard from './hooks/useGetLeaderboard';
import ResultsDialog from './dialogs/ResultsDialog/ResultsDialog';
import { useParams } from 'react-router-dom';
import useGetCustomSet from '../hooks/useGetCustomSet';
import { nanoid } from 'nanoid';
import { Header } from '../header';
import BottomNav from '../BottomNav/BottomNav';
import GameInfo from './GameInfo';
import useGetCustomGame from './hooks/useGetCustomGame';
import {
  getParsedLocalStorageItem,
  setStringifiedLocalStorageItem,
} from '../../local-storage';
import ConfirmPlayDialog from './ConfirmPlayDialog';
import { UserNotification } from '../Activity/types';
import InsightsDialog from './dialogs/InsightsDialog/InsightsDialog';
import useUploadGameStarted from './hooks/useUploadGameStarted';
import useUpdateArchiveSet from './hooks/useUpdateArchiveSet';

type Props = {
  clearProfile: () => void;
  mutateProfile: (profile: Profile) => void;
  profile: Profile;
  refetchProfile: () => void;
  unseenNotifications: number;
};

const Game = ({
  clearProfile,
  mutateProfile,
  profile,
  refetchProfile,
  unseenNotifications,
}: Props) => {
  const { customSetId } = useParams();

  const {
    data: customSet,
    isPending: customSetIsPending,
    refetch: refetchCustomSet,
  } = useGetCustomSet(customSetId);
  const [gameStarted, setGameStarted] = useState(false);
  const [gameId, setGameId] = useState<string>();
  const [shouldFetchLeaderboard, setShouldFetchLeaderboard] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [openResultsDialog, setOpenResultsDialog] = useState(false);
  const [openInsightsDialog, setOpenInsightsDialog] = useState(false);
  const [profileId, setProfileId] = useState<string>();
  const [customGame, setCustomGame] = useState(null);
  const [username, setUsername] = useState<string>('Guest');
  const [openConfirmPlayDialog, setOpenConfirmPlayDialog] = useState(false);

  const avatarPublicId = profile ? profile.avatarPublicId : '';
  const avatarUrl = profile ? profile.avatarUrl : '';
  const isCreator = profile?._id === customSet?.creator.profileId;

  const customSetPlayed = profile?.customSetsPlayed.find(
    (set) => set.customSetId === customSetId
  );

  const [customSetWasStartedButNotPlayed, setCustomSetWasStartedButNotPlayed] =
    useState(false);

  const customSetWasPlayed =
    (customSetPlayed && customSetPlayed.score !== null) ||
    customGame ||
    gameOver;

  const customSetRating = profile?.customSetsRatings?.find(
    (rating) => rating.customSetId === customSetId
  )?.rating;

  const { data: customGameData } = useGetCustomGame(
    profile?._id,
    customSetId,
    customSetWasPlayed,
    !profile
  );

  useEffect(() => {
    const lsCustomGame = getParsedLocalStorageItem(customSetId);
    if (lsCustomGame === true) {
      setCustomSetWasStartedButNotPlayed(true);
    } else if (lsCustomGame && !customGameData) {
      setCustomGame(lsCustomGame);
    }

    const lsProfileId = getParsedLocalStorageItem('profileId');
    if (lsProfileId) {
      setProfileId(lsProfileId);
    } else {
      const guestProfileId = generateRandomGuestUsername();
      setStringifiedLocalStorageItem('profileId', guestProfileId);
      setProfileId(guestProfileId);
    }
  }, []);

  useEffect(() => {
    if (profile) {
      setProfileId(profile._id);
      setUsername(profile.username);
    }
  }, [profile]);

  useEffect(() => {
    if (customSetPlayed && customSetPlayed.score === null) {
      setCustomSetWasStartedButNotPlayed(true);
    }
  }, [customSetPlayed]);

  useEffect(() => {
    if (customGameData) {
      setCustomGame(customGameData);
    }
    if (customGameData && !!profile) {
      setShouldFetchLeaderboard(true);
    }
    if (isCreator && !!profile) {
      setShouldFetchLeaderboard(true);
    }
  }, [customGameData, profile, isCreator]);

  const handleOnUploadGameSuccess = () => {
    if (!!profile) {
      setCustomSetWasStartedButNotPlayed(false);
      setShouldFetchLeaderboard(true);
      refetchProfile();
    }
    setOpenResultsDialog(true);
  };

  const { mutate: uploadCustomGame } = useUploadCustomGame(
    handleOnUploadGameSuccess
  );

  const {
    data: customSetLeaderboard,
    isPending: customSetLeaderboardIsPending,
  } = useGetLeaderboard(customSet?.customSetId, shouldFetchLeaderboard);

  const handlePlayClick = () => {
    if (isCreator) {
      setOpenInsightsDialog(true);
    } else if (!customSetWasPlayed && !gameStarted && !gameOver) {
      setOpenConfirmPlayDialog(true);
    } else {
      setOpenResultsDialog(true);
    }
  };

  const { mutate: updateGameStarted } = useUploadGameStarted();

  const handleConfirmPlayClick = () => {
    if (!customSet) return;
    const newGameId = nanoid();
    setGameId(newGameId);
    setGameStarted(true);
    setOpenConfirmPlayDialog(false);

    if (!profile) {
      setStringifiedLocalStorageItem(customSet.customSetId, true);
      return;
    }

    updateGameStarted({
      profileId,
      customSetId: customSet.customSetId,
      version: customSet.version,
    });
    const updatedProfile = {
      ...profile,
      customSetsPlayed: [
        ...profile.customSetsPlayed,
        {
          customSetId: customSet.customSetId,
          playedAt: 1,
          score: null,
          version: customSet.version,
        },
      ],
    };
    mutateProfile(updatedProfile);
  };

  const handleGameOver = async () => {
    setGameOver(true);
    const game = games[gameId];
    game.customSetId = customSet.customSetId;

    setStringifiedLocalStorageItem(customSet.customSetId, game);

    uploadCustomGame({ profileId, game });
  };

  const gameInProgress = gameStarted && !gameOver;

  return (
    <>
      <BasicLayout hasHeader={!gameInProgress} px={2}>
        {!gameInProgress && (
          <Header clearProfile={clearProfile} profile={profile} />
        )}

        {!gameInProgress ? (
          <GameInfo
            customSet={customSet}
            customSetIsPending={customSetIsPending}
            customSetRating={customSetRating}
            customSetWasStartedButNotPlayed={customSetWasStartedButNotPlayed}
            customSetWasPlayed={customSetWasPlayed}
            handlePlayClick={handlePlayClick}
            isCreator={isCreator}
            isGuest={!profile}
            profileId={profileId}
            refetchProfile={refetchProfile}
          />
        ) : gameInProgress && customSet && gameId ? (
          <GameSession
            answerLettersRevealOrder={customSet.answerLetterSeeds}
            avatarPublicId={avatarPublicId}
            avatarUrl={avatarUrl}
            customSet={customSet}
            gameId={gameId}
            handleGameOver={handleGameOver}
            profileId={profileId}
            questions={customSet.questions.sort(
              (a, b) => a.sortOrder - b.sortOrder
            )}
            username={username}
          />
        ) : (
          <Box>Error</Box>
        )}

        {!!profile && !gameInProgress && (
          <BottomNav
            bottomNavValue={0}
            unseenNotifications={unseenNotifications}
          />
        )}
      </BasicLayout>

      {(customSetWasPlayed || gameOver) && customSet && (
        <ResultsDialog
          close={() => setOpenResultsDialog(false)}
          customSetLeaderboard={customSetLeaderboard}
          customSetLeaderboardIsPending={customSetLeaderboardIsPending}
          isGuest={!profile}
          open={openResultsDialog}
          players={games[gameId]?.players || customGame?.players}
          profileId={profileId}
          questions={customSet.questions}
          title={customSet.title}
        />
      )}

      {isCreator && customSet && (
        <InsightsDialog
          close={() => setOpenInsightsDialog(false)}
          customSet={customSet}
          customSetLeaderboard={customSetLeaderboard}
          customSetLeaderboardIsPending={customSetLeaderboardIsPending}
          players={customGame?.players}
          profileId={profileId}
          questions={customSet.questions}
          title={customSet.title}
          open={openInsightsDialog}
        />
      )}

      <ConfirmPlayDialog
        confirmAction={handleConfirmPlayClick}
        handleClearUsernameClick={() => setUsername('')}
        handleUsernameChange={(e) => setUsername(e.target.value)}
        isGuest={!profile}
        onClose={() => setOpenConfirmPlayDialog(false)}
        open={openConfirmPlayDialog}
        title={customSet?.title || ''}
        username={username}
      />
    </>
  );
};

export default Game;
