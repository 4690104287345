import React from 'react';
import { Question } from '../../types';
import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import QuestionBarChart from './QuestionStatsBarChart';

type Props = {
  questions: Question[];
  timerLimit: number;
};
const InsightStats = ({ questions, timerLimit }: Props) => {
  const theme = useTheme();

  return (
    <Stack spacing={2}>
      {questions.map((question, index) => (
        <>
          <Stack key={index}>
            <Typography variant="body2">
              {index + 1}. {question.question}
            </Typography>

            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography
                pt={1}
                variant="body2"
                sx={{
                  color: theme.palette.dailies.main,
                }}
              >
                {question.answer}
              </Typography>

              <Typography
                pt={1}
                variant="body2"
                sx={{
                  color: theme.palette.primary.main,
                }}
              >
                PAR: {question.par}
              </Typography>
            </Stack>

            <QuestionBarChart
              par={question.par}
              questionScores={question.scores}
              timerLimit={timerLimit}
            />
          </Stack>

          <Divider />
        </>
      ))}
    </Stack>
  );
};

export default InsightStats;
