import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type Props = {
  confirmAction: () => void;
  handleClearUsernameClick: () => void;
  handleUsernameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isGuest: boolean;
  onClose: () => void;
  open: boolean;
  title: string;
  username: string;
};

const ConfirmPlayDialog = ({
  confirmAction,
  handleClearUsernameClick,
  handleUsernameChange,
  isGuest,
  onClose,
  open,
  title,
  username,
}: Props) => {
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle variant="body1" textAlign={'center'}>
        "{title}"
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          {isGuest && (
            <Stack spacing={4}>
              <Typography variant="caption">
                Login or register to track your stats and history in your
                profile. You can also play without an account.
              </Typography>

              <FormControl variant="outlined">
                <InputLabel htmlFor="guest-name">Guest Name</InputLabel>
                <OutlinedInput
                  id="guest-name"
                  type={'text'}
                  value={username}
                  onChange={handleUsernameChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={handleClearUsernameClick} edge="end">
                        {<HighlightOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Guest Name"
                />
                <FormHelperText>Min 3 characters</FormHelperText>
              </FormControl>
            </Stack>
          )}

          {!isGuest && (
            <Stack>
              <Button
                onClick={() => setShowDisclaimer((prev) => !prev)}
                endIcon={
                  showDisclaimer ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                }
                color="creamOrange"
                size="small"
              >
                Disclaimer
              </Button>
              {showDisclaimer && (
                <Typography variant="caption">
                  Refreshing, going back, closing the browser, or other
                  interuptions will result in an abandon. Abandoned games cannot
                  be resumed.
                </Typography>
              )}
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} fullWidth>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={confirmAction}
          fullWidth
          disabled={isGuest && username.length < 3}
        >
          Play
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmPlayDialog;
