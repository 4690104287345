// eventEmitter.ts

type Callback<T = any> = (data: T) => void;

class EventEmitter {
  private events: { [key: string]: Array<Callback> } = {};

  on<T>(eventName: string, callback: Callback<T>) {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }
    this.events[eventName].push(callback);
  }

  off(eventName: string, callback: Callback) {
    if (!this.events[eventName]) return;
    this.events[eventName] = this.events[eventName].filter(
      (cb) => cb !== callback
    );
  }

  emit<T>(eventName: string, data: T) {
    if (!this.events[eventName]) return;
    this.events[eventName].forEach((callback) => callback(data));
  }
}

export const gameEvents = new EventEmitter();
