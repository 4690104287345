import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Player, Question } from '../../../types';
import { LeaderboardQuestions } from './LeaderboardQuestions';
import { LeaderboardScores } from './LeaderboardScores';
import { TopScorer } from './types';

type PropTypes = {
  isGuest: boolean;
  leaderboard: Player[];
  loading: boolean;
  medianScores: Record<number, number>;
  players: Record<string, Player>;
  profileId: string;
  questions: Question[];
  topScores: Record<number, TopScorer[]>;
};

const Leaderboard = ({
  isGuest,
  leaderboard,
  loading,
  medianScores,
  players,
  profileId,
  questions,
  topScores,
}: PropTypes) => {
  return (
    <Stack>
      {isGuest ? (
        <Typography variant="caption" textAlign={'center'}>
          Sign in to view stats and the leaderboard
        </Typography>
      ) : (
        <Stack spacing={2} mt={1}>
          <Stack spacing={1}>
            <Typography
              variant="body2"
              textAlign={'center'}
              letterSpacing={5}
              sx={{
                textShadow: `#ffffff 0px 0px 0px, #ffffff 0px 0px 0px, #ffffff 0px 0px 16px`,
              }}
            >
              STATS
            </Typography>
            <Typography variant="caption" textAlign={'center'}>
              Click in "Top Scores" or "You" for details
            </Typography>
            <LeaderboardQuestions
              loading={loading}
              medianScores={medianScores}
              players={players}
              profileId={profileId}
              questions={questions}
              topScores={topScores}
            />
          </Stack>
          <Stack>
            <Typography
              variant="body2"
              textAlign={'center'}
              letterSpacing={5}
              sx={{
                textShadow: `#ffffff 0px 0px 0px, #ffffff 0px 0px 0px, #ffffff 0px 0px 16px`,
              }}
            >
              LEADERS
            </Typography>
            <LeaderboardScores
              leaderboard={leaderboard}
              loading={loading}
              profileId={profileId}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default Leaderboard;
