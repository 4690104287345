import React, { RefObject, useEffect, useRef, useState } from 'react';
import {
  Box,
  Fade,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { collapseWords } from '../../game-engine/game-loop/utils';
import { playerInput } from '../../game-engine/game-input/player-input';
import { games } from '../../game-engine/constants';
import { InputPenaltyLock } from './InputPenaltyLock';
import { Player } from './types';

type PropTypes = {
  buzzerCounter: number;
  gameId: string;
  isBuzzed: boolean;
  profileId: string;
  inputLabelText?: string;
  inputLabelType?: 'try-again' | 'be-more-specific';
  inputValue: string;
  locked?: boolean;
  player: Player;
  questionIndex: number;
  setIsInputFocused: (isFocused: boolean) => void;
  setInputValue: (value: string) => void;
  submittedPromptAnswer: string;
  startBuzzerCountdown: boolean;
};

export const Inputter = ({
  buzzerCounter,
  gameId,
  isBuzzed,
  profileId,
  inputLabelText,
  inputLabelType,
  inputValue,
  locked,
  player,
  questionIndex,
  setIsInputFocused,
  setInputValue,
  submittedPromptAnswer,
  startBuzzerCountdown,
}: PropTypes) => {
  const inputRef = useRef('');
  const textFieldInputRef: RefObject<HTMLInputElement> = useRef(null);
  const preventInputRef = useRef(false);
  const [lockBarWidth, setLockBarWidth] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (preventInputRef.current) return;
    if (!isBuzzed) return;
    submitInput();
  };

  useEffect(() => {
    // focusAndOpenKeyboard(textFieldInputRef.current, 100); // this is the only hack that forces the keyboard to open
    // const listener = (e: any) => {
    //   // console.log('listener', e);
    //   if (e.code === 'Enter'  || e.code === 'NumpadEnter') {
    //     // e.preventDefault();
    //     // if (preventInputRef.current) return;
    //     submitInput();
    //   }
    // };
    // document.addEventListener('keydown', listener);
    return () => {
      // document.removeEventListener('keydown', listener);
    };
  }, []);

  useEffect(() => {
    if (!player) return;
    if (player?.isPromptAnswer && !player?.incorrectAnswer) {
      setInputValue('');
      inputRef.current = '';
      // textFieldInputRef.current.focus();
    }
    if (player?.correctAnswer || player?.timerLimitReached) {
      if (textFieldInputRef) textFieldInputRef.current.blur();
    }
  }, [player]);

  useEffect(() => {
    if (!isBuzzed) return;
    setInputValue('');
    focusAndOpenKeyboard(textFieldInputRef.current, 100); // this is the only hack that forces the keyboard to open
  }, [isBuzzed]);

  useEffect(() => {
    if (!isBuzzed) return;
    if (buzzerCounter < 0) {
      submitInput();
    }
  }, [buzzerCounter]);

  // useEffect(() => {
  //   if (locked) {
  //     setTimeout(() => {
  //       setLockBarWidth(100);
  //     }, 50);
  //     preventInputRef.current = true;
  //   } else {
  //     setLockBarWidth(0);
  //     preventInputRef.current = false;
  //   }
  // }, [locked]);

  useEffect(() => {
    setInputValue('');
    preventInputRef.current = false;
  }, [questionIndex]);

  const submitInput = () => {
    const input = inputRef.current;
    const collapsedInput = collapseWords(input);
    sendPlayerInput(profileId, input, collapsedInput);
  };

  const sendPlayerInput = (
    profileId: string,
    input: string,
    collapsedInput: string
  ) => {
    playerInput(games[gameId], profileId, input, collapsedInput);
  };

  const handleInputOnChange = (value: string) => {
    if (value.length > 100) return;
    setInputValue(value);
    inputRef.current = value;
  };

  const handleDeleteButtonClick = () => {
    setInputValue('');
    textFieldInputRef.current.focus();
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl variant="filled" sx={{ position: 'relative' }}>
        <Fade in={inputLabelText && !locked ? true : false}>
          <InputLabel
            htmlFor="input-filled"
            style={{
              backgroundColor:
                inputLabelType === 'be-more-specific' ? '#FF7300' : '#d01212ae',
              color: inputLabelType === 'be-more-specific' ? 'black' : 'white',
              padding: 4,
              borderRadius: 4,
              top: -40,
              left: -38,
              fontSize: '16px',
            }}
          >
            {inputLabelText}
          </InputLabel>
        </Fade>
        {/* {locked ? (
          <Box position={'absolute'} zIndex={1}>
            <InputPenaltyLock lockBarWidth={lockBarWidth} />
          </Box>
        ) : null} */}
        <FilledInput
          id="input-filled"
          autoFocus
          size="small"
          hiddenLabel
          placeholder={
            inputLabelType === 'be-more-specific'
              ? `..."${submittedPromptAnswer}"`
              : `answer in ${buzzerCounter} sec`
          }
          inputRef={textFieldInputRef}
          value={inputValue}
          onChange={(e) => handleInputOnChange(e.target.value)}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
          inputMode="text"
          autoComplete="off"
          inputProps={{
            type: 'text',
            autoCorrect: 'off',
            autoCapitalize: 'off',
            autoFocus: true,
            spellCheck: false,
            style: { padding: 4 }, // => results in input height of 31px
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="clear answer"
                onClick={(e) => handleDeleteButtonClick()}
                edge="end"
                sx={{ padding: 0.5, borderRadius: '4px' }}
              >
                <Close />
              </IconButton>
            </InputAdornment>
          }
        />
        <button type="submit" style={{ display: 'none' }}>
          Submit
        </button>
      </FormControl>
    </form>
  );
};

function focusAndOpenKeyboard(el, timeout) {
  if (!timeout) {
    timeout = 100;
  }
  if (el) {
    // Align temp input element approximately where the input element is
    // so the cursor doesn't jump around
    var __tempEl__ = document.createElement('input');
    __tempEl__.setAttribute('autocorrect', 'off');
    __tempEl__.setAttribute('autocapitalize', 'off');
    __tempEl__.style.position = 'absolute';
    __tempEl__.style.opacity = '0';
    __tempEl__.style.width = '1px';
    __tempEl__.style.top = el.offsetTop + 7 + 'px';
    __tempEl__.style.left = el.offsetLeft + 'px';
    // Put this temp element as a child of the page <body> and focus on it
    document.body.appendChild(__tempEl__);
    __tempEl__.focus();

    // The keyboard is open. Now do a delayed focus on the target element
    setTimeout(function () {
      el.focus();
      el.click();
      // Remove the temp element
      // document.body.removeChild(__tempEl__);
    }, timeout);
  }
}
