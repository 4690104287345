import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import {
  Box,
  Card,
  CardActionArea,
  Stack,
  Typography,
  Divider,
  useTheme,
  Chip,
  IconButton,
} from '@mui/material';
import React from 'react';
import { Question } from '../../game/types';

type Props = {
  handleCardClick: (questionId: string) => void;
  handlePlayQuestionClick: (e, question: Question) => void;
  question: Question;
  showDetails?: boolean;
};

const QuestionCard = ({
  handleCardClick,
  handlePlayQuestionClick,
  question,
  showDetails,
}: Props) => {
  const theme = useTheme();
  return (
    <Box>
      <Card sx={{ py: 1, bgcolor: 'transparent' }}>
        <CardActionArea onClick={() => handleCardClick(question._id)}>
          <Stack
            direction={'row'}
            spacing={1}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Stack px={2} spacing={1}>
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Chip
                  label={question.category}
                  variant="outlined"
                  color="primary"
                  size="small"
                />
                <Chip
                  label={`PAR ${question.par}`}
                  variant="outlined"
                  color="primary"
                  size="small"
                />
              </Stack>

              <Typography variant="body2">{question.question}</Typography>

              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.dailies.main,
                }}
              >
                {question.answer}
              </Typography>

              {showDetails && question.type === 'buzzer' && (
                <>
                  <Typography variant="body2" color="lightgrey">
                    Alts: {question.alternateAnswers.join(', ') || 'None'}
                  </Typography>

                  <Typography variant="body2" color="lightgrey">
                    Prompts: {question.promptAnswer.join(', ') || 'None'}
                  </Typography>
                </>
              )}

              {showDetails && question.type === 'multiple-choice' && (
                <>
                  <Typography variant="body2" color="lightgrey">
                    Multiple choice options:
                  </Typography>

                  <Typography variant="body2" color="lightgrey">
                    {question.multipleChoiceAnswers.join(', ')}
                  </Typography>
                </>
              )}
            </Stack>

            <IconButton
              color="secondary"
              onClick={(e) => handlePlayQuestionClick(e, question)}
            >
              <PlayCircleOutlineIcon />
            </IconButton>
          </Stack>
        </CardActionArea>
      </Card>

      <Divider />
    </Box>
  );
};

export default QuestionCard;
