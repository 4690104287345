import React, { useEffect, useState } from 'react';
import {
  Route,
  Navigate,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { CssBaseline } from '@mui/material';

import './styles.css';

import Game from './pages/game/Game';
import Main from './pages/main/Main';
import { Verify } from './pages/auth/Verify';
import { ResetPassword } from './pages/auth/login/components/ResetPassword';
import useFetchProfile from './pages/hooks/useFetchProfile';
import { Login } from './pages/auth/login';
import CreateSet from './pages/CreateSet/CreateSet';
import ProfilePage from './pages/Profile/Profile';
import Activity from './pages/Activity/Activity';
import useGetUnseenUserNotifications from './pages/Activity/hooks/useGetUnseenUserNotifications';
import { Profile } from './types/types';
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  RadialLinearScale,
  Filler,
  Title,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  RadialLinearScale,
  Filler,
  Title
);

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const [profile, setProfile] = useState(null);

  const mutateProfile = (profile: Profile) => setProfile(profile);

  const {
    data: profileData,
    isPending: profileIsPending,
    refetch: refetchProfile,
  } = useFetchProfile();

  useEffect(() => {
    if (profileData) {
      setProfile(profileData);
    }
  }, [profileData]);

  const { data: unseenUserNotifications } = useGetUnseenUserNotifications(
    !!profile
  );

  const [unseenNotifications, setUnseenNotifications] = useState(0);

  useEffect(() => {
    if (unseenUserNotifications) {
      setUnseenNotifications(
        unseenUserNotifications.unseenUserNotificationsCount
      );
    }
  }, [unseenUserNotifications]);

  const handleClearProfile = () => {
    setProfile(undefined);
    navigate('/login');
  };

  const AuthenticatedRoute = ({ children }) => {
    if (!profileIsPending && !profile) {
      return <Navigate to="/login" replace />;
    }

    return children;
  };

  return (
    <>
      <CssBaseline />

      <Routes>
        <Route
          path="login"
          element={
            !!profile ? (
              <Navigate to="/" replace />
            ) : (
              <Login refetchProfile={refetchProfile} />
            )
          }
        />
        <Route
          path="reset-password/:token"
          element={<ResetPassword refetchProfile={refetchProfile} />}
        />
        <Route
          path="verify/:token"
          element={<Verify refetchProfile={refetchProfile} />}
        />
        <Route
          path="game/:customSetId"
          element={
            <Game
              clearProfile={handleClearProfile}
              mutateProfile={mutateProfile}
              profile={profile}
              refetchProfile={refetchProfile}
              unseenNotifications={unseenNotifications}
            />
          }
        />
        <Route
          path="create"
          element={
            <AuthenticatedRoute>
              <CreateSet
                clearProfile={handleClearProfile}
                profile={profile}
                unseenNotifications={unseenNotifications}
              />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="activity"
          element={
            <AuthenticatedRoute>
              <Activity
                clearProfile={handleClearProfile}
                clearNotifications={() => setUnseenNotifications(0)}
                profile={profile}
              />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="profile/:viewProfileId"
          element={
            <AuthenticatedRoute>
              <ProfilePage
                clearProfile={handleClearProfile}
                profile={profile}
                refetchProfile={refetchProfile}
                unseenNotifications={unseenNotifications}
              />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/"
          element={
            <AuthenticatedRoute>
              <Main
                clearProfile={() => setProfile(undefined)}
                profile={profile}
                unseenNotifications={unseenNotifications}
              />
            </AuthenticatedRoute>
          }
        />
        <Route path="*" element={<Login refetchProfile={refetchProfile} />} />
      </Routes>
    </>
  );
}

export default App;
