import {
  Avatar,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

type PropTypes = {
  score: number;
  username?: string;
};

export const ScoreBar = ({ score, username }: PropTypes) => {
  const ref = useRef<HTMLDivElement>(null);
  const [widthLeft, setWidthLeft] = useState<number | null>(null);

  const theme = useTheme();

  // get the width of the progressbar
  useEffect(() => {
    const elementLeft = ref.current;

    function updateWidth() {
      if (elementLeft) {
        const { width } = elementLeft.getBoundingClientRect();
        setWidthLeft(width);
      }
    }

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [ref]);

  // Customize the scale of the progress bar (by default MUI uses a scale of 0 to 100).
  const normalise = (value: number) => {
    if (value < 40) {
      return (value * 100) / 40;
    } else {
      // return ((value + 10) * 100) / (value + 10);
      return 100;
    }
  };

  // turn negative score into normalised positive score
  const negativeScore = (score: number) => normalise(score * -1);

  const scorePosition = (score: number) => {
    if (score === 0) {
      return 16;
    } else if (score < 0 && score <= -40) {
      return widthLeft ? -widthLeft + 16 : 0;
    } else if (score < 0) {
      return ((widthLeft ?? 0) / 40) * score + 16;
    } else if (score > 0 && score < 40) {
      return ((widthLeft ?? 0) / 40) * score + 16;
    } else if (score >= 40) {
      return widthLeft ? widthLeft + 16 : 0;
    }
  };

  return (
    <Stack
      alignItems="center"
      direction="row"
      width="100%"
      className="STACK-CONTAINER"
    >
      <Stack
        direction="row"
        alignItems="center"
        width="50%"
        color="#dbd6cc"
        className="STACK-LEFT"
      >
        <Typography
          left={score < 0 ? '50%' : 'undefined'}
          right={score > 0 ? '50%' : 'undefined'}
          paddingX={1}
          position="absolute"
          fontSize={{ xs: 14, sm: 18 }}
          zIndex={1}
        >
          {username}
        </Typography>
        <LinearProgress
          variant="determinate"
          color="inherit"
          value={negativeScore(score)}
          sx={{
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            height: { xs: 8, sm: 21 },
            width: '100%',
            transform: 'scale(-1, 1)',
          }}
        />
      </Stack>
      <Stack
        direction="row"
        width="50%"
        alignItems="center"
        color="#dbd6cc"
        className="STACK-RIGHT"
      >
        <LinearProgress
          ref={ref}
          variant="determinate"
          color="inherit"
          value={normalise(score)}
          sx={{
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            height: { xs: 8, sm: 21 },
            width: '100%',
          }}
        />
        <Avatar
          sx={{
            backgroundColor:
              score === 0
                ? theme.palette.primary.main
                : score < 0
                ? theme.palette.primary.main
                : theme.palette.primary.main,
            boxShadow:
              '0px 0px 5px 0px #fff, 0px 0px 1px 0px #fff, 0px 0px 2px 0px #fff',
            color:
              score === 0
                ? theme.palette.primary.dark
                : score < 0
                ? theme.palette.secondary.contrastText
                : theme.palette.secondary.contrastText,
            height: { xs: 21, sm: 32 },
            position: 'absolute',
            width: { xs: 21, sm: 32 },
            right: `calc(50% - ${scorePosition(score)}px)`,
            transition: 'right 0.5s ease-out',
            zIndex: 1,
          }}
        >
          <Typography fontSize={{ xs: 12, sm: 16 }}>
            {score > 0 ? `+${score}` : score}
          </Typography>
        </Avatar>
      </Stack>
    </Stack>
  );
};
