import { collapseWords } from '../game-loop/utils';
import { calcFuzzyScore } from './fuzzy-algo';

export const EXACT_MATCH_REQUIRED_LENGTH = 8;
const PANICKY_FUZZY_SCORE = 0.76;
const PASSABLE_FUZZY_SCORE = 0.84;
const POISED_FUZZY_SCORE = 0.92;

// An exact match is required when:
// - answer is less than or equal to min required length or
// - is a number or
// - all but one answer letter is revealed
const exactMatchRequired = (
  collapsedAnswer: string,
  answerWoNumbersAndRomanNumerals: string,
  plural?: string
) =>
  collapsedAnswer.length <= EXACT_MATCH_REQUIRED_LENGTH ||
  answerWoNumbersAndRomanNumerals.length <= EXACT_MATCH_REQUIRED_LENGTH ||
  // eslint-disable-next-line no-self-compare
  +collapsedAnswer === +collapsedAnswer ||
  collapsedAnswer === plural;

const removeNumbersFromText = (text: string) => text.replace(/[0-9]/g, '');
const getNumbersFromText = (text: string) => text.replace(/[^0-9]/g, '');

export const fuzzyMatchSequence = (
  playerInput: string,
  collapsedAnswer: string,
  answerWoNumbersAndRomanNumerals: string,
  romanNumerals: string,
  numbers: string,
  gameClock: number,
  answerWordList: string[],
  plural?: string
) => {
  const collapsedGuttedAnswer = collapseWords(answerWoNumbersAndRomanNumerals);
  if (exactMatchRequired(collapsedAnswer, collapsedGuttedAnswer, plural)) {
    return { correct: false, fuzzy: 0 };
  }

  // All roman numerals have to be exact. No fuzzy ever allowed on those
  if (romanNumerals) {
    const playerInputRomanNumerals = playerInput.slice(
      playerInput.length - romanNumerals.length
    );
    if (playerInputRomanNumerals !== romanNumerals) {
      return { correct: false, fuzzy: 0 };
    }
    const playerInputWithoutRomanNumerals = playerInput.slice(
      0,
      playerInput.length - romanNumerals.length
    );
    // if (
    //   !firstCharsMatch(playerInputWithoutRomanNumerals, collapsedGuttedAnswer)
    // ) {
    //   return { correct: false, fuzzy: 0 };
    // }
  }

  // All numbers have to be exact. No fuzzy ever allowed on those
  if (numbers) {
    const playerInputNumbers = getNumbersFromText(playerInput);
    if (playerInputNumbers !== numbers) {
      return { correct: false, fuzzy: 0 };
    }
    const playerInputWithoutNumbers = removeNumbersFromText(playerInput);
    // if (!firstCharsMatch(playerInputWithoutNumbers, collapsedGuttedAnswer)) {
    //   return { correct: false, fuzzy: 0 };
    // }
  }

  // if (
  //   !numbers &&
  //   !romanNumerals &&
  //   !firstCharsMatch(playerInput, collapsedGuttedAnswer)
  // ) {
  //   return { correct: false, fuzzy: 0 };
  // }

  // If exact match is not required, below is fuzzy match logic
  const fuzzyScore = calcFuzzyScore(
    playerInput,
    answerWordList,
    collapsedGuttedAnswer
  );
  // console.log(
  //   'FUZZY SCORE:',
  //   fuzzyScore,
  //   'Player Input:',
  //   playerInput,
  //   'Answer:',
  //   collapsedGuttedAnswer
  // );
  // At specific late gameclock values, prevent certain acceptable fuzzy thresholds
  if (fuzzyScoreValidation(fuzzyScore, gameClock)) {
    return { correct: true, fuzzy: fuzzyScore };
  }
  return { correct: false, fuzzy: 0 };
};

export const fuzzyScoreValidation = (fuzzyScore: number, gameClock: number) =>
  (gameClock <= 18 && fuzzyScore >= PANICKY_FUZZY_SCORE) ||
  (gameClock === 19 && fuzzyScore >= PASSABLE_FUZZY_SCORE);

export const calculateFuzzyPenalty = (fuzzy: number) => {
  if (fuzzy === 1) {
    return -1;
  }
  if (fuzzy < 1 && fuzzy >= POISED_FUZZY_SCORE) {
    return 0;
  }
  if (fuzzy < POISED_FUZZY_SCORE && fuzzy >= PASSABLE_FUZZY_SCORE) {
    return 1;
  }
  if (fuzzy < PASSABLE_FUZZY_SCORE && fuzzy >= PANICKY_FUZZY_SCORE) {
    return 2;
  }
  return null;
};
