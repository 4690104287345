import { useMutation } from '@tanstack/react-query';
import { postPublishCustomSet } from '../../../../api/apiServices';

const usePublishCustomSet = () => {
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: postPublishCustomSet,
  });

  return {
    mutate,
    isPending,
    isSuccess,
  };
};

export default usePublishCustomSet;
