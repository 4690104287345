import { Box, Tabs, Tab, useTheme, Typography } from '@mui/material';
import React from 'react';
import DraftList from './DraftList';
import { CustomSet } from '../../types/custom-sets';
import { Profile } from '../../types/types';
import { a11yProps, TabPanel } from '../Profile/ProfileTabs/constants';
import PublishedList from './PublishedList';

type Props = {
  customSetsDrafts: CustomSet[];
  customSetsPublished: CustomSet[];
  handleDraftCardClick: (customSetId: string) => void;
  handlePublishedCardClick: (customSetId: string) => void;
  profile: Profile;
};

const SetListsTabs = ({
  customSetsDrafts,
  customSetsPublished,
  handleDraftCardClick,
  handlePublishedCardClick,
  profile,
}: Props) => {
  const theme = useTheme();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="inherit"
        variant="fullWidth"
      >
        <Tab
          label={`Drafts (${customSetsDrafts?.length || 0})`}
          {...a11yProps(0)}
          sx={{
            textTransform: 'none',
          }}
        />
        <Tab
          label={`Published (${customSetsPublished?.length || 0})`}
          {...a11yProps(1)}
          sx={{
            textTransform: 'none',
          }}
        />
      </Tabs>

      <TabPanel value={value} index={0} dir={theme.direction}>
        <DraftList
          customSetsDrafts={customSetsDrafts}
          handleCardClick={handleDraftCardClick}
          profile={profile}
        />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <PublishedList
          customSetsPublished={customSetsPublished}
          handleCardClick={handlePublishedCardClick}
          profile={profile}
        />
      </TabPanel>
    </Box>
  );
};

export default SetListsTabs;
