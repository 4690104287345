import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import CreateIcon from '@mui/icons-material/Create';
import { CustomSet } from '../../types/custom-sets';
import { Profile } from '../../types/types';
import SetListsTabs from './SetListsTabs';

type Props = {
  customSetsDrafts: CustomSet[];
  customSetsPublished: CustomSet[];
  handleDraftCardClick: (customSetId: string) => void;
  handlePublishedCardClick: (customSetId: string) => void;
  handleCreateNewSetClick: () => void;
  profile: Profile;
};

const SetLists = ({
  customSetsDrafts,
  customSetsPublished,
  handleDraftCardClick,
  handlePublishedCardClick,
  handleCreateNewSetClick,
  profile,
}: Props) => {
  return (
    <Stack>
      <Box pt={2} pb={4} display="flex" justifyContent={'center'}>
        <Button
          variant="contained"
          onClick={handleCreateNewSetClick}
          startIcon={<CreateIcon />}
        >
          Create a new set
        </Button>
      </Box>

      <SetListsTabs
        customSetsPublished={customSetsPublished}
        customSetsDrafts={customSetsDrafts}
        handleDraftCardClick={handleDraftCardClick}
        handlePublishedCardClick={handlePublishedCardClick}
        profile={profile}
      />
    </Stack>
  );
};

export default SetLists;
