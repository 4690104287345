import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material';

import { updateGames } from '../../game-engine/game-loop';
import { createGame } from '../../game-engine/game-loop/create-game';
import { endGame, setupNextQuestion } from '../../game-engine/game-loop/helper';
import { startTimer } from '../../game-engine/game-loop/timer';
import { CountdownGameStart } from './CountdownGameStart';
import { GameHeader } from './GameHeader';
import { ScoreBar } from './Scorebar';
import { QuestionAnswer } from './question-answer';
import { PlayerInput } from './PlayerInput';
import { isMobile } from 'react-device-detect';
import {
  COUNTDOWN_DURATION,
  GAME_STAGES,
  games,
} from '../../game-engine/constants';
import './Buzzer.css';
import { AnswerFeedback } from './answer-feedback';
import { Penalty, Player, ProperNameShortAnswer, Question } from './types';
import { gameEvents } from '../../game-engine/event-emitter';
import { CustomSet } from '../../types/custom-sets';
import Buzzer from './Buzzer';
import MultipleChoiceAnswers from './MultipleChoiceAnswers';
import { handleTimerLimitReached } from '../../game-engine/game-loop/time-limit';

const BUZZER_COUNTDOWN_LEN = 6;

let buzzerTimeout;
let incorrectAnswerTimeout;
let playerLockTimeout;

type PropTypes = {
  answerLettersRevealOrder: Record<number, (number | string)[][]>;
  avatarPublicId?: string;
  avatarUrl?: string;
  customSet: CustomSet;
  gameId: string;
  handleGameOver: () => void;
  profileId: string;
  questions: Question[];
  skipCountdown?: boolean;
  username: string;
};

const GameSession = ({
  answerLettersRevealOrder,
  avatarPublicId,
  avatarUrl,
  customSet,
  gameId,
  handleGameOver,
  profileId,
  questions,
  skipCountdown,
  username,
}: PropTypes) => {
  const [type, setType] = useState<'buzzer' | 'multiple-choice'>('buzzer');
  const [countdown, setCountdown] = useState<number>(COUNTDOWN_DURATION);
  const [gameClock, setGameClock] = useState(0);
  const [revealQuestion, setRevealQuestion] = useState('');
  const [revealFullQuestion, setRevealFullQuestion] = useState<string>();
  const [revealAnswer, setRevealAnswer] = useState<string[][]>();
  const [revealMultipleChoiceAnswer, setRevealMultipleChoiceAnswer] =
    useState<string>();
  const [revealMultipleChoiceAnswers, setRevealMultipleChoiceAnswers] =
    useState<string[]>();
  const [players, setPlayers] = useState<Record<string, Player>>();
  const [player, setPlayer] = useState<Player>();
  const [opponent, setOpponent] = useState<Player>();
  // const [questionSet, setQuestionSet] = useState<Question[]>();
  const [gameStarted, setGameStarted] = useState(false);
  const [questionIndex, setQuestionIndex] = useState<number>();
  const [questionMetadata, setQuestionMetadata] =
    useState<Record<string, any>>();
  const [timerLimitReached, setTimerLimitReached] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [unrevealedIndex, setUnrevealedIndex] = useState<number[]>();
  const [fuzzy, setFuzzy] = useState<Record<string, number>>();
  const [properNameShortAnswer, setProperNameShortAnswer] =
    useState<ProperNameShortAnswer>();
  const [penalty, setPenalty] = useState<Penalty>();
  const [delayBeforeQuestionRevealCount, setDelayBeforeQuestionRevealCount] =
    useState(2);
  const [nextQuestionDelayCount, setNextQuestionDelayCount] = useState(4);
  const [revealAt, setRevealAt] = useState<number[]>([]);
  const [nextWordIndex, setNextWordIndex] = useState<number>();
  const [startBuzzerCountdown, setStartBuzzerCountdown] = useState(false);
  const [buzzerCounter, setBuzzerCounter] = useState(BUZZER_COUNTDOWN_LEN);
  const [isBuzzed, setIsBuzzed] = useState(false);
  const isLockedRef = useRef(false);

  useEffect(() => {
    if (!penalty) return;
    if (penalty.penaltyTimestamp) {
      isLockedRef.current = true;
      playerLockTimeout = setTimeout(() => {
        isLockedRef.current = false;
      }, 1000);
    }
  }, [penalty]);

  // this reset the container height when the soft keyboard is open
  const setContainerViewPortHeight = () => {
    const div: HTMLDivElement = document.querySelector('.game-container');
    const visualViewportHeight = window.visualViewport.height;
    div.style.height = `${visualViewportHeight}px`;
  };

  useEffect(() => {
    gameEvents.on('type', setType);
    gameEvents.on('countdown', setCountdown);
    gameEvents.on('gameClock', setGameClock);
    gameEvents.on('revealQuestion', setRevealQuestion);
    gameEvents.on('revealFullQuestion', setRevealFullQuestion);
    gameEvents.on('revealAnswer', setRevealAnswer);
    gameEvents.on('revealMultipleChoiceAnswer', setRevealMultipleChoiceAnswer);
    gameEvents.on(
      'revealMultipleChoiceAnswers',
      setRevealMultipleChoiceAnswers
    );
    gameEvents.on('players', setPlayers);
    gameEvents.on('player', setPlayer);
    gameEvents.on('opponent', setOpponent);
    // gameEvents.on('questionSet', setQuestionSet);
    gameEvents.on('gameStarted', setGameStarted);
    gameEvents.on('questionIndex', setQuestionIndex);
    gameEvents.on('questionMetadata', setQuestionMetadata);
    gameEvents.on('timerLimitReached', handleTimerLimitReached);
    gameEvents.on('gameOver', setGameOver);
    gameEvents.on('unrevealedIndex', setUnrevealedIndex);
    gameEvents.on('fuzzy', setFuzzy);
    gameEvents.on('properNameShortAnswer', setProperNameShortAnswer);
    gameEvents.on('penalty', setPenalty);
    gameEvents.on(
      'delayBeforeQuestionRevealCount',
      setDelayBeforeQuestionRevealCount
    );
    gameEvents.on('nextQuestionDelayCount', setNextQuestionDelayCount);
    gameEvents.on('revealAt', setRevealAt);
    gameEvents.on('nextWordIndex', setNextWordIndex);

    startGame(customSet, skipCountdown);

    const listener = (e: any) => {
      if (e.code === 'Space') {
        if (games[gameId].paused || disableBuzzer()) return;
        e.preventDefault();
        handleBuzz();
      }
    };
    document.addEventListener('keydown', listener);

    return () => {
      gameEvents.off('type', setType);
      gameEvents.off('countdown', setCountdown);
      gameEvents.off('gameClock', setGameClock);
      gameEvents.off('revealQuestion', setRevealQuestion);
      gameEvents.off('revealFullQuestion', setRevealFullQuestion);
      gameEvents.off('revealAnswer', setRevealAnswer);
      gameEvents.off(
        'revealMultipleChoiceAnswer',
        setRevealMultipleChoiceAnswer
      );
      gameEvents.off(
        'revealMultipleChoiceAnswers',
        setRevealMultipleChoiceAnswers
      );
      gameEvents.off('players', setPlayers);
      gameEvents.off('player', setPlayer);
      gameEvents.off('opponent', setOpponent);
      // gameEvents.off('questionSet', setQuestionSet);
      gameEvents.off('gameStarted', setGameStarted);
      gameEvents.off('questionIndex', setQuestionIndex);
      gameEvents.off('questionMetadata', setQuestionMetadata);
      gameEvents.off('timerLimitReached', handleTimerLimitReached);
      gameEvents.off('gameOver', setGameOver);
      gameEvents.off('unrevealedIndex', setUnrevealedIndex);
      gameEvents.off('fuzzy', setFuzzy);
      gameEvents.off('properNameShortAnswer', setProperNameShortAnswer);
      gameEvents.off('penalty', setPenalty);
      gameEvents.off(
        'delayBeforeQuestionRevealCount',
        setDelayBeforeQuestionRevealCount
      );
      gameEvents.off('nextQuestionDelayCount', setNextQuestionDelayCount);
      gameEvents.off('revealAt', setRevealAt);
      gameEvents.off('nextWordIndex', setNextWordIndex);
      document.removeEventListener('keydown', listener);
    };
  }, []);

  const handleTimerLimitReached = (data: any) => {
    clearTimeout(incorrectAnswerTimeout);
    setTimerLimitReached(data);
    clearTimeout(buzzerTimeout);
    setIsBuzzed(false);
    games[gameId].paused = false;
    games[gameId].pausedTimestamp = null;
  };

  useEffect(() => {
    const handleTouchMove = (e) => {
      if (gameOver) return;
      e.preventDefault();
    };

    const handleScroll = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener('touchmove', handleTouchMove, {
      passive: false,
    });
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [gameStarted, gameOver]);

  useEffect(() => {
    setPlayers((prev) => ({
      ...prev,
      [profileId]: player,
    }));

    if (player?.isPromptAnswer && !player?.correctAnswer) {
      clearTimeout(buzzerTimeout);
      startBuzzCounter();
      return;
    }

    if (player?.timerLimitReached) {
      clearTimeout(buzzerTimeout);
      clearTimeout(incorrectAnswerTimeout);
      clearBuzz();
      return;
    }

    if (player?.correctAnswer) {
      clearTimeout(buzzerTimeout);
      clearTimeout(incorrectAnswerTimeout);
      continueGameAfterBuzz();
      return;
    }

    if (!player?.correctAnswer && player?.incorrectAnswer) {
      continueGameAfterBuzz();

      incorrectAnswerTimeout = setTimeout(() => {
        setPlayer((prev) => ({
          ...prev,
          incorrectAnswer: false,
        }));
      }, 2000);
    }
  }, [player]);

  useEffect(() => {
    if (questionIndex === undefined || questionIndex < 0) return;
    resetStatesAndPlayers();
  }, [questionIndex]);

  useEffect(() => {
    if (!gameOver) return;
    handleGameOver();
  }, [gameOver]);

  const resetStatesAndPlayers = () => {
    setFuzzy(undefined);
    setRevealQuestion('');
    setRevealFullQuestion(undefined);
    setRevealAt([]);
    setRevealMultipleChoiceAnswer(undefined);
    setRevealMultipleChoiceAnswers(undefined);
    resetPlayers();
  };

  const resetPlayers = () => {
    if (!players) return;
    const resetPlayers = JSON.parse(JSON.stringify(players));
    for (const profileId of Object.keys(resetPlayers)) {
      if (!resetPlayers[profileId]) continue;
      resetPlayers[profileId].correctAnswer = false;
      resetPlayers[profileId].incorrectAnswer = false;
      resetPlayers[profileId].isPromptAnswer = false;
      resetPlayers[profileId].timerLimitReached = false;
      resetPlayers[profileId].fuzzyScore = null;
      resetPlayers[profileId].currentScore = null;
      if (profileId === opponent?.profileId) {
        setOpponent(resetPlayers[profileId]);
      } else {
        setPlayer(resetPlayers[profileId]);
      }
    }
    setPlayers(resetPlayers);
  };

  const startGame = (customSet: CustomSet, skipCountdown?: boolean) => {
    const game = createGame(
      customSet,
      gameId,
      profileId,
      questions,
      answerLettersRevealOrder,
      username,
      avatarPublicId,
      skipCountdown
    );
    setupNextQuestion(game, 0);
    startTimer(updateGames);
  };

  const startBuzzCounter = () => {
    let buzzerCounter = BUZZER_COUNTDOWN_LEN;
    setBuzzerCounter(buzzerCounter);
    buzzerTimeout = setInterval(() => {
      if (buzzerCounter < 0) {
        continueGameAfterBuzz();
        return;
      }
      buzzerCounter = buzzerCounter - 1;
      setBuzzerCounter(buzzerCounter);
    }, 1000);
  };

  const clearBuzz = () => {
    clearTimeout(buzzerTimeout);
    setIsBuzzed(false);
    games[gameId].paused = false;
    games[gameId].pausedTimestamp = null;
  };

  const continueGameAfterBuzz = () => {
    clearBuzz();
    games[gameId].gameStage = games[gameId].previousGameStage;
  };

  const handleBuzz = () => {
    if (games[gameId].timerLimitReached) return;
    if (games[gameId].buzzerAttemptsRemaining <= 0) return;
    games[gameId].buzzerAttemptsRemaining =
      games[gameId].buzzerAttemptsRemaining - 1;
    games[gameId].paused = true;
    games[gameId].pausedTimestamp = Date.now();
    games[gameId].previousGameStage = games[gameId].gameStage;
    games[gameId].gameStage = GAME_STAGES.PAUSED;
    setIsBuzzed(true);
    clearTimeout(incorrectAnswerTimeout);
    startBuzzCounter();
  };

  const disableBuzzer = () => {
    if (games[gameId]?.buzzerAttemptsRemaining === 0) return true;
    if (isLockedRef.current) return true;
    if (startBuzzerCountdown) return true;
    if (gameClock === games[gameId].timerLimit) return true;
    if (
      games[gameId]?.gameStage !== GAME_STAGES.REVEAL_QUESTION &&
      games[gameId]?.gameStage !== GAME_STAGES.REVEAL_BOXES &&
      games[gameId]?.gameStage !== GAME_STAGES.DELAY_BEFORE_LETTER_REVEAL &&
      games[gameId]?.gameStage !== GAME_STAGES.REVEAL_ANSWER &&
      games[gameId]?.gameStage !== GAME_STAGES.WAIT_UNTIL_TIME_LIMIT
    )
      return true;
  };

  return (
    <>
      {countdown > 0 ? (
        <CountdownGameStart
          countdown={countdown}
          skipCountdown={skipCountdown}
        />
      ) : (
        <>
          <Stack alignItems={'center'} spacing={1} mt={2}>
            {isBuzzed ? <Box className="dimmed"></Box> : null}
            <ScoreBar
              score={player ? player.parScore : 0}
              username={player?.username}
            />
            {opponent ? (
              <ScoreBar
                score={opponent.parScore}
                username={opponent.username}
              />
            ) : null}
            <GameHeader
              avatarPublicId={avatarPublicId}
              avatarUrl={avatarUrl}
              gameClock={gameClock}
              opponent={opponent}
              player={player}
              questionIndex={questionIndex}
              questionMetadata={questionMetadata}
              timerLimitReached={timerLimitReached}
              totalNumQuestions={questions.length}
            />
            {isBuzzed ? (
              <PlayerInput
                answer={revealAnswer}
                buzzerCounter={buzzerCounter}
                gameId={gameId}
                isBuzzed={isBuzzed}
                penalty={penalty}
                player={player}
                profileId={profileId}
                questionIndex={questionIndex}
                startBuzzerCountdown={startBuzzerCountdown}
                timerLimitReached={timerLimitReached}
              />
            ) : (
              <AnswerFeedback
                gameId={gameId}
                player={player}
                questionIndex={questionIndex}
                type={type}
              />
            )}

            <Divider sx={{ width: '100%' }} />

            <QuestionAnswer
              answer={revealAnswer}
              correctAnswer={player?.correctAnswer}
              fullQuestion={revealFullQuestion}
              isBuzzed={isBuzzed}
              properNameShortAnswer={properNameShortAnswer}
              question={revealQuestion}
              questionIndex={questionIndex}
              startBuzzerCountdown={startBuzzerCountdown}
              timerLimitReached={timerLimitReached}
              unrevealedIndex={unrevealedIndex}
            />

            {type === 'buzzer' ? (
              <Buzzer
                disableBuzzer={disableBuzzer}
                handleBuzz={handleBuzz}
                isLocked={isLockedRef.current}
              />
            ) : (
              <MultipleChoiceAnswers
                gameId={gameId}
                profileId={profileId}
                revealMultipleChoiceAnswer={revealMultipleChoiceAnswer}
                revealMultipleChoiceAnswers={revealMultipleChoiceAnswers}
                selectedMultipleChoiceAnswer={
                  player?.roundScores[questionIndex]?.answerAttempts[0]?.input
                }
              />
            )}
          </Stack>
        </>
      )}
    </>
  );
};

export default GameSession;
