import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { Word } from './Word';
import './styles.css';
import { ProperNameShortAnswer } from '../types';

type Props = {
  answer?: string[][];
  correctAnswer: boolean;
  properNameShortAnswer?: ProperNameShortAnswer;
  timerLimitReached: boolean;
  unrevealedIndex?: number[];
};
export const DisplayAnswer = ({
  answer,
  correctAnswer,
  properNameShortAnswer,
  timerLimitReached,
  unrevealedIndex,
}: Props) => {
  const [entireAnswerDisplayed, setEntireAnswerDisplayed] = useState(false);

  useEffect(() => {
    if (!answer) return;
    for (let i = 0; i < answer.length; i++) {
      if (answer[i].includes('')) {
        setEntireAnswerDisplayed(false);
        return;
      }
    }
    setEntireAnswerDisplayed(true);
  }, [answer]);

  const underline = (wordIndex: number) => {
    if (!properNameShortAnswer || !answer) return false;
    if (properNameShortAnswer === 'last word') {
      if (wordIndex === answer.length - 1) return true;
    }
    if (properNameShortAnswer === 'last two words') {
      if (wordIndex === answer.length - 1 || wordIndex === answer.length - 2)
        return true;
    }
    if (properNameShortAnswer === 'first word') {
      if (wordIndex === 0) return true;
    }
    return false;
  };

  return (
    <Stack
      direction="row"
      justifyContent="center"
      sx={{ flexWrap: 'wrap', zIndex: 10 }}
    >
      {answer
        ? answer.map((word, wordIndex) => (
            <Stack
              direction="row"
              spacing={1}
              justifyContent="center"
              flexWrap={'wrap'}
              className={`answer-fade-in ${underline(wordIndex) ? '' : ''}`}
              key={`word-${wordIndex}`}
              mx={1.5}
            >
              <Word
                correctAnswer={correctAnswer}
                entireAnswerDisplayed={entireAnswerDisplayed}
                timerLimitReached={timerLimitReached}
                underline={underline(wordIndex)}
                unrevealedIndex={unrevealedIndex}
                word={word}
                wordIndex={wordIndex}
              ></Word>
            </Stack>
          ))
        : null}
    </Stack>
  );
};
