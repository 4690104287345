import React, { useEffect, useState } from 'react';
import { Box, LinearProgress, useTheme } from '@mui/material';
import { Inputter } from './Inputter';
import { RoundEndFeedback } from './round-end-feedback';
import { Penalty, Player } from './types';

let playerLockTimeout;

type Props = {
  answer: string[][];
  buzzerCounter: number;
  gameId: string;
  isBuzzed: boolean;
  penalty: Penalty;
  player: Player;
  profileId: string;
  questionIndex: number;
  startBuzzerCountdown: boolean;
  timerLimitReached: boolean;
};

export const PlayerInput = ({
  answer,
  buzzerCounter,
  gameId,
  isBuzzed,
  penalty,
  player,
  profileId,
  questionIndex,
  startBuzzerCountdown,
  timerLimitReached,
}: Props) => {
  const theme = useTheme();

  const [isInputFocused, setIsInputFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isLocked, setIsLocked] = useState(false);
  const [isPromptAnswer, setIsPromptAnswer] = useState(false);
  const [submittedPromptAnswer, setSubmittedPromptAnswer] = useState<string>();
  // const [isLocked, setIsLocked] = useState(false);

  // useEffect(() => {
  //   if (!penalty) return;
  //   if (penalty.penaltyTimestamp) {
  //     setIsLocked(true);
  //     playerLockTimeout = setTimeout(() => {
  //       setIsLocked(false);
  //     }, 1000);
  //   }
  // }, [penalty]);

  useEffect(() => {
    if (player?.isPromptAnswer) {
      setIsPromptAnswer(true);
      setSubmittedPromptAnswer(player?.submittedPromptAnswer);
      return;
    }
    if (!player?.isPromptAnswer) {
      setIsPromptAnswer(false);
    }
  }, [player]);

  useEffect(() => {
    if (!isLocked || !answer || !answer.length) return;
    clearTimeout(playerLockTimeout);
    setIsLocked(false);
  }, [answer]);

  // useEffect(() => {
  //   if (!isLocked || !answer || !answer.length) return;
  //   clearTimeout(playerLockTimeout);
  //   setIsLocked(false);
  // }, [answer]);

  const isRoundEnded = timerLimitReached || player?.correctAnswer;
  const isWrongAnswerAttempt =
    player?.incorrectAnswer && !player?.timerLimitReached;

  const getProgressBarValue = () => {
    if (!isBuzzed) return 0;
    if (buzzerCounter === 6) return 0;
    if (buzzerCounter === 5) return 16.6;
    if (buzzerCounter === 4) return 33.2;
    if (buzzerCounter === 3) return 49.8;
    if (buzzerCounter === 2) return 66.4;
    if (buzzerCounter === 1) return 83;
    if (buzzerCounter === 0) return 100;
    return 0;
  };

  const getProgressBarColor = () => {
    if (!isBuzzed) return 'none';
    if (buzzerCounter === 6) return '#00FF00';
    if (buzzerCounter === 5) return '#00FF00';
    if (buzzerCounter === 4) return '#80FF00';
    if (buzzerCounter === 3) return '#cfff00';
    if (buzzerCounter === 2) return '#FFFF00';
    if (buzzerCounter === 1) return '#FF8000';
    if (buzzerCounter === 0) return '#FF0000';
  };

  return (
    <Box height={40} className="focused-section">
      <Box sx={{ opacity: isRoundEnded ? 0 : 1 }}>
        {isBuzzed ? (
          <LinearProgress
            variant="determinate"
            value={getProgressBarValue()}
            sx={{
              bgcolor: 'transparent',
              height: '2px',
              '& .MuiLinearProgress-bar': { bgcolor: getProgressBarColor() },
            }}
          />
        ) : null}
        <Inputter
          buzzerCounter={buzzerCounter}
          gameId={gameId}
          isBuzzed={isBuzzed}
          profileId={profileId}
          inputLabelText={isPromptAnswer ? 'Be more specific' : null}
          inputLabelType={isPromptAnswer ? 'be-more-specific' : null}
          inputValue={inputValue}
          locked={false}
          player={player}
          questionIndex={questionIndex}
          setIsInputFocused={setIsInputFocused}
          setInputValue={setInputValue}
          submittedPromptAnswer={submittedPromptAnswer}
          startBuzzerCountdown={startBuzzerCountdown}
        />
      </Box>
      {isRoundEnded ? (
        <RoundEndFeedback inputValue={inputValue} player={player} />
      ) : null}
    </Box>
  );
};
