import React from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';

type Props = {
  question: string;
  fullQuestion?: string;
};
export const DisplayQuestion = ({ question, fullQuestion }: Props) => {
  const theme = useTheme();
  const xsBreakpoint = useMediaQuery(theme.breakpoints.only('xs'));
  const displayQuestion = (question: string, fullQuestion?: string) => {
    if (!question && !fullQuestion) return <></>;
    const questionWords = fullQuestion
      ? fullQuestion.split(' ')
      : question.split(' ');

    let words = '';
    const elements = [];
    for (const word of questionWords) {
      words = words + ' ' + word;
    }
    elements.push(words);
    return (
      <>
        {elements.map((e, index) => (
          <span key={index} style={{ lineHeight: '26px' }}>
            {e}
          </span>
        ))}
      </>
    );
  };

  return (
    <Typography
      textAlign="center"
      paddingX={1}
      variant={xsBreakpoint ? null : 'h5'}
      lineHeight={xsBreakpoint ? 'initial' : 2}
    >
      {displayQuestion(question, fullQuestion)}
    </Typography>
  );
};
