import { updatePlayerScore } from '../game-input/player-input';
import { Game } from '../../pages/game/types';
import { gameEvents } from '../event-emitter';

export const handleTimerLimitReached = (game: Game) => {
  if (game.timerLimitReached) return;
  game.timerLimitReached = true;
  gameEvents.emit('timerLimitReached', true);
  game.unrevealedIndex = null;
  gameEvents.emit('unrevealedIndex', null);

  const players = game.players;
  // console.log('handleTimerLimitReached', players);
  const questionIndex = game.questionIndex;
  const questionData = game.questionData;
  const par = questionData.par;
  const playersArr = Object.values(players);
  for (const p of playersArr) {
    if (!p.correctAnswer) {
      // console.log('TIMEOUT', p.username);
      const score = game.timerLimit - par;
      updatePlayerScore(
        game,
        p.profileId,
        questionIndex,
        score,
        false,
        null,
        undefined,
        undefined
      );
      gameEvents.emit('player', p);
    }
  }
};
