import React from 'react';
import { Typography } from '@mui/material';
import './styles.css';

type PropTypes = {
  gameClock: number;
  par: number;
  size?: 'normal' | 'small';
};

export const GameClock = ({ par, gameClock, size }: PropTypes) => {
  return (
    <Typography
      className={gameClock === par ? 'game-timer-grow' : ''}
      textAlign="center"
      letterSpacing={2}
      marginRight={-1} // to account for the letterspacing
      variant={size === 'small' ? 'h6' : 'h4'}
      sx={{
        textShadow: `#fff 0px 0px 0px, #fff 0px 0px 20px, #fff 0px 0px 0px`,
      }}
    >
      00:{gameClock < 10 ? '0' : null}
      {gameClock || 0}
    </Typography>
  );
};
