import React from 'react';
import { Chip, Stack } from '@mui/material';

type Props = {
  category: string;
  par: number;
  questionIndex: number;
  totalNumQuestions: number;
};
export const CategoryParChips = ({
  category,
  par,
  questionIndex,
  totalNumQuestions,
}: Props) => (
  <Stack
    direction="row"
    justifyContent="center"
    alignItems="center"
    spacing={2}
    mt={1}
  >
    {/* <Chip
      label={`${questionIndex + 1 || 0} of ${totalNumQuestions}`}
      color="info"
      variant="outlined"
      size={'small'}
      sx={{ fontSize: 12 }}
    /> */}
    <Chip
      label={category || 'None'}
      color="info"
      variant="outlined"
      size={'small'}
      sx={{ fontSize: 12 }}
    />
    <Chip
      label={`PAR ${par}`}
      color="info"
      variant="outlined"
      size={'small'}
      sx={{ fontSize: 12 }}
    />
  </Stack>
);
