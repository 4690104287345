import React from 'react';
import {
  Avatar,
  Badge,
  BadgeProps,
  Box,
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { GameClock } from './GameClock';
import { CategoryParChips } from './CategoryParChips';
import { PlayerDisplay } from './PlayerDisplay';
import { Player } from './types';

const PenaltyBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -5,
    top: 22,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const CenteredGameClockBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  width: 'fit-content',
}));

const DisplayScoreLabelValue = ({ score }: { score: number }) => (
  <Stack spacing={-1} alignItems="center" justifyContent="center" marginX={1}>
    <Typography variant="overline">Score</Typography>
    <Typography
      sx={{
        textShadow: `#fff 0px 0px 1px, #fff 10px 0px 20px, #fff 0px 0px 16px`,
      }}
    >
      {score > 0 ? `+${score}` : score}
    </Typography>
  </Stack>
);

const DisplayQuestionIndex = ({
  questionIndex,
  totalNumQuestions,
}: {
  questionIndex: number;
  totalNumQuestions: number | undefined;
}) => (
  <Stack spacing={-1}>
    <Typography variant="overline">Question</Typography>
    <Typography variant="caption" textAlign="center" width="100%">
      {questionIndex + 1} of {totalNumQuestions}
    </Typography>
  </Stack>
);

type Props = {
  avatarPublicId?: string;
  avatarUrl?: string;
  gameClock: number;
  opponent: Player;
  player: Player;
  questionIndex: number;
  questionMetadata: Record<string, any>;
  timerLimitReached: boolean;
  totalNumQuestions: number;
};

export const GameHeader = ({
  avatarPublicId,
  avatarUrl,
  gameClock,
  opponent,
  player,
  questionIndex,
  questionMetadata,
  timerLimitReached,
  totalNumQuestions,
}: Props) => {
  const theme = useTheme();

  return (
    <Box width={'100%'}>
      <Stack
        direction={'row'}
        position={'relative'}
        justifyContent={'space-between'}
        alignItems={'center'}
        paddingX={1}
        width={'100%'}
      >
        <PlayerDisplay
          avatarUrl={avatarUrl}
          avatarPublicId={avatarPublicId}
          player={player}
          timerLimitReached={timerLimitReached}
        />

        <CenteredGameClockBox sx={{ transform: 'translate(-50%, 0%)' }}>
          <GameClock par={questionMetadata?.par} gameClock={gameClock} />
        </CenteredGameClockBox>

        {opponent ? (
          <PlayerDisplay
            avatarPublicId={opponent.avatarPublicId}
            player={opponent}
            timerLimitReached={timerLimitReached}
          />
        ) : (
          <DisplayQuestionIndex
            questionIndex={questionIndex}
            totalNumQuestions={totalNumQuestions}
          />
        )}
      </Stack>
      <CategoryParChips
        category={questionMetadata?.category}
        par={questionMetadata?.par}
        questionIndex={questionIndex}
        totalNumQuestions={totalNumQuestions}
      />
    </Box>
  );
};
