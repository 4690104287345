import React from 'react';
import { Letter } from './Letter';

type PropTypes = {
  correctAnswer: boolean;
  entireAnswerDisplayed: boolean;
  timerLimitReached: boolean;
  underline: boolean;
  unrevealedIndex?: number[];
  word: string[];
  wordIndex: number;
};

export const Word = ({
  correctAnswer,
  entireAnswerDisplayed,
  timerLimitReached,
  underline,
  unrevealedIndex,
  word,
  wordIndex,
}: PropTypes) => {
  return (
    <>
      {word.map((letter, letterIndex) =>
        letter === ' ' ? (
          <div key={`letter-${letterIndex}`}></div>
        ) : (
          <div key={`letter-${letterIndex}`}>
            <Letter
              correctAnswer={correctAnswer}
              entireAnswerDisplayed={entireAnswerDisplayed}
              letter={letter}
              letterIndex={letterIndex}
              timerLimitReached={timerLimitReached}
              underline={underline}
              unrevealedIndex={unrevealedIndex}
              wordIndex={wordIndex}
            ></Letter>
          </div>
        )
      )}
    </>
  );
};
