import { useState, useCallback } from 'react';
import { Methods } from '../pages/auth/hooks/constants';

const API_HOST = process.env.REACT_APP_API;

type FetchOptions = {
  url: string;
  method?: Methods;
  headers?: HeadersInit;
  body?: Record<string, unknown>;
};

type FetchResult<T> = {
  clearData: () => void;
  data: T | null;
  loading: boolean;
  error: Record<string, any>;
  sendRequest: (options?: FetchOptions) => void; // Renamed for clarity
};

export const useApi = <T>(): FetchResult<T> => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const sendRequest = useCallback(async (options?: FetchOptions) => {
    setLoading(true);
    setError(null);
    setData(null);

    try {
      if (!options) {
        throw new Error('No options provided for API request');
      }

      const response = await fetch(API_HOST + options.url, {
        method: options.method || 'GET', // Default to GET if method not specified
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          ...options.headers,
        },
        body:
          options.method === Methods.POST ? JSON.stringify(options.body) : null,
      });

      if (!response.ok) {
        let errorObj: any = { message: 'Network response was not ok' }; // Initialize with a default message
        if (response.headers.get('Content-Length') !== '0') {
          try {
            const errorData = await response.json();
            // Assign message from errorData or stick with the default
            errorObj.message = errorData.message || errorObj.message;

            // Add the email property to errorObj if it exists in errorData
            if (errorData.email) {
              errorObj.email = errorData.email;
            }

            console.log('Error object:', errorObj);
          } catch (jsonError) {
            console.error('Error parsing JSON from error response:', jsonError);
          }
        }
        // Throw the error object instead of a string
        throw errorObj;
      }

      // Only parse JSON if response has a body
      if (response.headers.get('Content-Length') !== '0') {
        const jsonData = (await response.json()) as T;
        setData(jsonData);
      }
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  }, []);

  const clearData = () => setData(null);

  return { clearData, data, loading, error, sendRequest };
};
