// import { GAME_STAGES,  } from './utils';

import { GAME_STAGES } from '../constants';
import {
  resetStatesForNextQuestion,
  revealPredeterminedAnswerLetterOrder,
  simulateGhostPlayer,
} from './helper';
import { isInterval, tickResolution } from './timer';
import {
  findIndexOf2dArray,
  getRandomIndexInArray,
  roundToOneDecimal,
} from './utils';
import { handleTimerLimitReached } from './time-limit';
import { Game } from '../../pages/game/types';
import { gameEvents } from '../event-emitter';

// All intervals in seconds
const COUNTDOWN_INTERVAL = 1;
const DELAY_BEFORE_QUESTION_REVEAL_INTERVAL = 1;
const GAMECLOCK_INTERVAL = 1;
const REVEAL_QUESTION_INTERVAL = 0.5;
const DELAY_BEFORE_LETTER_REVEAL_INTERVAL = 1;
const REVEAL_BOXES_INTERVAL = 0.5;
const NEXT_QUESTION_INTERVAL = 1;
const UNPAUSE_COUNT_INTERVAL = 1;

// const isInterval = (x, y) => Math.round(x * 100) % Math.round(y * 100) === 0;

const handleCountdown = (seconds: number, game: Game) => {
  if (!isInterval(seconds, COUNTDOWN_INTERVAL)) return;
  const isGameStarted = game.gameStarted;
  if (!isGameStarted) return;
  if (game.skipCountdown) {
    gameEvents.emit('countdown', 0);
    game.countdown = 0;
    game.gameStage = GAME_STAGES.DELAY_BEFORE_QUESTION_REVEAL;
    return;
  }
  const decCountdown = game.countdown - 1;
  game.countdown = decCountdown;
  gameEvents.emit('countdown', decCountdown);
  // console.log('game.countdown', game.countdown);

  if (game.countdown === 0) {
    game.gameStage = GAME_STAGES.DELAY_BEFORE_QUESTION_REVEAL;
    return;
  }
};

const handleDelayBeforeQuestionReveal = (seconds: number, game: Game) => {
  if (!isInterval(seconds, DELAY_BEFORE_QUESTION_REVEAL_INTERVAL)) return;
  const delayBeforeQuestionRevealCount = game.delayBeforeQuestionRevealCount;
  gameEvents.emit(
    'delayBeforeQuestionRevealCount',
    game.delayBeforeQuestionRevealCount
  );
  if (delayBeforeQuestionRevealCount <= 0) {
    game.gameStage = GAME_STAGES.REVEAL_QUESTION;
    game.startTime = Date.now();
    game.gameClockIntervalTimestamp = Date.now() - 0 * 1000;
    return;
  }
  game.delayBeforeQuestionRevealCount = game.delayBeforeQuestionRevealCount - 1;
};

const handleGameClock = (seconds: number, game: Game) => {
  if (!isInterval(seconds, GAMECLOCK_INTERVAL)) return;
  if (game.timerLimitReached || game.allPlayersAnsweredCorrectly) return;
  const updatedGameClock = game.gameClock + 1;
  game.gameClock = updatedGameClock;
  game.gameClockIntervalTimestamp = Date.now() - updatedGameClock * 1000;
  gameEvents.emit('gameClock', updatedGameClock);
  // console.log(
  //   'gameClock',
  //   updatedGameClock,
  // );
  if (
    game.revealAnswerAtSecond &&
    game.revealAnswerAtSecond === updatedGameClock
  ) {
    if (game.type === 'buzzer') {
      game.revealAnswer = game.revealedLetters;
      gameEvents.emit('revealAnswer', game.revealAnswer);
    } else if (game.type === 'multiple-choice') {
      gameEvents.emit(
        'revealMultipleChoiceAnswers',
        game.multipleChoiceAnswers
      );
    }
  } else if (updatedGameClock >= game.timerLimit) {
    handleTimerLimitReached(game);
    game.gameStage = GAME_STAGES.NEXT_QUESTION;

    if (game.type === 'buzzer') {
      game.revealAnswer = game.answerLetters;
      gameEvents.emit('revealAnswer', game.revealAnswer);
    } else if (game.type === 'multiple-choice') {
      gameEvents.emit('revealMultipleChoiceAnswer', game.questionData.answer);
    }
  }
};

const handleRevealQuestion = (seconds: number, game: Game) => {
  if (game.gameStage !== GAME_STAGES.REVEAL_QUESTION) return;
  if (!isInterval(seconds, REVEAL_QUESTION_INTERVAL)) return;
  if (game.questionWordIndex >= game.questionWords.length) {
    if (game.type === 'buzzer') {
      game.revealAnswer = game.revealedLetters;
      // console.log('game.revealAnswer', game.revealAnswer);
      gameEvents.emit('revealAnswer', game.revealAnswer);
      game.gameStage = GAME_STAGES.REVEAL_BOXES;
    } else if (game.type === 'multiple-choice') {
      gameEvents.emit(
        'revealMultipleChoiceAnswers',
        game.multipleChoiceAnswers
      );
    } else {
    }
  }
  game.revealQuestion = game.questionWords
    .slice(0, game.questionWordIndex + 1)
    .join(' ');
  // console.log('handleRevealQuestion', game.revealQuestion);
  game.questionWordIndex = game.questionWordIndex + 1;
  gameEvents.emit('revealQuestion', game.revealQuestion);
};

const handleRevealBoxes = (seconds: number, game: Game) => {
  if (game.gameStage !== GAME_STAGES.REVEAL_BOXES) return;
  if (!isInterval(seconds, REVEAL_BOXES_INTERVAL)) return;
  // console.log('REVEAL_BOXES', seconds, game.gameStage);
  if (game.revealBoxesCount <= 0) {
    game.gameStage = GAME_STAGES.DELAY_BEFORE_LETTER_REVEAL;
  }
  game.revealBoxesCount = game.revealBoxesCount - 1;
};

const handleDelayBeforeLetterReveal = (seconds: number, game: Game) => {
  if (game.gameStage !== GAME_STAGES.DELAY_BEFORE_LETTER_REVEAL) return;
  if (!isInterval(seconds, DELAY_BEFORE_LETTER_REVEAL_INTERVAL)) return;
  // console.log('DELAY_BEFORE_LETTER_REVEAL', seconds, game.gameStage);
  if (game.delayBeforeLetterRevealCount <= 0) {
    game.gameStage = GAME_STAGES.REVEAL_ANSWER;
    const revealModOffset = roundToOneDecimal(seconds % game.revealTiming);
    game.revealModOffset = revealModOffset;
  }
  game.delayBeforeLetterRevealCount = game.delayBeforeLetterRevealCount - 1;
};

const handleRevealAnswer = (seconds: number, game: Game) => {
  if (game.gameStage !== GAME_STAGES.REVEAL_ANSWER) return;
  // guarantees that the first letter is revealed instantly
  const adjustedSeconds = seconds - game.revealModOffset - 0.1;
  if (!isInterval(adjustedSeconds, game.revealTiming)) return;
  if (!game.skipCountdown && game.type === 'buzzer') {
    revealPredeterminedAnswerLetterOrder(game);
    return;
  }
  const revealedLetters = [...game.revealedLetters];
  const unrevealedProperNameIndex = game.unrevealedProperNameIndex;
  const [randomWordIndex, randomLetterIndex] = getRandomIndexInArray(
    revealedLetters,
    unrevealedProperNameIndex
  );
  if (randomWordIndex === -1 && randomLetterIndex === -1) {
    game.unrevealedIndex = findIndexOf2dArray(revealedLetters, '');
    // console.log('game.unrevealedIndex', game.unrevealedIndex);
    gameEvents.emit('unrevealedIndex', game.unrevealedIndex);
    game.gameStage = GAME_STAGES.WAIT_UNTIL_TIME_LIMIT;
    return;
  }
  game.revealedLetters[randomWordIndex][randomLetterIndex] =
    game.answerLetters[randomWordIndex][randomLetterIndex];
  // console.log('data.revealAnswer', JSON.stringify(game.revealAnswer));
  game.revealAnswer = revealedLetters;
  gameEvents.emit('revealAnswer', revealedLetters);
};

const handleNextQuestion = (seconds: number, game: Game) => {
  if (game.gameStage !== GAME_STAGES.NEXT_QUESTION) return;
  if (!isInterval(seconds, NEXT_QUESTION_INTERVAL)) return;
  const timerLimitReached = game.timerLimitReached;
  if (game.type === 'buzzer' && !timerLimitReached) {
    game.revealAnswer = game.answerLetters;
    gameEvents.emit('revealAnswer', game.revealAnswer);
  }
  const nextQuestionDelayCount = game.nextQuestionDelayCount;
  gameEvents.emit('nextQuestionDelayCount', game.nextQuestionDelayCount);
  if (nextQuestionDelayCount <= 0) {
    resetStatesForNextQuestion(game);
    return;
  }
  game.nextQuestionDelayCount = game.nextQuestionDelayCount - 1;
};

const handleAllPlayersAnsweredCorrectly = (game: Game) => {
  const questionData = game.questionData;
  game.revealFullQuestion = questionData.question;
  gameEvents.emit('revealFullQuestion', game.revealFullQuestion);
  game.allPlayersAnsweredCorrectly = false;
  game.gameStage = GAME_STAGES.NEXT_QUESTION;
  if (game.type === 'multiple-choice') {
    gameEvents.emit('revealMultipleChoiceAnswer', game.questionData.answer);
  }
};

const handleNoBuzzesRemaining = (game: Game) => {
  game.gameClock = game.timerLimit;
  game.gameClockIntervalTimestamp = null;
  gameEvents.emit('gameClock', game.timerLimit);
  game.gameStage = GAME_STAGES.NEXT_QUESTION;
  const questionData = game.questionData;
  game.revealFullQuestion = questionData.question;
  gameEvents.emit('revealFullQuestion', game.revealFullQuestion);
  handleTimerLimitReached(game);

  if (game.type === 'buzzer') {
    game.revealAnswer = game.answerLetters;
    gameEvents.emit('revealAnswer', game.revealAnswer);
  } else if (game.type === 'multiple-choice') {
    gameEvents.emit('revealMultipleChoiceAnswer', game.questionData.answer);
  }
};

const handlePaused = async (seconds: number, game: Game) => {
  if (!isInterval(seconds, UNPAUSE_COUNT_INTERVAL)) return;
  const paused = game.paused;
};

export const updateGame = (seconds: number, game: Game) => {
  const gameStage = game.gameStage;
  // console.log('gameStage', gameStage);
  if (gameStage === GAME_STAGES.PAUSED) {
    handlePaused(seconds, game);
    return;
  }
  if (gameStage === GAME_STAGES.COUNT_DOWN) {
    handleCountdown(seconds, game);
  }
  if (gameStage === GAME_STAGES.DELAY_BEFORE_QUESTION_REVEAL) {
    handleDelayBeforeQuestionReveal(seconds, game);
  }
  if (
    gameStage !== GAME_STAGES.COUNT_DOWN &&
    gameStage !== GAME_STAGES.DELAY_BEFORE_QUESTION_REVEAL &&
    gameStage !== GAME_STAGES.NEXT_QUESTION
  ) {
    handleGameClock(seconds, game);
  }
  if (
    gameStage === GAME_STAGES.REVEAL_QUESTION &&
    gameStage !== GAME_STAGES.WAIT_UNTIL_TIME_LIMIT
  ) {
    handleRevealQuestion(seconds, game);
  }
  if (
    gameStage === GAME_STAGES.REVEAL_BOXES &&
    gameStage !== GAME_STAGES.WAIT_UNTIL_TIME_LIMIT
  ) {
    handleRevealBoxes(seconds, game);
  }
  if (
    gameStage === GAME_STAGES.DELAY_BEFORE_LETTER_REVEAL &&
    gameStage !== GAME_STAGES.WAIT_UNTIL_TIME_LIMIT
  ) {
    handleDelayBeforeLetterReveal(seconds, game);
  }
  if (
    gameStage === GAME_STAGES.REVEAL_ANSWER &&
    gameStage !== GAME_STAGES.WAIT_UNTIL_TIME_LIMIT
  ) {
    handleRevealAnswer(seconds, game);
  }
  if (
    gameStage === GAME_STAGES.NEXT_QUESTION &&
    gameStage !== GAME_STAGES.WAIT_UNTIL_TIME_LIMIT
  ) {
    handleNextQuestion(seconds, game);
  }
  if (
    gameStage !== GAME_STAGES.COUNT_DOWN &&
    gameStage !== GAME_STAGES.DELAY_BEFORE_QUESTION_REVEAL &&
    gameStage !== GAME_STAGES.NEXT_QUESTION &&
    game.allPlayersAnsweredCorrectly
  ) {
    handleAllPlayersAnsweredCorrectly(game);
  } else if (
    gameStage !== GAME_STAGES.COUNT_DOWN &&
    gameStage !== GAME_STAGES.DELAY_BEFORE_QUESTION_REVEAL &&
    gameStage !== GAME_STAGES.NEXT_QUESTION &&
    game.buzzerAttemptsRemaining === 0
  ) {
    handleNoBuzzesRemaining(game);
  }
};
