import React from 'react';
import { Paper, Typography, useTheme } from '@mui/material';
import { boxShadowGameLettersReveal } from '../constants';

type PropTypes = {
  correctAnswer: boolean;
  entireAnswerDisplayed: boolean;
  letter: string;
  letterIndex: number;
  timerLimitReached: boolean;
  underline: boolean;
  unrevealedIndex?: number[];
  wordIndex: number;
};

export const Letter = ({
  correctAnswer,
  entireAnswerDisplayed,
  letter,
  letterIndex,
  timerLimitReached,
  underline,
  unrevealedIndex,
  wordIndex,
}: PropTypes) => {
  const theme = useTheme();

  const isUnrevealedLetter = () =>
    unrevealedIndex &&
    wordIndex === unrevealedIndex[0] &&
    letterIndex === unrevealedIndex[1];

  return (
    <Paper
      elevation={6}
      className={`${isUnrevealedLetter() ? 'answer-letter-animation' : ''}`}
      sx={{
        width: '24px',
        height: '26px',
        alignContent: 'center',
        backgroundColor: isUnrevealedLetter() ? 'secondary.main' : 'initial',
        marginTop: '8px',
        borderBottom: underline ? '1px solid #e7ff00' : 'inherit',
        boxShadow:
          timerLimitReached || correctAnswer
            ? boxShadowGameLettersReveal(
                correctAnswer ? theme.palette.success : theme.palette.error
              )
            : null,
      }}
    >
      <Typography variant="body1" textAlign="center">
        {letter === '' ? ' ' : letter}
      </Typography>
    </Paper>
  );
};
