export const tickResolution = 0.1; // tenth of a second
let STOP_TIMER = true;
let timeoutId = null;
let deltaLessThan50 = 0;

const tick = async (startTime, updateGames) => {
  clearTimeout(timeoutId);
  const { seconds, targetNext } = getTargetNext(startTime, tickResolution);
  updateGames(seconds);
  // console.log('seconds', seconds);
  if (STOP_TIMER) {
    console.log('timer stopped', seconds);
    if (deltaLessThan50 > 0) {
      // const performanceDoc = await PerformanceModel.findOne().exec();
      // performanceDoc.deltaLessThan50 = performanceDoc.deltaLessThan50 + 1;
      // performanceDoc.save();
    }
    return;
  }
  const delta = targetNext - Date.now();
  if (delta < 50) {
    console.log('=====> DELTA LESS THAN 50 <======', delta);
    deltaLessThan50 = deltaLessThan50 + 1;
  }
  // console.log('timer delta', delta);
  timeoutId = setTimeout(() => {
    tick(startTime, updateGames);
  }, delta);
};

export const getTargetNext = (startTime, resolution) => {
  const elapsed = Date.now() - startTime;
  const seconds = roundToOneDecimal(elapsed / 1000);
  const targetNext = (seconds + resolution) * 1000 + startTime;
  return { seconds, targetNext };
};

export const startTimer = (updateGames: (seconds: number) => void) => {
  STOP_TIMER = false;
  tick(Date.now(), updateGames);
};

export const isTimerRunning = () => !STOP_TIMER;

export const stopTimer = () => {
  STOP_TIMER = true;
};

const roundToOneDecimal = (value) => Math.round(value * 10) / 10;

export const isInterval = (x, y) =>
  Math.round(x * 100) % Math.round(y * 100) === 0;
