import React from 'react';
import { CustomSetListItem } from '../../../types/custom-sets';
import { Stack, Typography } from '@mui/material';
import CustomSetCard from '../../main/CustomSetCard';
import { useNavigate } from 'react-router-dom';

type Props = {
  customSetsCreated: CustomSetListItem[];
  profileId: string;
};

const CreatedList = ({ customSetsCreated, profileId }: Props) => {
  const navigate = useNavigate();

  const handleCardClick = (customSetId: string) => {
    navigate(`/game/${customSetId}`);
  };

  return (
    <Stack>
      {customSetsCreated?.length ? (
        customSetsCreated.map((customSet, i) => (
          <CustomSetCard
            key={i}
            customSet={customSet}
            handleCardClick={handleCardClick}
            profileId={profileId}
          />
        ))
      ) : (
        <Typography textAlign={'center'}>No sets created</Typography>
      )}
    </Stack>
  );
};

export default CreatedList;
